<template>
  <ChecCard inner-class="hosted-experinces-card" tailwind="p-4">
    <ChecHeader class="mb-4" variant="card" :title="$t('general.hostedExperiences')" />

    <ChecSwitch
      class="mb-4"
      name="EnableHostedStorefront"
      :toggled="merchant.has.enabled_hosted_storefront"
      @input="value => change('enabled_hosted_storefront', value)"
    >
      {{ $t('settings.merchant.enabledHostedStorefront') }}
      <ChecIcon
        v-tooltip="$t('settings.merchant.enableHostedStorefrontTooltip')"
        icon="question-mark-square"
        class="w-3 h-3 inline"
      />
    </ChecSwitch>
    <ChecSwitch
      name="EnableHostedCheckouts"
      :toggled="merchant.has.enabled_hosted_checkouts"
      @input="value => change('enabled_hosted_checkouts', value)"
    >
      {{ $t('settings.merchant.enableHostedCheckouts') }}
      <ChecIcon
        v-tooltip="$t('settings.merchant.enableHostedCheckoutsTooltip')"
        icon="question-mark-square"
        class="w-3 h-3 inline"
      />
    </ChecSwitch>
  </ChecCard>
</template>

<script>
import {
  ChecSwitch,
  ChecCard,
  ChecHeader,
  ChecIcon,
} from '@chec/ui-library';

export default {
  name: 'HostedExperiences',
  components: {
    ChecSwitch,
    ChecCard,
    ChecHeader,
    ChecIcon,
  },
  model: {
    prop: 'merchant',
    event: 'input',
  },
  props: {
    merchant: {
      type: Object,
      required: true,
    },
  },
  methods: {
    change(prop, value) {
      this.$emit('input', {
        ...this.merchant,
        has: {
          ...this.merchant.has,
          [prop]: value,
        },
      });
    },
  },

};
</script>

<style lang="scss">
.hosted-experiences-card {
  @apply flex flex-col;
}
</style>
