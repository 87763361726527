<template>
  <div class="standard-zone-rates-table-container">
    <ChecTable class="standard-zone-rates-table" snug>
      <thead>
        <tr>
          <th class="standard-zone-rates-table__row-header-region">
            {{ $t('general.region') }}
          </th>
          <th class="standard-zone-rates-table__row-header-standard">
            {{ $t('general.standard') }}
          </th>
          <th class="standard-zone-rates-table__row-header-digital">
            {{ $t('general.digital') }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-if="loading">
          <td colspan="4" class="standard-zone-rates-table__loading-cell">
            <ChecLoading />
          </td>
        </tr>
        <tr
          v-else-if="!activeZone"
          class="standard-zone-rates-table__row--empty"
        >
          <td colspan="3">
            {{ $t('general.empty') }}
          </td>
        </tr>
        <template v-else>
          <StandardTaxRow
            :active-zone="activeZone"
          />
        </template>
      </tbody>
    </ChecTable>
    <ChecTable class="regional-zone-rates-table" snug>
      <thead>
        <tr>
          <th class="regional-zone-rates-table__row-header-region">
            {{ tableRegionalTypeHeader }}
          </th>
          <th class="regional-zone-rates-table__row-header-standard">
            {{ $t('general.standard') }}
          </th>
          <th class="regional-zone-rates-table__row-header-digital">
            {{ $t('general.digital') }}
          </th>
          <th class="regional-zone-rates-table__row-header-calculation">
            {{ $t('general.calculation') }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-if="loading">
          <td colspan="4" class="regional-zone-rates-table__loading-cell">
            <ChecLoading />
          </td>
        </tr>
        <tr
          v-else-if="!activeZone.rates.length"
          class="regional-zone-rates-table__row--empty"
        >
          <td colspan="4">
            {{ $t('general.empty') }}
          </td>
        </tr>
        <template v-else>
          <RegionalTaxRow
            v-for="(rate, index) in activeZone.rates"
            :key="index"
            :active-zone="rate"
            :subdivisions="subdivisions[country]"
          />
        </template>
      </tbody>
    </ChecTable>
  </div>
</template>

<script>
import {
  ChecLoading,
  ChecTable,
} from '@chec/ui-library';
import {
  mapGetters,
} from 'vuex';
import actions from '@/store/actions';
import RegionalTaxRow from './RegionalTaxRow.vue';
import StandardTaxRow from './StandardTaxRow.vue';
import caTaxDefaults from '../../data/tax/ca';
import usTaxDefaults from '../../data/tax/us';

export default {
  name: 'RegionalTaxTable',
  components: {
    ChecLoading,
    ChecTable,
    RegionalTaxRow,
    StandardTaxRow,
  },
  props: {
    activeTaxZone: Object,
    country: String,
  },
  data() {
    return {
      loading: true,
      activeZone: this.activeTaxZone,
    };
  },
  computed: {
    ...mapGetters(['countries', 'subdivisions']),
    /**
     * Determines wether we are on the add tax configuration route.
     *
     * @returns {boolean}
     */
    isAdding() {
      return this.$route.name === 'settings.tax.addZone';
    },
    /**
     * @returns {string}
     */
    tableRegionalTypeHeader() {
      return this.country === 'US'
        ? this.$t('general.state')
        : this.$t('general.province');
    },
  },
  watch: {
    activeZone() {
      this.activeZone = this.activeTaxZone;
    },
  },
  mounted() {
    if (this.isAdding) {
      this.loadEmptyRegions();
    } else {
      this.loadSubdivisions();
    }
  },
  methods: {
    /**
     * Loads the default regional tax zones for the United States or Canada.
     */
    loadEmptyRegions() {
      this.loadSubdivisions();
      if (this.country === 'US') {
        this.activeZone.rates = usTaxDefaults;
        return;
      }
      // Set the standard country rate to 5%
      this.activeZone.country_standard_rate = 5;
      this.activeZone.rates = caTaxDefaults;
    },
    /**
     * If the subdivision is not already loaded into the state, we fetch the
     * appropriate regtions.
     */
    async loadSubdivisions() {
      if (!this.subdivisions[this.country]) {
        await this.$store.dispatch(actions.FETCH_SUBDIVISIONS, this.country);
      }
      this.loading = false;
    },
  },
};
</script>

<style lang="scss">
.regional-zone-rates-table {
  @apply mt-8;

  table {
    @apply w-full h-px;
  }

  tbody {
    @apply h-full;
  }

  tr:not(.regional-zone-rates-table__row--empty) > *:last-child {
    @apply;
  }

  &__row--empty {
    @apply text-left;
  }

  &__row-header-standard,
  &__row-header-digital,
  &__row-header-calculation {
    @apply w-1/4;
  }

  &__loading-cell {
    .chec-table--snug & {
      @apply relative p-8;
    }

    .loading {
      @apply z-0;
    }
  }
}
</style>
