<template>
  <div class="billing-plan-thumbnail">
    <ChecMarketingIcon class="billing-plan-thumbnail__icon" :icon="resolvedIcon" />
    {{ name }}
  </div>
</template>

<script>
import { ChecMarketingIcon } from '@chec/ui-library';
import getPlanIconName from '@/mixins/getPlanIconName';

export default {
  name: 'PlanIcon',
  components: { ChecMarketingIcon },
  mixins: [getPlanIconName],
  props: {
    name: {
      type: String,
      required: true,
    },
    icon: String,
  },
  computed: {
    resolvedIcon() {
      return this.getPlanIconName(this.icon || this.name);
    },
  },
};
</script>

<style lang="scss">
.billing-plan-thumbnail {
  @apply flex items-center font-bold capitalize;

  &__icon {
    @apply w-8 h-8 mr-4 flex-shrink-0;
  }
}
</style>
