<template>
  <ChecCard class="log-entry">
    <ChecLoading
      v-if="loading"
      without-background
    />
    <div v-else class="log-entry__wrapper">
      <div class="log-entry__card">
        <ChecHeader variant="card" :title="$t('developer.summary')" />
        <LogSummary :log="log" />
      </div>
      <div class="log-entry__card">
        <LogRequestResponse :log="log" />
      </div>
    </div>
  </ChecCard>
</template>

<script>
import {
  ChecCard,
  ChecHeader,
  ChecLoading,
} from '@chec/ui-library';
import LogSummary from './LogSummary.vue';
import LogRequestResponse from './LogResponseRequest.vue';

export default {
  name: 'LogEntry',
  components: {
    ChecCard,
    ChecHeader,
    ChecLoading,
    LogSummary,
    LogRequestResponse,
  },
  props: {
    /**
     * A single log
     */
    log: Object,
    /**
     * Determine the loading state
     */
    loading: Boolean,
  },
};
</script>

<style lang="scss">
.log-entry {
  @apply shadow rounded-md mt-8;

  @screen xl {
    @apply ml-10 mt-0 w-2/5;
    min-width: 22rem;
  }

  @screen 2xl {
    min-width: 30rem;
  }

  table {
    @apply table-fixed w-full mt-4;
  }

  &__card {
    @apply p-8;

    // Split these up in case there are multiple content containers inside a card
    &:first-child {
      @apply rounded-tl-md rounded-tr-md bg-white;
    }

    &:last-child {
      @apply rounded-bl-md rounded-br-md bg-gray-700;
    }

    p {
      @apply text-sm;
    }
  }

  .card__inner-wrapper {
    @apply p-0; // Override inner card padding
  }
}
</style>
