<template>
  <form class="extra-field-add-edit" @submit.prevent>
    <DashboardHeader :title="headerText" />
    <ChecLoading v-if="isLoading || loading" without-background />
    <div v-else class="extra-field-add-edit__content space-y-4 md:space-y-0">
      <div class="extra-field-add-edit__main space-y-4">
        <ChecCard
          tailwind="p-4"
          variant="card"
        >
          <DashboardHeader
            variant="card"
            margin="small"
            :title="$t('general.details')"
          />
          <div class="input-wrapper__row space-x-2">
            <div class="input-wrapper">
              <TextField
                v-model="extraField.name"
                name="extra-field-name"
                type="text"
                required
                :label="$t('general.name')"
              >
                <ChecSwitch
                  v-model="extraField.required"
                  prefix-label
                  name="required"
                >
                  {{ $t('general.required') }}
                </ChecSwitch>
              </TextFIeld>
              <span v-if="validationErrors.name" class="input-wrapper__error">
                {{ validationErrors.name }}
              </span>
            </div>
            <div class="input-wrapper extra-fields-add-edit__field-type">
              <ChecFormField :tooltip="$t('extraFields.fieldTypeDescription')">
                <ChecDropdown
                  v-model="extraField.type"
                  required
                  :options="fieldTypes"
                  :label="$t('extraFields.fieldType')"
                  name="extra-field-type"
                />
              </ChecFormField>
              <span v-if="validationErrors.type" class="input-wrapper__error">
                {{ validationErrors.type }}
              </span>
              <div
                v-if="extraField.type === 'options'
                  || extraField.type === 'radio'"
                class="input-wrapper extra-fields-add-edit__options-field"
              >
                <ChecTagsField
                  v-model="extraField.options"
                  add-on-blur
                  :add-on-keys="[9, 13, 188]"
                  :max-length="20"
                  :placeholder="$t('extraFields.addOptions')"
                  name="extra-field-options"
                />
                <span v-if="validationErrors.options" class="input-wrapper__error">
                  {{ validationErrors.options }}
                </span>
              </div>
            </div>
          </div>
        </ChecCard>
      </div>
      <div class="extra-field-add-edit__aside space-y-4">
        <ActionsCard
          :allow-delete="!isAdding"
          :saving="saving"
          @save-extra-field="handleSaveExtraField"
          @delete-extra-field="handleDeleteExtraField"
        />
      </div>
    </div>
  </form>
</template>

<script>
import {
  ChecCard,
  ChecDropdown,
  ChecFormField,
  ChecLoading,
  ChecTagsField,
  ChecSwitch,
  TextField,
} from '@chec/ui-library';
import addNotification from '@/mixins/addNotification';
import confirm from '@/mixins/confirm';
import crud from '@/mixins/crud';
import { mapGetters } from 'vuex';
import fieldTypes from '@/lib/extraFieldsTypes';
import validateSchemaRequest from '@/lib/helpers/validateSchemaRequestHelper';
import DashboardHeader from '@/components/DashboardHeader.vue';
import ActionsCard from '../components/ActionsCard.vue';
import extraFieldSchema from '../schemas/extraField';

export default {
  name: 'AddEditExtraField',
  components: {
    ChecCard,
    ChecDropdown,
    ChecFormField,
    ChecLoading,
    ChecSwitch,
    ChecTagsField,
    TextField,
    DashboardHeader,
    ActionsCard,
  },
  mixins: [addNotification, confirm, crud('settings/extraFields')],
  data() {
    return {
      saving: false,
      loading: false,
      extraField: {
        name: '',
        required: false,
        type: '',
        options: [],
      },
      extraFieldId: '',
      validationErrors: {},
    };
  },
  computed: {
    ...mapGetters(['isLoading']),
    /**
     * Whether this panel is in "add mode" and a gateway is being added
     */
    isAdding() {
      return this.$route.name === 'settings.extraFields.add';
    },
    /**
     * Create the extra field title with the name
     *
     * @returns {string}
     */
    headerText() {
      if (this.isAdding) {
        return this.$t('extraFields.add');
      }
      return this.$t('extraFields.edit');
    },
    /**
     * Returns a map of extra fields types in a format that our dropdowns can consume
     *
     * @returns {object[]}
     */
    fieldTypes() {
      return fieldTypes.map((fieldType) => ({
        value: fieldType.value,
        label: fieldType.label,
      }));
    },
  },
  /**
   * Fetch extra field, if editing
   */
  mounted() {
    if (this.isAdding) {
      return;
    }

    this.extraFieldId = this.$route.params.id;
    this.load(this.extraFieldId).then(async () => {
      const extraFieldData = await this.get(this.extraFieldId);

      this.extraField = {
        name: extraFieldData.name,
        required: extraFieldData.required,
        type: extraFieldData.type,
        options: extraFieldData.options?.length ? extraFieldData.options : [],
      };
    }).catch(() => {
      this.$router.push({ name: 'notFound' });
    });
  },
  methods: {
    handleClose() {
      this.$router.push({ name: 'settings.extraFields.home' });
    },
    /**
     * Handle save for creating or updating extra field
     */
    handleSaveExtraField() {
      this.validationErrors = {};
      // Set saving to true when save button is clicked
      this.saving = true;
      // Set loading state when saving
      if (this.saving) {
        this.loading = true;
      }

      validateSchemaRequest(extraFieldSchema, this.extraField, { abortEarly: false })
        .then((validatedData) => {
          const extraFieldPayload = {
            ...validatedData,
            name: this.extraField.name,
            required: this.extraField.required,
            type: this.extraField.type,
            options: this.extraField.options,
          };

          this.handleClose('close');
          if (!this.isAdding) {
            return this.update(this.$route.params.id, extraFieldPayload, true);
          }
          return this.create(extraFieldPayload, true);
        })
        .then(() => {
          this.addNotification(this.$t('extraFields.saveSuccess'));
        })
        .catch((error) => {
          if (error.name === 'ValidationError') { // yup schema validation error
            this.validationErrors = error.errors;
            return;
          }
          this.addNotification(this.$t('extraFields.saveFailure'), 'error');
        })
        .finally(() => { this.loading = false; });

      this.saving = false;
    },
    /**
     * Deletes extra field
     */
    async handleDeleteExtraField() {
      // Handle confirm modal
      if (!await this.confirm(
        this.$t('general.areYouSure'),
        this.$t('extraFields.confirmDeleteExtraField'),
      )) {
        return;
      }
      const { id } = this.$route.params;
      await this.$router.push({ name: 'settings.extraFields.home' });
      // Use extra field delete CRUD
      this.delete(id).then(() => {
        this.addNotification(this.$t('extraFields.deleted'));
      }).catch((error) => {
        this.addNotification(this.$t('extraFields.deleteFailed'), 'error');
        throw error;
      });
    },
  },
};
</script>

<style lang="scss">
.extra-field-add-edit {
  &__aside {
    @screen lg {
      @apply ml-8 w-1/3 max-w-sm flex-shrink-0;
    }
  }

  @screen lg {
    &__content {
      @apply flex flex-row;
    }

    &__main {
      @apply flex-grow;

      .chec-header {
        @apply mb-4;
      }
    }
  }

  .card {
    @apply mb-8;
  }

  // Override to align error and info button
  &__field-type {
    .input-wrapper__error {
      @apply float-left;
    }

    .form-field-action-append {
      @apply float-right;
    }
  }

  .input-wrapper.extra-fields-add-edit__options-field {
    @apply w-full; // Override input wrapper width when in a row
  }

  .tags-field__input-wrapper input {
    width: 20rem; // Override tags field input width
  }
}
</style>
