<template>
  <div v-if="!isLoading" class="notifications-settings">
    <DashboardHeader :title="$t('settings.notifications.title')" />

    <ChecLoading v-if="loading" without-background />
    <div v-else class="notifications-settings__row space-y-8 md:space-y-0 md:space-x-8">
      <div class="notifications-settings__cards space-y-8">
        <ChecCard tailwind="p-4" class="notifications-settings__content">
          <DashboardHeader
            variant="card"
            margin="small"
            :title="$t('settings.notifications.emailNotifications')"
          />
          <p class="notifications-settings__summary">
            {{ $t('settings.notifications.emailNotificationSummary') }}
          </p>

          <div class="notifications-settings__section-container">
            <ChecSwitch v-model="news" name="news">
              {{ $t('settings.notifications.news') }}
            </ChecSwitch>
          </div>

          <div class="notifications-settings__section-container">
            <ChecSwitch v-model="customerOrderReceipt" name="customerOrderReceipt">
              {{ $t('settings.notifications.customerOrderReceipt') }}
            </ChecSwitch>
          </div>

          <div class="notifications-settings__section-container">
            <ChecSwitch v-model="customerOrderShipped" name="customerOrderShipped">
              {{ $t('settings.notifications.customerOrderShipped') }}
            </ChecSwitch>
          </div>

          <div class="notifications-settings__section-container">
            <ChecSwitch v-model="customerLoginToken" name="customerLoginToken">
              {{ $t('settings.notifications.customerLoginToken') }}
            </ChecSwitch>
          </div>

          <div class="notifications-settings__section-container">
            <ChecSwitch v-model="merchantOrderReceipt" name="merchantOrderReceipt">
              {{ $t('settings.notifications.merchantOrderReceipt') }}
            </ChecSwitch>
          </div>
        </ChecCard>
      </div>

      <div class="notifications-settings__actions md:space-y-8">
        <ChecCard tailwind="p-4 flex" class="mt-8 md:mt-0">
          <ChecButton color="green" class="notifications-settings__save" @click="handleUpdate">
            {{ $t('general.saveChanges') }}
          </ChecButton>
        </ChecCard>

        <ChecCard tailwind="p-4" class="notifications-settings__content">
          <DashboardHeader
            variant="card"
            margin="small"
            :title="$t('settings.notifications.dashboard')"
          />
          <p class="notifications-settings__summary">
            {{ $t('settings.notifications.dashboardSummary') }}
          </p>

          <div class="notifications-settings__section-container">
            <ChecDropdown
              v-model="dashboardNotifications"
              name="dashboardNotifications"
              :label="$t('settings.notifications.dashboard')"
              :options="[
                {
                  value: 'off',
                  label: this.$t('settings.notifications.none'),
                },
                {
                  value: 'quiet',
                  label: this.$t('settings.notifications.navMenuHighlight'),
                },
                {
                  value: 'full',
                  label: this.$t('settings.notifications.notificationPopup'),
                },
              ]"
              required
            />
          </div>
        </ChecCard>
      </div>
    </div>
  </div>
</template>

<script>
import {
  ChecButton,
  ChecCard,
  ChecDropdown,
  ChecLoading,
  ChecSwitch,
} from '@chec/ui-library';
import {
  mapActions,
  mapGetters, mapMutations, mapState,
} from 'vuex';
import DashboardHeader from '@/components/DashboardHeader.vue';
import mutations from '@/store/mutations';
import addNotification from '@/mixins/addNotification';
import confirm from '@/mixins/confirm';
import actions from '@/store/actions';

export default {
  name: 'Home',
  components: {
    ChecButton,
    ChecCard,
    ChecDropdown,
    ChecLoading,
    ChecSwitch,
    DashboardHeader,
  },
  mixins: [addNotification, confirm],
  data() {
    return {
      news: null,
      customerLoginToken: null,
      customerOrderReceipt: null,
      customerOrderShipped: null,
      merchantOrderReceipt: null,
      dashboardNotifications: 'quiet',
      loading: true,
    };
  },
  computed: {
    ...mapGetters(['isLoading']),
    ...mapGetters('notifications', ['notificationSettings']),
    ...mapState('merchant', ['merchant']),
  },
  watch: {
    notificationSettings(val) {
      this.updateNotificationState(val);
    },
  },
  async mounted() {
    if (Object.keys(this.notificationSettings).length === 0) {
      await this.$store.dispatch('notifications/FETCH_NOTIFICATION_SETTINGS');
    }

    this.loading = false;
    this.updateNotificationState(this.notificationSettings);
  },
  methods: {
    ...mapActions('notifications', { setSettings: actions.UPDATE_NOTIFICATION_SETTINGS }),
    ...mapMutations({ setLoading: mutations.SET_LOADING }),
    updateNotificationState(data) {
      if (!data) {
        // Default
        this.news = null;
        this.customerLoginToken = null;
        this.customerOrderReceipt = null;
        this.customerOrderShipped = null;
        this.merchantOrderReceipt = null;
        this.dashboardNotifications = 'quiet';

        return;
      }

      this.news = data.merchant.news;
      this.customerLoginToken = data.customer.login_token;
      this.customerOrderReceipt = data.customer.orders;
      this.customerOrderShipped = data.customer.shipments;
      this.merchantOrderReceipt = data.merchant.orders;
      this.dashboardNotifications = data.merchant.dashboard_notifications;
    },
    /**
     * Update the notification settings
     */
    handleUpdate() {
      this.setLoading(true);
      this.setSettings({
        customer: {
          login_token: this.customerLoginToken,
          orders: this.customerOrderReceipt,
          shipments: this.customerOrderShipped,
        },
        merchant: {
          news: this.news,
          orders: this.merchantOrderReceipt,
          dashboard_notifications: this.dashboardNotifications,
        },
      })
        .then(() => {
          this.addNotification(this.$t('settings.updatedSuccessfully'));
        })
        .catch(() => {
          this.addNotification(this.$t('settings.updateFailed'), 'error');
        })
        .finally(() => {
          this.setLoading(false);
        });
    },
  },
};
</script>

<style lang="scss">
.notifications-settings {
  @apply text-gray-500;

  // Work around ChecLoading awkwardness
  min-height: 4rem;

  > .loading {
    @apply pb-16 mt-4;
  }

  &__actions {
    @apply w-full flex flex-col-reverse;

    @screen md {
      @apply w-1/3 flex-col;
    }
  }

  &__cards {
    @apply w-full;
  }

  &__row {
    @apply flex items-start flex-col;

    @screen md {
      @apply flex-row;
    }
  }

  &__section {
    @apply w-full;

    @screen md {
      @apply w-4/6 flex;
    }
  }

  &__section-container {
    @apply flex flex-col my-6;

    &:last-child {
      @apply mb-0;
    }

    @screen md {
      @apply flex-row;
    }
  }

  &__save {
    @apply flex-grow;
  }

  &__summary {
    @apply mb-4;
  }

  &__aside {
    @apply w-full;

    @screen md {
      @apply w-1/3;
    }
  }
}
</style>
