<template>
  <div class="invoice-view">
    <TransactionFees />
  </div>
</template>

<script>
import TransactionFees from '../components/TransactionFees.vue';

export default {
  name: 'InvoiceView',
  components: {
    TransactionFees,
  },
};
</script>

<style lang="scss">
.invoice-view {
  @apply mt-20 text-gray-500;

  a {
    @apply text-blue-600;

    &:hover {
      @apply underline;
    }
  }

  h2 {
    @apply text-2xl;

    span {
      @apply font-bold;
    }
  }

  &__toolbar {
    @apply m-4 flex items-center;

    h2 {
      @apply flex-grow;
    }
  }
}
</style>
