<template>
  <ChecCard inner-class="discount-actions" tailwind="p-4">
    <ChecButton
      color="green"
      class="flex-grow"
      :disabled="saving"
      button-type="submit"
      @click="() => $emit('save-discount')"
    >
      {{ saveLabel }}
    </ChecButton>
    <ChecOptionsMenu
      v-if="allowDelete"
      class="discount-actions__more-options"
      :aria-label="$t('product.additionalActions')"
    >
      <ChecOption
        destructive
        @option-selected="() => $emit('delete-discount')"
      >
        {{ $t('general.delete') }}
      </ChecOption>
    </ChecOptionsMenu>
  </ChecCard>
</template>

<script>
import {
  ChecButton,
  ChecCard,
  ChecOptionsMenu,
  ChecOption,
} from '@chec/ui-library';

export default {
  name: 'ActionsCard',
  components: {
    ChecButton,
    ChecCard,
    ChecOptionsMenu,
    ChecOption,
  },
  props: {
    saving: Boolean,
    allowDelete: Boolean,
  },
  computed: {
    /**
     * The label shown for the "save" button
     *
     * @returns {string}
     */
    saveLabel() {
      return this.saving ? this.$t('general.saving') : this.$t('general.saveChanges');
    },
  },
};
</script>

<style lang="scss">
.discount-actions {
  @apply flex flex-row gap-2;

  &__rule {
    @apply border-gray-200 mt-4 mb-2;
  }

  &__secondary {
    @apply flex justify-end;
  }

  &__more-options {
    @apply flex items-center;
  }
}
</style>
