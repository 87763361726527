<template>
  <div class="tax-info__intro bg-confetti">
    <ChecCard compact class="tax-info__intro-card">
      <h3 class="tax-info__intro-header">
        {{ $t('settings.tax.introTitle') }}
      </h3>
      <p>{{ $t('settings.tax.introCopy') }}</p>
      <div class="tax-info__intro-actions">
        <ChecButton
          color="primary"
          variant="round"
          @click="() => $emit('enable-tax')"
        >
          {{ $t('settings.tax.addTax') }}
        </ChecButton>
      </div>
    </ChecCard>
  </div>
</template>

<script>
import {
  ChecButton,
  ChecCard,
} from '@chec/ui-library';

export default {
  name: 'Intro',
  components: {
    ChecButton,
    ChecCard,
  },
};
</script>

<style lang="scss">
.tax-info {
  &__intro {
    @apply pt-24 pb-40 mt-4 whitespace-pre-line;
  }

  &__intro-card {
    @apply mx-auto max-w-xl;

    &.card--compact,
    &.card--compact .card__inner-wrapper {
      // Overriding default card styles for some reason (as per designs)
      @apply rounded-lg;
    }
  }

  &__intro-header {
    @apply mb-4 font-bold;
  }

  &__intro-actions {
    @apply flex justify-end mt-8;
  }
}
</style>
