<template>
  <TableRow
    class="logs-table-row"
    role="button"
    tabindex="0"
    @click="$emit('click')"
  >
    <td class="logs-table-row__method-cell">
      <p>
        {{ method }}
      </p>
    </td>
    <td>
      <code>{{ url }}</code>
    </td>
    <td class="logs-table-row__status-cell">
      <ChecDataPill :color="statusColor">
        {{ statusCode }}
      </ChecDataPill>
    </td>
    <td>
      <Timestamp :timestamp="time" />
    </td>
  </TableRow>
</template>

<script>
import { ChecDataPill } from '@chec/ui-library';
import Timestamp from '@/components/Timestamp.vue';
import TableRow from '@/components/DashboardTable/TableRow.vue';

export default {
  name: 'LogRow',
  components: {
    TableRow,
    ChecDataPill,
    Timestamp,
  },
  props: {
    method: String,
    url: String,
    statusCode: Number,
    time: Number,
  },
  computed: {
    /**
     * Determine the data pill color from the status code
     */
    statusColor() {
      if (this.statusCode >= 200 && this.statusCode < 300) {
        return 'green';
      }
      return 'red';
    },
  },
};
</script>

<style lang="scss">
.logs-table-row {
  @apply cursor-pointer;

  code {
    @apply py-1 px-2 bg-gray-200 rounded-sm break-words;
    @apply text-xs font-mono tracking-wide text-gray-600;
  }

  &--active {
    @apply bg-gray-100;

    code {
      @apply bg-white;
    }

    p { // Apply font weight on only <p> element in active class
      @apply font-bold;
    }
  }

  &__status-cell {
    padding-top: calc(0.75rem - 1px); // Specific padding to vertically align pill
  }
}
</style>
