<template>
  <ChecCard inner-class="details-card" tailwind="p-4">
    <DashboardHeader
      variant="card"
      margin="small"
      :title="$t('account.dashboardLanguage')"
    />
    <div class="input-wrapper">
      <ChecDropdown
        :value="language"
        name="language"
        required
        :label="$t('account.dashboardLanguage')"
        :options="availableLanguages"
        @input="value => $emit('input', value)"
      />
    </div>
  </ChecCard>
</template>

<script>
import {
  ChecCard,
  ChecDropdown,
} from '@chec/ui-library';
import languages from '@/lang/meta';
import DashboardHeader from '@/components/DashboardHeader.vue';

export default {
  name: 'LanguageCard',
  components: {
    ChecCard,
    DashboardHeader,
    ChecDropdown,
  },
  model: {
    prop: 'language',
    event: 'input',
  },
  props: {
    language: {
      type: String,
    },
  },
  computed: {
    /**
     * Returns a list of languages available for use in the Dashboard, formatted for use
     * in a ChecDropdown field.
     *
     * @returns {object[]}
     */
    availableLanguages() {
      return languages.languages.map((language) => ({
        value: language.language,
        label: language.localizedName || 'English', // when translations haven't been pulled
      }));
    },
  },
};
</script>
