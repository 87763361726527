import i18n from '@/lang';

export default [
  {
    value: 'short_text',
    label: i18n.t('general.shortText'),
  },
  {
    value: 'long_text',
    label: i18n.t('general.longText'),
  },
  {
    value: 'number',
    label: i18n.t('general.number'),
  },
  {
    value: 'date',
    label: i18n.t('general.date'),
  },
  {
    value: 'checkbox',
    label: i18n.t('general.checkbox'),
  },
  {
    value: 'radio',
    label: i18n.t('general.radio'),
  },
  {
    value: 'options',
    label: i18n.t('general.options'),
  },
];
