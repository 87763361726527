<template>
  <ChecCard inner-class="enable-vat-card" tailwind="p-4">
    <ChecSwitch
      name="vat_moss"
      :toggled="tax.eu_vat_moss"
      @input="handleChangeVatMossOption"
    >
      {{ $t('settings.tax.enableVat') }}
    </ChecSwitch>
    <hr class="enable-vat__rule">
    <p>
      {{ $t('settings.tax.enableVatInfo') }}
    </p>
  </ChecCard>
</template>

<script>
import {
  ChecCard,
  ChecSwitch,
} from '@chec/ui-library';

export default {
  name: 'EnableVat',
  components: {
    ChecCard,
    ChecSwitch,
  },
  model: {
    prop: 'tax',
    event: 'input',
  },
  props: {
    tax: {
      type: Object,
    },
  },
  methods: {
    handleChangeVatMossOption(value) {
      this.$emit('input', {
        ...this.tax,
        eu_vat_moss: value,
      });
    },
  },
};
</script>

<style lang="scss">
.enable-vat {
  @apply flex flex-col;

  &__rule {
    @apply border-gray-200 mt-4 mb-2;
  }
}
</style>
