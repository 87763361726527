<template>
  <tr class="eu-vat-moss-row">
    <td>
      {{ country.country_name }}
    </td>
    <td>
      {{ `${country.physical_rate}%` }}
    </td>
    <td>
      {{ `${country.digital_rate}%` }}
    </td>
  </tr>
</template>

<script>
export default {
  name: 'EuVatMossRow',
  props: {
    country: Object,
  },
};
</script>

<style lang="scss">
.eu-vat-moss-row {
  &__percentage {
    @apply text-lg;
  }
}
</style>
