import {
  object,
  string,
} from 'yup';
import i18n from '@/lang';

export default object().shape({
  name: string().required(i18n.t('validation.enterName')),
  type: string().required(i18n.t('validation.typeRequired')),
});
