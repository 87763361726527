<template>
  <ChecSlideoutPanel
    :title="panelTitle"
    close-on-overlay-click
    @close="close"
  >
    <template>
      <ChecLoading v-if="loading" />
      <ChecHeader
        header-tag="h3"
        :title="$t('settings.tax.taxRates')"
      >
        <ChecSwitch
          v-if="country === 'US'"
          :toggled="isNexusEnabled"
          name="enabled-nexus"
          class="zone-configure__option"
          @input="isNexusEnabled = !isNexusEnabled"
        >
          {{ $t('settings.tax.calculateAutomatically') }}
        </ChecSwitch>
      </ChecHeader>
      <component
        :is="activeZoneTemplate"
        v-if="activeZoneTemplate !== 'USNexusTable'"
        :active-tax-zone="zone"
        :country="country"
        @update="updateZone"
      />
      <USNexusTable
        v-else
        :existing-states="zone.nexus || []"
        :new-states="newNexusStates"
        :deleted-zipcodes="deletedNexusZipcodes"
        @add="handleAddNexus"
        @cancel-delete="handleCancelDeleteNexus"
        @delete="handleDeleteNexus"
      />
    </template>
    <template #toolbar>
      <div class="zone-configure__toolbar space-x-4">
        <div class="flex-grow">
          <ChecButton
            v-if="!isAdding"
            button-type="submit"
            outline
            color="red"
            icon="trash"
            :disabled="loading"
            @click="handleDeleteConfiguration()"
          >
            {{ $t('general.delete') }}
          </ChecButton>
        </div>
        <ChecButton text-only color="primary" @click="close">
          {{ $t('general.cancel' ) }}
        </ChecButton>
        <ChecButton
          button-type="submit"
          color="primary"
          :disabled="loading || !canSave"
          @click="handleSaveConfiguration()"
        >
          {{ saveButtonLabel }}
        </ChecButton>
      </div>
    </template>
  </ChecSlideoutPanel>
</template>

<script>
import {
  ChecButton,
  ChecHeader,
  ChecLoading,
  ChecSwitch,
  ChecSlideoutPanel,
} from '@chec/ui-library';
import { mapGetters } from 'vuex';
import RegionalTaxTable from './RegionalTaxTable.vue';
import StandardTaxTable from './StandardTaxTable.vue';
import USNexusTable from './USNexusTable.vue';

export default {
  name: 'ConfigureZone',
  components: {
    ChecButton,
    ChecHeader,
    ChecLoading,
    ChecSwitch,
    ChecSlideoutPanel,
    RegionalTaxTable,
    StandardTaxTable,
    USNexusTable,
  },
  props: {
    country: String,
    zone: Object,
    loading: Boolean,
  },
  data() {
    return {
      saving: false,
      newNexusStates: [],
      deletedNexusZipcodes: [],
    };
  },
  computed: {
    ...mapGetters(['countries']),
    /**
     * Returns the active country code.
     *
     * @returns {string}
     */
    activeCountry() {
      return this.country;
    },
    /**
     * Returns the active countries name.
     *
     * @returns {string}
     */
    activeCountryName() {
      return this.countries[this.activeCountry];
    },
    /**
     * Returns which tempalte we are showing depending on the current
     * or selected country.
     *
     * @returns {string}
     */
    activeZoneTemplate() {
      if (this.activeCountry === 'CA') {
        return 'RegionalTaxTable';
      }
      if (this.activeCountry === 'US') {
        return this.isNexusEnabled ? 'USNexusTable' : 'RegionalTaxTable';
      }
      return 'StandardTaxTable';
    },
    /**
     * Determines wether we are on the add tax configuration route.
     *
     * @returns {boolean}
     */
    isAdding() {
      return this.$route.name === 'settings.tax.addZone';
    },
    isNexusEnabled: {
      get() {
        return this.zone.calculate_automatically;
      },
      set(enabled) {
        this.$emit('update', { ...this.zone, calculate_automatically: enabled });
      },
    },
    /**
     * @returns {string}
     */
    saveButtonLabel() {
      if (this.saving) {
        return this.$t('general.saving');
      }
      return this.$t('general.saveChanges');
    },
    /**
     * @returns {string}
     */
    panelTitle() {
      return this.isAdding
        ? this.$t('settings.tax.addTaxRate', { name: this.activeCountryName })
        : this.$t('settings.tax.editTaxRate', { name: this.activeCountryName });
    },
    /**
     * Verify that the zone is ready to be saved
     *
     * @returns {boolean}
     */
    canSave() {
      return this.hasNoEmptyRegionalRates
        && this.hasNoEmptyStandardRates;
    },
    /**
     * Verify that there are no empty regional rates
     *
     * @returns {boolean}
     */
    hasNoEmptyRegionalRates() {
      if (!this.zone.rates.length) {
        return true;
      }
      return Object.values(this.zone.rates).findIndex(
        (rate) => rate.standard_rate === ''
          || rate.digital_rate === '',
      ) === -1;
    },
    /**
     * Verify that there are no empty standard rates
     *
     * @returns {boolean}
     */
    hasNoEmptyStandardRates() {
      if (this.zone.country_standard_rate === ''
        || this.zone.country_digital_rate === '') {
        return false;
      }
      return true;
    },
  },
  methods: {
    close() {
      if (this.isAdding) {
        this.$emit('close');
        return;
      }

      this.$router.push({ name: 'settings.tax' });
    },
    handleSaveConfiguration() {
      if (this.isNexusEnabled && this.activeCountry === 'US') {
        // Calculate the changes to nexus
        const nexus = (this.zone.nexus || [])
          // Remove deleted zones
          .filter((candidate) => !this.deletedNexusZipcodes.includes(candidate.zipcode))
          // Concat new ones
          .concat(this.newNexusStates);

        this.$emit('update', { ...this.zone, nexus });
      }
      this.$emit('save-configuration');
    },
    handleDeleteConfiguration() {
      this.$emit('delete-configuration');
    },
    handleAddNexus(state) {
      this.newNexusStates.push(state);
    },
    handleCancelDeleteNexus(zipcode) {
      const index = this.deletedNexusZipcodes.findIndex((candidate) => candidate === zipcode);

      if (index === -1) {
        return;
      }

      this.deletedNexusZipcodes.splice(index, 1);
    },
    handleDeleteNexus(zipcode) {
      // Skip already deleted records
      if (this.isNexusDeleted(zipcode)) {
        return;
      }

      // Handle records that haven't been saved yet - we just remove them without confirmation
      const newStateIndex = this.newNexusStates
        .findIndex((candidate) => candidate.zipcode === zipcode);

      if (newStateIndex >= 0) {
        this.newNexusStates.splice(newStateIndex, 1);
        return;
      }

      this.deletedNexusZipcodes.push(zipcode);
    },
    isNexusDeleted(zipcode) {
      return this.deletedNexusZipcodes.includes(zipcode);
    },
    updateZone(regions) {
      this.$emit('update', { ...this.zone, rates: regions });
    },
  },
};
</script>

<style lang="scss">
.zone-configure {
  &__toolbar {
    @apply flex justify-end w-full;
  }
}
</style>
