<template>
  <ChecCard
    class="tax-zones__card"
    inner-class="details-card"
  >
    <ChecHeader
      variant="card"
      :title="$t('settings.tax.taxRateZones')"
    >
      <ChecButton
        variant="round"
        color="primary"
        icon="plus"
        tag-type="route"
        :to="{ name: 'settings.tax.addZone' }"
      >
        {{ $t('general.add') }}
      </ChecButton>
    </ChecHeader>
    <DashboardTable
      class="tax-zones-table"
      snug
      :align-left-after="3"
      :columns="[
        $t('general.country'),
        $t('settings.tax.standardRate'),
        $t('settings.tax.digitalRate'),
        $t('general.actions'),
      ]"
      state-key="settings/tax/zones"
    >
      <EuVatMossRow
        v-if="euVatMoss"
        @disable-vat-moss="disableVatMoss"
        @show-vat-moss="showVatMoss"
      />
      <ZoneRow
        v-for="zone in zones"
        :key="zone.id"
        :zone="zone"
        :countries="countries"
        @edit-tax-zone="updateZone(zone)"
        @delete="handleDeleteConfiguration(zone.id)"
      />
    </DashboardTable>
    <DashboardPagination state-key="settings/tax/zones" />
  </ChecCard>
</template>

<script>
import {
  ChecButton,
  ChecCard,
  ChecHeader,
} from '@chec/ui-library';
import DashboardPagination from '@/components/Pagination.vue';
import DashboardTable from '@/components/DashboardTable.vue';
import addNotification from '@/mixins/addNotification';
import crud from '@/mixins/crud';
import confirm from '@/mixins/confirm';
import ZoneRow from './ZoneRow.vue';
import EuVatMossRow from './EuVatMossRow.vue';

export default {
  name: 'Zones',
  components: {
    DashboardTable,
    ChecButton,
    ChecCard,
    ChecHeader,
    DashboardPagination,
    EuVatMossRow,
    ZoneRow,
  },
  mixins: [
    addNotification,
    confirm,
    crud('settings/tax/zones', true),
  ],
  props: {
    countries: Object,
    euVatMoss: Boolean,
  },
  data() {
    return {
      activeTaxCountry: null,
      loading: false,
    };
  },
  methods: {
    disableVatMoss() {
      this.$emit('disable-vat-moss');
    },
    showVatMoss() {
      this.$router.push({
        name: 'settings.tax.showVatMass',
      });
    },
    updateZone(zone) {
      this.activeTaxCountry = zone;
      this.$router.push({
        name: 'settings.tax.editZone',
        params: {
          id: zone.id,
        },
      });
    },
    /**
     * Confirm and delete the tax zone.
     */
    async handleDeleteConfiguration(zoneId) {
      if (!await this.confirm(
        this.$t('settings.tax.confirmDeleteZoneTitle'),
        this.$t('settings.tax.confirmDeleteZone'),
      )) {
        return;
      }
      this.loading = true;
      this.delete(zoneId)
        .then(() => {
          this.addNotification(this.$t('settings.tax.deleted'));
        })
        .catch((error) => {
          this.addNotification(this.$t('settings.tax.saveFailed'), 'error');
          throw error;
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style lang="scss">
.details-card {
  @apply p-4;

  .chec-header {
    @apply mb-4;
  }
}

.tax-zones-table {
  @apply mt-4;

  table {
    @apply w-full h-px;
  }

  tbody {
    @apply h-full;
  }

  &__row-header-thumb,
  &__row-header-actions {
    @apply w-16;
  }

  &__row--empty {
    @apply text-gray-400;
  }
}
</style>
