<template>
  <ChecCard inner-class="details-card" tailwind="p-4">
    <DashboardHeader
      variant="card"
      margin="small"
      :title="$t('account.updatePassword')"
    />
    <div class="input-wrapper">
      <PasswordField
        :value="password.currentPassword"
        name="currentPassword"
        class="account-details__input account-details__input--password"
        :label="$t('account.currentPassword')"
        hide-show-button
        @input="value => change('currentPassword', value)"
      />
    </div>
    <div class="input-wrapper">
      <PasswordField
        :value="password.newPassword"
        name="newPassword"
        class="account-details__input account-details__input--password"
        :label="$t('account.newPassword')"
        :password-strength-score="password.passwordStrengthScore"
        show-password-strength
        @input="value => validatePassword(value)"
      />
    </div>
  </ChecCard>
</template>

<script>
import {
  ChecCard,
  PasswordField,
} from '@chec/ui-library';
import DashboardHeader from '@/components/DashboardHeader.vue';

export default {
  name: 'PasswordCard',
  components: {
    ChecCard,
    DashboardHeader,
    PasswordField,
  },
  model: {
    prop: 'password',
    event: 'input',
  },
  props: {
    password: {
      type: Object,
    },
  },
  methods: {
    change(prop, value) {
      this.$emit('input', {
        ...this.password,
        [prop]: value,
      });
    },
    /**
     * Use zxcvbn to validate the new password score
     */
    async validatePassword(value) {
      this.change('newPassword', value);
      const { default: zxcvbn } = await import(/* webpackChunkName: "zxcvbn" */ 'zxcvbn');

      if (typeof zxcvbn === 'undefined') {
        // Unable to use zxcvbn, return an "ok" result
        this.change('passwordStrengthScore', 2);
        return;
      }

      const result = zxcvbn(
        this.password.newPassword,
        // Domain specific words to penalize
        ['chec', 'commerce', 'commerce.js'],
      );
      this.change('passwordStrengthScore', result.score);
    },
  },
};
</script>
