<template>
  <div class="standard-zone-rates-table-container">
    <ChecTable class="standard-zone-rates-table" snug>
      <thead>
        <tr>
          <th class="standard-zone-rates-table__row-header-region">
            {{ $t('general.region') }}
          </th>
          <th class="standard-zone-rates-table__row-header-standard">
            {{ $t('general.standard') }}
          </th>
          <th class="standard-zone-rates-table__row-header-digital">
            {{ $t('general.digital') }}
          </th>
        </tr>
      </thead>
      <tbody>
        <template v-if="activeTaxZone">
          <StandardTaxRow
            :active-zone="activeTaxZone"
          />
        </template>
        <tr
          v-else
          class="standard-zone-rates-table__row--empty"
        >
          <td colspan="3">
            {{ $t('general.empty') }}
          </td>
        </tr>
      </tbody>
    </ChecTable>
  </div>
</template>

<script>
import {
  ChecTable,
} from '@chec/ui-library';
import StandardTaxRow from './StandardTaxRow.vue';

export default {
  name: 'StandardTaxTable',
  components: {
    ChecTable,
    StandardTaxRow,
  },
  props: {
    activeTaxZone: Object,
  },
};
</script>

<style lang="scss">
.standard-zone-rates-table {
  @apply mt-4;

  table {
    @apply w-full h-px;
  }

  tbody {
    @apply h-full;
  }

  tr:not(.standard-zone-rates-table__row--empty) > *:last-child {
    @apply;
  }

  &__row--empty {
    @apply text-left;
  }

  &__loading-cell {
    .chec-table--snug & {
      @apply relative p-8;
    }

    .loading {
      @apply z-0;
    }
  }
}
</style>
