/**
 * Supported currencies in the Chec API. In future this might be an API endpoint.
 */
export default [
  { code: 'USD', name: 'USD - U.S. dollar' },
  { code: 'EUR', name: 'EUR - Euro' },
  { code: 'CAD', name: 'CAD - Canadian dollar' },

  { code: 'AED', name: 'AED - United Arab Emirates dirham' },
  { code: 'AFN', name: 'AFN - Afghanistan afghani' },
  { code: 'ALL', name: 'ALL - Albanian lek' },
  { code: 'AMD', name: 'AMD - Armenian dram' },
  { code: 'ANG', name: 'ANG - Netherlands Antillean guilder' },
  { code: 'AOA', name: 'AOA - Angolan kwanza' },
  { code: 'ARS', name: 'ARS - Argentine peso' },
  { code: 'AUD', name: 'AUD - Australian dollar' },
  { code: 'AWG', name: 'AWG - Aruban florin' },
  { code: 'AZN', name: 'AZN - Azerbaijani manat' },
  { code: 'BAM', name: 'BAM - Bosnia and Herzegovina convertible mark' },
  { code: 'BBD', name: 'BBD - Barbados dollar' },
  { code: 'BDT', name: 'BDT - Bangladeshi taka' },
  { code: 'BGN', name: 'BGN - Bulgarian lev' },
  { code: 'BIF', name: 'BIF - Burundian franc' },
  { code: 'BMD', name: 'BMD - Bermudian dollar' },
  { code: 'BND', name: 'BND - Brunei dollar' },
  { code: 'BOB', name: 'BOB - Boliviano' },
  { code: 'BRL', name: 'BRL - Brazilian real' },
  { code: 'BSD', name: 'BSD - Bahamian dollar' },
  { code: 'BWP', name: 'BWP - Botswana pula' },
  { code: 'BZD', name: 'BZD - Belize dollar' },
  { code: 'CDF', name: 'CDF - Congolese franc' },
  { code: 'CHF', name: 'CHF - Swiss franc' },
  { code: 'CLP', name: 'CLP - Chilean peso' },
  { code: 'CNY', name: 'CNY - Chinese yuan' },
  { code: 'COP', name: 'COP - Colombian peso' },
  { code: 'CRC', name: 'CRC - Costa Rican colon' },
  { code: 'CVE', name: 'CVE - Cape Verdean escudo' },
  { code: 'CZK', name: 'CZK - Czech koruna' },
  { code: 'DJF', name: 'DJF - Djiboutian franc' },
  { code: 'DKK', name: 'DKK - Danish krone' },
  { code: 'DOP', name: 'DOP - Dominican Republic peso' },
  { code: 'DZD', name: 'DZD - Algerian dinar' },
  { code: 'EGP', name: 'EGP - Egyptian pound' },
  { code: 'ETB', name: 'ETB - Ethiopian birr' },
  { code: 'FJD', name: 'FJD - Fijian dollar' },
  { code: 'FKP', name: 'FKP - Falkland Islands pound' },
  { code: 'GBP', name: 'GBP - British Pounds' },
  { code: 'GEL', name: 'GEL - Georgian lari' },
  { code: 'GHS', name: 'GHS - Ghanaian cedi' },
  { code: 'GIP', name: 'GIP - Gibraltar pound' },
  { code: 'GMD', name: 'GMD - Gambian dalasi' },
  { code: 'GNF', name: 'GNF - Guinean franc' },
  { code: 'GTQ', name: 'GTQ - Guatemalan quetzal' },
  { code: 'GYD', name: 'GYD - Guyanese dollar' },
  { code: 'HKD', name: 'HKD - Hong Kong dollar' },
  { code: 'HNL', name: 'HNL - Honduran lempira' },
  { code: 'HRK', name: 'HRK - Croatian kuna' },
  { code: 'HTG', name: 'HTG - Haitian gourde' },
  { code: 'HUF', name: 'HUF - Hungarian forint' },
  { code: 'IDR', name: 'IDR - Indonesian rupiah' },
  { code: 'ILS', name: 'ILS - Israeli shekels' },
  { code: 'INR', name: 'INR - Indian rupee' },
  { code: 'ISK', name: 'ISK - Icelandic króna' },
  { code: 'JMD', name: 'JMD - Jamaican dollar' },
  { code: 'JPY', name: 'JPY - Japanese yen' },
  { code: 'KES', name: 'KES - Kenyan shilling' },
  { code: 'KGS', name: 'KGS - Kyrgyzstani som' },
  { code: 'KHR', name: 'KHR - Cambodian riel' },
  { code: 'KMF', name: 'KMF - Comorian franc' },
  { code: 'KRW', name: 'KRW - South Korean won' },
  { code: 'KYD', name: 'KYD - Cayman Islands dollar' },
  { code: 'KZT', name: 'KZT - Kazakhstan tenge' },
  { code: 'LAK', name: 'LAK - Lao kip' },
  { code: 'LBP', name: 'LBP - Lebanese pound' },
  { code: 'LKR', name: 'LKR - Sri Lankan rupee' },
  { code: 'LRD', name: 'LRD - Liberian dollar' },
  { code: 'LSL', name: 'LSL - Lesotho loti' },
  { code: 'MAD', name: 'MAD - Moroccan dirham' },
  { code: 'MDL', name: 'MDL - Moldovan leu' },
  { code: 'MGA', name: 'MGA - Malagasy ariary' },
  { code: 'MKD', name: 'MKD - Macedonian denar' },
  { code: 'MMK', name: 'MMK - Myanmar kyat' },
  { code: 'MNT', name: 'MNT - Mongolian tögrög' },
  { code: 'MOP', name: 'MOP - Macanese pataca' },
  { code: 'MRO', name: 'MRO - Mauritanian ouguiya' },
  { code: 'MUR', name: 'MUR - Mauritian rupee' },
  { code: 'MVR', name: 'MVR - Maldivian rufiyaa' },
  { code: 'MWK', name: 'MWK - Malawian kwacha' },
  { code: 'MXN', name: 'MXN - Mexican peso' },
  { code: 'MYR', name: 'MYR - Malaysian ringgit' },
  { code: 'MZN', name: 'MZN - Mozambican metical' },
  { code: 'NAD', name: 'NAD - Namibian dollar' },
  { code: 'NGN', name: 'NGN - Nigerian naira' },
  { code: 'NIO', name: 'NIO - Nicaraguan córdoba' },
  { code: 'NOK', name: 'NOK - Norwegian krone' },
  { code: 'NPR', name: 'NPR - Nepalese rupee' },
  { code: 'NZD', name: 'NZD - New Zealand dollar' },
  { code: 'PAB', name: 'PAB - Panamanian balboa' },
  { code: 'PEN', name: 'PEN - Peruvian sol' },
  { code: 'PGK', name: 'PGK - Papua New Guinean kina' },
  { code: 'PHP', name: 'PHP - Philippine peso' },
  { code: 'PKR', name: 'PKR - Pakistani rupee' },
  { code: 'PLN', name: 'PLN - Polish zloty' },
  { code: 'PYG', name: 'PYG - Paraguayan guaraní' },
  { code: 'QAR', name: 'QAR - Qatari riyal' },
  { code: 'RON', name: 'RON - Romanian leu' },
  { code: 'RSD', name: 'RSD - Serbian dinar' },
  { code: 'RUB', name: 'RUB - Russian ruble' },
  { code: 'RWF', name: 'RWF - Rwandan franc' },
  { code: 'SAR', name: 'SAR - Saudi riyal' },
  { code: 'SBD', name: 'SBD - Soloman Islands dollar' },
  { code: 'SCR', name: 'SCR - Seychelles rupee' },
  { code: 'SEK', name: 'SEK - Swedish krona' },
  { code: 'SGD', name: 'SGD - Singapore dollar' },
  { code: 'SHP', name: 'SHP - Saint Helena pound' },
  { code: 'SLL', name: 'SLL - Sierra Leonean leone' },
  { code: 'SOS', name: 'SOS - Somali shilling' },
  { code: 'SRD', name: 'SRD - Surinamese dollar' },
  { code: 'STD', name: 'STD - São Tomé and Príncipe dobra' },
  { code: 'SZL', name: 'SZL - Swazi lilangeni' },
  { code: 'THB', name: 'THB - Thai baht' },
  { code: 'TJS', name: 'TJS - Tajikistani somoni' },
  { code: 'TOP', name: 'TOP - Tongan pa\'anga' },
  { code: 'TRY', name: 'TRY - Turkish lira' },
  { code: 'TTD', name: 'TTD - Trinidad and Tobago dollar' },
  { code: 'TWD', name: 'TWD - New Taiwan dollar' },
  { code: 'TZS', name: 'TZS - Tanzanian shilling' },
  { code: 'UAH', name: 'UAH - Ukrainian hryvnia' },
  { code: 'UGX', name: 'UGX - Ugandan shilling' },
  { code: 'UYU', name: 'UYU - Uruguayan peso' },
  { code: 'UZS', name: 'UZS - Uzbekistan som' },
  { code: 'VND', name: 'VND - Vietnamese đồng' },
  { code: 'VUV', name: 'VUV - Vanuatu vatu' },
  { code: 'WST', name: 'WST - Samoan tala' },
  { code: 'XAF', name: 'XAF - Central African CFA franc' },
  { code: 'XCD', name: 'XCD - East Caribbean dollar' },
  { code: 'XOF', name: 'XOF - West African CFA franc' },
  { code: 'XPF', name: 'XPF - CFP franc' },
  { code: 'YER', name: 'YER - Yemeni rial' },
  { code: 'ZAR', name: 'ZAR - South African rand' },
  { code: 'ZMW', name: 'ZMW - Zambian kwacha' },
];
