<template>
  <ChecCard inner-class="tax-actions" tailwind="p-4">
    <ChecButton
      color="green"
      :disabled="saving"
      button-type="submit"
      @click="() => $emit('save-tax')"
    >
      {{ saveLabel }}
    </ChecButton>
    <hr class="tax-actions__rule">
    <ChecSwitch
      v-model="isEnabled"
      name="enabled"
    >
      {{ $t('settings.tax.enableTax') }}
    </ChecSwitch>
  </ChecCard>
</template>

<script>
import {
  ChecButton,
  ChecCard,
  ChecSwitch,
} from '@chec/ui-library';

export default {
  name: 'ActionsCard',
  components: {
    ChecButton,
    ChecCard,
    ChecSwitch,
  },
  props: {
    saving: Boolean,
    enabled: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    isEnabled: {
      get() {
        return this.enabled;
      },
      set(value) {
        this.$emit('toggle-enabled', value);
      },
    },
    /**
     * The label shown for the "save" button
     *
     * @returns {string}
     */
    saveLabel() {
      return this.saving ? this.$t('general.saving') : this.$t('general.saveChanges');
    },
  },
};
</script>

<style lang="scss">
.tax-actions {
  @apply flex flex-col;

  &__rule {
    @apply border-gray-200 mt-4 mb-2;
  }
}
</style>
