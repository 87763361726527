<template>
  <DashboardTable
    class="configuration-table"
    :align-right-after="2"
    :columns="[
      $t('general.label'),
      $t('general.value'),
      $t('general.actions'),
    ]"
    snug
  >
    <tr
      v-for="({ label, value }, index) in configurationData"
      :key="index"
    >
      <td>
        <TextField
          v-model="configurationData[index].label"
          :value="label"
          :label="$t('general.label')"
          name="label"
          class="configuration-table__label"
          @input="value => handleOptionUpdate"
        />
      </td>
      <td>
        <TextField
          v-model="configurationData[index].value"
          :value="value"
          :label="$t('general.value')"
          name="label"
          class="configuration-table__label"
          @input="value => handleOptionUpdate"
        />
      </td>
      <td>
        <ChecOptionsMenu class="configuration-table__actions-button">
          <ChecOption destructive @option-selected="handleDeleteRow(index)">
            {{ $t('general.delete') }}
          </ChecOption>
        </ChecOptionsMenu>
      </td>
    </tr>
  </DashboardTable>
</template>

<script>
import {
  ChecOptionsMenu,
  ChecOption,
  TextField,
} from '@chec/ui-library';
import DashboardTable from '@/components/DashboardTable.vue';

export default {
  name: 'ConfigurationTable',
  components: {
    ChecOptionsMenu,
    ChecOption,
    DashboardTable,
    TextField,
  },
  props: {
    configurations: Array,
  },
  data() {
    return {
      configurationData: [],
    };
  },
  watch: {
    configurations: {
      handler() {
        this.configurationData = this.configurations;
      },
      deep: true,
    },
  },
  mounted() {
    if (this.configurations.length > 0) {
      this.configurationData = this.configurations;
    }
  },
  methods: {
    handleDeleteRow(index) {
      this.configurationData.splice(index, 1);
    },
    handleOptionUpdate() {
      this.$emit('update-configuration', this.configurationData);
    },
  },
};
</script>

<style lang="scss">
.configuration-table {
  @apply w-full mt-4;

  &__actions-button {
    @apply flex justify-end;
  }
}
</style>
