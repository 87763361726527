<template>
  <ChecCard class="billing-plans" tailwind="p-4">
    <ChecHeader variant="card" :title="$t('billing.changePlan')" />
    <table class="billing-plans-table">
      <tbody>
        <tr v-for="({ plan_name, transaction_fee, monthly_fee, slug }, i) in plans" :key="i">
          <td>
            <PlanThumbnail :name="plan_name" />
          </td>
          <td class="billing-plans-table__transaction-fee">
            <template v-if="transaction_fee">
              {{ $t('billing.planTransactionFee', { fee: transaction_fee.print }) }}
            </template>
          </td>
          <td class="billing-plans-table__monthly-fee">
            <template v-if="monthly_fee">
              <span v-if="plan_name === 'Standard'">
                <strong>$10.00</strong>
                {{ $t('billing.min') }}
                {{ $t('billing.perMonth') }}
              </span>
              <span v-else>
                <span class="font-bold">{{ money(monthly_fee).full() }}</span>
                {{ $t('billing.perMonth') }}
              </span>
            </template>
            <template v-else>
              {{ $t('billing.customPricing') }}
            </template>
          </td>
          <td>
            <div class="billing-plans-table__inner-actions-cell">
              <ChecDataPill
                v-if="isCurrentPlan(slug)"
                color="green"
              >
                {{ $t('billing.currentPlan') }}
              </ChecDataPill>
              <template v-else>
                <router-link
                  v-if="monthly_fee"
                  :to="getLink(slug)"
                >
                  {{ $t('general.viewDetails') }}
                </router-link>
                <a
                  v-else
                  href="mailto:hello+sales@commercejs.com"
                >
                  {{ $t('general.contactUs') }}
                </a>
              </template>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </ChecCard>
</template>

<script>
import { mapActions } from 'vuex';
import { ChecCard, ChecDataPill, ChecHeader } from '@chec/ui-library';
import actions from '@/store/actions';
import getPlanIconName from '@/mixins/getPlanIconName';
import money from '@/lib/helpers/money';
import PlanThumbnail from './PlanThumbnail.vue';

const { REQUEST_CHANGE_PLAN } = actions;

export default {
  name: 'PlanTable',
  components: {
    ChecCard,
    ChecDataPill,
    ChecHeader,
    PlanThumbnail,
  },
  mixins: [getPlanIconName],
  props: {
    /**
     * List of plans
     */
    plans: {
      type: Array,
      default: () => [],
    },
    /**
     * Merchant's current plan
     */
    currentPlan: {
      type: Object,
    },
  },
  methods: {
    isCurrentPlan(planSlug) {
      return this.currentPlan && planSlug === this.currentPlan.slug;
    },
    getLink(slug) {
      return {
        name: 'settings.billing.plan',
        params: { slug },
      };
    },
    ...mapActions({
      changePlan: `billing/${REQUEST_CHANGE_PLAN}`,
    }),
    money,
  },
};
</script>

<style lang="scss" scoped>
.billing-plans {
  @apply self-start mt-8 flex-1;

  @screen lg {
    @apply mt-0 ml-8;
  }
}

.billing-plans-table {
  @apply table-fixed w-full mt-4;

  tr {
    &:hover {
      @apply bg-white;
    }

    td {
      @apply w-1/4 py-4;
    }
  }

  &__transaction-fee {
    @apply text-xs text-right;
  }

  &__monthly-fee {
    @apply text-sm text-gray-500 text-right;
  }

  &__inner-actions-cell {
    @apply float-right text-sm;
  }
}
</style>
