<template>
  <ChecCard inner-class="account-delete" tailwind="p-4">
    <DashboardHeader
      variant="card"
      margin="small"
      :title="$t('account.deleteAccount')"
    >
      <ChecButton
        color="red"
        variant="round"
        class="account__save"
        @click="() => $emit('delete-account')"
      >
        {{ $t('account.deleteAccount') }}
      </ChecButton>
    </DashboardHeader>
    <ChecAlert
      variant="warning"
      inline
      show-icon
      disable-close
      class="account-delete__alert-message"
    >
      {{ $t('account.deleteMessage') }}
    </ChecAlert>
  </ChecCard>
</template>

<script>
import {
  ChecButton,
  ChecAlert,
  ChecCard,
} from '@chec/ui-library';
import DashboardHeader from '@/components/DashboardHeader.vue';

export default {
  name: 'DeleteAccountCard',
  components: {
    ChecButton,
    ChecAlert,
    ChecCard,
    DashboardHeader,
  },
};
</script>
