import i18n from '@/lang';
import {
  string, object, bool,
} from 'yup';

export default object().shape({
  enabled: bool(),
  eu_vat_moss: bool(),
  prices_include_tax: bool(),
  address: object({
    town_city: string().nullable().required(i18n.t('validation.validCity')),
    country: string().nullable().required(i18n.t('validation.validCountry')),
    postal_zip_code: string().nullable().required(i18n.t('validation.validPostalZipCode')),
    county_state: string().nullable().required(i18n.t('validation.validRegion')),
    street: string().nullable().required(i18n.t('validation.validStreet')),
  }),
});
