<template>
  <ChecCard
    v-if="details.plan"
    borders="full"
    class="current-plan-card"
    tailwind="p-4"
  >
    <ChecHeader variant="card" :title="$t('billing.currentPlan')" />
    <div class="current-plan-thumbnail">
      <ChecMarketingIcon
        v-if="details.plan.plan_name"
        :icon="getPlanIconName(details.plan.plan_name, true)"
        class="current-plan-thumbnail__logo"
      />
      <div class="current-plan-thumbnail__plan">
        <h4 class="current-plan-thumbnail__plan-title">
          {{ details.plan.plan_name }}
        </h4>
        <template v-if="details.plan.monthly_fee">
          <span
            v-if="details.plan.plan_name !== 'Standard'"
            class="current-plan-thumbnail__price"
          >
            {{ money(details.plan.monthly_fee).full() }}
          </span>
          <span v-else>
            <strong>$10.00</strong>
            {{ $t('billing.min') }}
          </span>
          {{ $t('billing.perMonth') }}
        </template>
        <template v-else>
          {{ $t('billing.customPricing') }}
        </template>
      </div>
    </div>

    <InnerBlock :title="$t('billing.details')">
      <p v-if="details.plan.transaction_fee">
        {{ $t('billing.planTransactionFee', { fee: details.plan.transaction_fee.print }) }}
      </p>
      <p v-if="details.plan.monthly_ceiling">
        {{ $t(
          'billing.planMonthlyCeiling',
          { ceiling: money(details.plan.monthly_ceiling).full() }
        ) }}
      </p>
      <p v-if="details.plan.overage">
        {{ $t('billing.planOverage', { overage: details.plan.overage.print }) }}
      </p>
    </InnerBlock>

    <InnerBlock v-if="details.accrued_transaction_fees" :title="$t('billing.accruedFees') ">
      {{ money(details.accrued_transaction_fees).full() }}
    </InnerBlock>

    <PaymentMethodSection />

    <InnerBlock
      :title="$t('billing.emailAddress')"
      action-text="Update"
      @action="openEmailModal"
    >
      <span :title="details.billing_email" class="current-plan-card__billing-email">
        {{ details.billing_email }}
      </span>
    </InnerBlock>
    <BillingEmailModal v-if="emailModalOpen" @close="closeEmailModal" />
  </ChecCard>
</template>

<script>
import {
  ChecCard,
  ChecHeader,
  ChecMarketingIcon,
  InnerBlock,
} from '@chec/ui-library';
import getPlanIconName from '@/mixins/getPlanIconName';
import money from '@/lib/helpers/money';
import BillingEmailModal from './BillingEmailModal.vue';
import PaymentMethodSection from './PaymentMethodSection.vue';

export default {
  name: 'CurrentPlanCard',
  components: {
    PaymentMethodSection,
    BillingEmailModal,
    ChecCard,
    ChecHeader,
    ChecMarketingIcon,
    InnerBlock,
  },
  mixins: [getPlanIconName],
  props: {
    /**
     * General info about merchants billing - current plan, accrued fees, billing email
     */
    details: {
      type: Object,
      required: true,
      default: () => ({}),
    },
  },
  data() {
    return {
      emailModalOpen: false,
    };
  },
  methods: {
    money,
    openEmailModal() {
      this.emailModalOpen = true;
    },
    closeEmailModal() {
      this.emailModalOpen = false;
    },
  },
};
</script>

<style lang="scss">
.current-plan-card {
  @apply leading-tight;

  @screen xl {
    @apply w-1/3;
    max-width: 22rem;
  }

  .card-inner-block {
    @apply mt-2;
  }

  &__billing-email {
    @apply block truncate w-48;
  }
}

.current-plan-thumbnail {
  @apply flex items-center my-4;

  &__logo {
    @apply w-full h-auto mr-4;
    max-width: 80px;
  }

  &__plan {
    @apply text-gray-600 text-lg;
  }

  &__price {
    @apply font-bold text-black;
  }

  &__plan-title {
    @apply text-xl mb-2 font-bold text-gray-600;
  }
}
</style>
