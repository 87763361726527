<template>
  <ChecModal
    class="billing-email-modal"
    width="lg"
    :header="$t('billing.updateEmail')"
    @dismiss="emitClose"
  >
    <form @submit.prevent="updateEmail">
      <TextField
        ref="input"
        v-model="email"
        :label="$t('general.emailAddress')"
        type="email"
        required
      />
      <div class="billing-email-modal__actions">
        <ChecButton color="primary" text-only @click="emitClose">
          {{ $t('general.cancel') }}
        </ChecButton>
        <ChecButton color="primary" button-type="submit">
          {{ $t('general.saveChanges') }}
        </ChecButton>
      </div>
    </form>
  </ChecModal>
</template>

<script>
import { ChecButton, ChecModal, TextField } from '@chec/ui-library';
import { mapActions } from 'vuex';
import actions from '@/store/actions';

export default {
  name: 'BillingEmailModal',
  components: { ChecButton, ChecModal, TextField },
  data() {
    return {
      email: this.$store.state.billing.details.billing_email,
    };
  },
  mounted() {
    this.$refs.input.$el.focus();
  },
  methods: {
    emitClose() {
      this.$emit('close');
    },
    updateEmail() {
      this.setBillingEmail(this.email);
      this.emitClose();
    },
    ...mapActions('billing', { setBillingEmail: actions.SET_BILLING_EMAIL }),
  },
};
</script>

<style lang="scss">
.billing-email-modal {
  &__actions {
    @apply flex justify-between mt-8;
  }
}
</style>
