<template>
  <ChecCard
    class="general-settings__card"
    inner-class="details-card"
  >
    <ChecHeader
      variant="card"
      :title="$t('general.details')"
    />
    <div class="input-wrapper__row space-x-2">
      <div class="input-wrapper">
        <ChecFormField
          :tooltip="$t('settings.merchant.nameTooltip')"
          :append-label="$t('general.whatIsThis')"
        >
          <TextField
            :value="merchant.name"
            name="name"
            :label="$t('settings.merchant.name')"
            :variant="errors.name ? 'error' : ''"
            required
            @input="value => change('name', value)"
          />
        </ChecFormField>
        <span v-if="errors.name" class="input-wrapper__error">
          {{ errors.name }}
        </span>
      </div>
      <div class="input-wrapper">
        <ChecFormField
          :tooltip="$t('settings.merchant.emailTooltip')"
          :append-label="$t('general.whatIsThis')"
        >
          <TextField
            :value="merchant.email"
            name="email"
            required
            :label="$t('general.emailAddress')"
            @input="value => change('email', value)"
          />
        </ChecFormField>
        <span v-if="errors.email" class="input-wrapper__error">
          {{ errors.email }}
        </span>
      </div>
    </div>
    <div class="input-wrapper">
      <ChecFormField
        :tooltip="$t('settings.merchant.descriptionTooltip')"
        :append-label="$t('general.whatIsThis')"
      >
        <ChecWysiwyg
          :value="merchant.description"
          name="description"
          :label="$t('general.description')"
          @input="value => change('description', value)"
        />
      </ChecFormField>
    </div>
  </ChecCard>
</template>

<script>
import {
  ChecCard,
  ChecHeader,
  ChecFormField,
  TextField,
} from '@chec/ui-library';
import ChecWysiwyg from '@chec/ui-library/dist/ChecWysiwyg';

export default {
  name: 'GeneralSettings',
  components: {
    ChecCard,
    ChecHeader,
    ChecFormField,
    ChecWysiwyg,
    TextField,
  },
  model: {
    prop: 'merchant',
    event: 'input',
  },
  props: {
    merchant: {
      type: Object,
      required: true,
    },
    errors: {
      type: Object,
      default: () => ({}),
    },
  },
  methods: {
    change(prop, value) {
      this.$emit('input', {
        ...this.merchant,
        [prop]: value,
      });
    },
  },
};
</script>

<style lang="scss">
.details-card {
  @apply p-4;

  .chec-header {
    @apply mb-4;
  }
}

.general-settings {
  &__inventory-available {
    .text-field--disabled .text-field__right-content {
      @apply opacity-100; // by default this would be .5
    }
  }
}
</style>
