<script>
/* global Chargebee */
import { mapActions, mapMutations } from 'vuex';
import mutations from '@/store/mutations';
import actions from '@/store/actions';
import { makeApiRequest } from '@/lib/api';

export default {
  name: 'PaymentMethodModal',
  created() {
    this.$loadScript('https://js.chargebee.com/v2/chargebee.js').then(() => {
      const ChargeBeeInstance = Chargebee.init({ site: 'chec' });
      ChargeBeeInstance.openCheckout({
        hostedPage() {
          return makeApiRequest('GET', '/v1/billing/payment-url')
            .then((response) => response.data);
        },
        loaded: () => {
          this.setLoading(false);
        },
        close: () => {
          this.updateDetails(true);
          this.$emit('close');
        },
      });
    });
  },
  mounted() {
    this.setLoading(true);
  },
  methods: {
    ...mapMutations({ setLoading: mutations.SET_LOADING }),
    ...mapActions('billing', { updateDetails: actions.FETCH_BILLING }),
  },
  render() {
    return '';
  },
};
</script>

<style>

</style>
