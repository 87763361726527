import {
  array,
  mixed,
  object,
  string,
} from 'yup';
import validNumber from '@/lib/helpers/validateNumber';
import i18n from '@/lang';

export default object().shape({
  code: string().required(i18n.t('discount.codeRequired')),
  type: string().oneOf(['fixed', 'percentage']),
  value: string().matches(validNumber, i18n.t('validation.validNumber'))
    .required(
      i18n.t('discount.valueRequired'),
    ),
  expires: string().nullable(),
  quantity: mixed().nullable(),
  for: array().of(string()), // product IDs
});
