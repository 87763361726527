<template>
  <div class="attributes-table">
    <DashboardTable
      class="attributes-table__table"
      :align-right-after="4"
      :columns="[
        { title: $t('general.name'), class: 'attributes-table__row-header-name' },
        { title: $t('general.id') },
        { title: $t('general.type') },
        { title: $t('general.visibility') },
        { title: $t('general.required') },
        { title: $t('general.actions') },
      ]"
      show-search
      state-key="settings/attributes"
    >
      <AttributeRow
        v-for="attribute in attributes"
        :key="attribute.id"
        :attribute="attribute"
        @edit-attribute="handleEditAttribute(attribute)"
        @delete-attribute="handleDeleteAttribute(attribute)"
      />
    </DashboardTable>
  </div>
</template>

<script>
import DashboardTable from '@/components/DashboardTable.vue';
import crud from '@/mixins/crud';
import confirm from '@/mixins/confirm';
import addNotification from '@/mixins/addNotification';
import AttributeRow from './AttributeRow.vue';

export default {
  name: 'AttributesTable',
  components: {
    DashboardTable,
    AttributeRow,
  },
  mixins: [
    crud('settings/attributes', true),
    addNotification,
    confirm,
  ],
  methods: {
    /**
     * Edit the attribute
     */
    handleEditAttribute(attribute) {
      this.$router.push({
        name: 'settings.attributes.edit',
        params: { id: attribute.id },
      });
    },
    /**
     * Deletes attribute
     */
    async handleDeleteAttribute(attribute) {
      // Handle confirm modal
      if (!await this.confirm(
        this.$t('general.areYouSure'),
        this.$t('attributes.confirmDelete'),
      )) {
        return;
      }

      // Use attribute delete CRUD
      this.delete(attribute.id).then(() => {
        this.addNotification(this.$t('attributes.deleted'));
      }).catch((error) => {
        this.addNotification(this.$t('attributes.deleteFailed'), 'error');
        throw error;
      });
    },
  },
};
</script>

<style lang="scss">
.attributes-table {
  &__row-header-name {
    @apply w-1/2;
  }
}
</style>
