<template>
  <DashboardTable
    class="logs-table"
    :align-right-after="3"
    :columns="[
      { title: $t('developer.method'), class: 'logs-table__method-header' },
      { title: $t('developer.url') },
      { title: $t('general.status'), class: 'logs-table__status-header' },
      { title: $t('developer.dateTime') },
    ]"
    show-search
    state-key="developerLogs"
  >
    <LogRow
      v-for="({
        id,
        method,
        url,
        status_code: statusCode,
        created,
      }) in logs"
      :key="id"
      :method="method"
      :url="url"
      :status-code="statusCode"
      :time="created"
      :class="{ 'logs-table-row--active': isActive(id) }"
      @keypress.native.enter="() => handleSelectLog(id)"
      @click="() => handleSelectLog(id)"
    />
  </DashboardTable>
</template>

<script>
import router from 'vue-router';
import DashboardTable from '@/components/DashboardTable.vue';
import LogRow from './LogRow.vue';

const { isNavigationFailure, NavigationFailureType } = router;

export default {
  name: 'LogsTable',
  components: {
    DashboardTable,
    LogRow,
  },
  props: {
    /**
     * List of developer logs
     */
    logs: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    /**
     * Is the current log entry active
     */
    isActive(id) {
      return this.$route.params.id === id;
    },
    /**
     * Emit event to display log entry
     */
    handleSelectLog(id) {
      this.$router.push({ name: 'developer.logs.view', params: { id } })
        .catch((error) => {
          // Avoid errors for duplicated clicks on log entries
          if (
            isNavigationFailure(error, NavigationFailureType.duplicated)
            || isNavigationFailure(error, NavigationFailureType.cancelled)
          ) {
            return;
          }
          throw error;
        });
    },
  },
};
</script>

<style lang="scss">
.logs-table {
  @apply w-full;

  &__method-header {
    @apply w-16;
  }

  &__status-header {
    @apply w-20;
  }
}
</style>
