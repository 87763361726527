<template>
  <ChecSlideoutPanel
    :title="mode === 'add' ?
      $t('settings.developerSettings.addKey') :
      $t('settings.developerSettings.editKey')"
    close-on-overlay-click
    size=""
    @close="close"
  >
    <ChecLoading v-if="isLoading || loading" />
    <template>
      <ChecTable
        v-if="mode === 'edit'"
        class="status-table"
      >
        <tbody>
          <tr>
            <td>
              {{ $t('settings.developerSettings.type') }}
            </td>
            <td>
              <ChecDataPill
                :color="updatedApiKey.type === 'public' ? 'blue' : 'red' "
              >
                {{ updatedApiKey.type }}
              </ChecDataPill>
            </td>
          </tr>
          <tr>
            <td>
              {{ $t('general.environment') }}
            </td>
            <td>
              <ChecDataPill
                :color="updatedApiKey.is_sandbox ? 'orange' : 'green' "
              >
                {{ statusCopy }}
              </ChecDataPill>
            </td>
          </tr>
        </tbody>
      </ChecTable>
      <div
        v-else
        class="input-wrapper__row space-x-2"
      >
        <div class="input-wrapper">
          <ChecDropdown
            v-model="updatedApiKey.type"
            required
            :label="$t('settings.developerSettings.type')"
            name="type"
            :options="typeOptions"
          />
        </div>
        <div class="input-wrapper">
          <ChecDropdown
            v-model="updatedApiKey.is_sandbox"
            required
            :label="$t('general.environment')"
            name="sandbox"
            :options="modeOptions"
          />
        </div>
      </div>
      <div class="input-wrapper__row space-x-2">
        <div class="input-wrapper">
          <TextField
            v-model="updatedApiKey.label"
            :label="$t('settings.developerSettings.label')"
            name="quantity"
          />
        </div>
        <div class="input-wrapper">
          <ChecFormField
            class="w-full"
            :append-label="$t('settings.developerSettings.changelog')"
            @action-click="seeChangelog"
          >
            <ChecDropdown
              v-model="updatedApiKey.api_version"
              required
              :label="$t('settings.developerSettings.apiVersion')"
              name="apiVersons"
              :options="apiOptions"
            />
          </ChecFormField>
        </div>
      </div>
      <div
        v-if="mode === 'edit'"
        class="input-wrapper manage-api-key__api-field"
      >
        <TextField
          :value="updatedApiKey.key"
          :label="$t('settings.developerSettings.apiKey')"
          readonly
        >
          <ChecButton
            v-tooltip="$t('settings.developerSettings.resetKeyButtonTooltip')"
            color="primary"
            variant="round"
            @click="generateKey"
          >
            <template #icon>
              <ChecIcon class="manage-api-key__copy-button-icon" icon="refresh" />
            </template>
          </ChecButton>
          <CopyButton
            class="manage-api-key__copy-button"
            :copy-content="apiKey.key"
            color="primary"
          />
        </TextField>
      </div>
    </template>
    <template #toolbar>
      <div class="manage-api-key__toolbar space-x-4">
        <ChecButton text-only color="primary" @click="close">
          {{ $t('general.cancel' ) }}
        </ChecButton>
        <ChecButton
          button-type="submit"
          color="primary"
          :disabled="saving"
          @click="handleSaveKey(updatedApiKey)"
        >
          {{ saveButtonLabel }}
        </ChecButton>
      </div>
    </template>
  </ChecSlideoutPanel>
</template>

<script>
import {
  ChecButton,
  ChecDataPill,
  ChecDropdown,
  ChecFormField,
  ChecIcon,
  ChecTable,
  ChecLoading,
  ChecSlideoutPanel,
  TextField,
} from '@chec/ui-library';
import { mapGetters, mapActions } from 'vuex';
import { actions } from '@/modules/merchant/store/api-keys';
import addNotification from '@/mixins/addNotification';
import confirm from '@/mixins/confirm';
import CopyButton from '@/components/CopyButton.vue';
import apiVersions from '../../../../../content/versions.json';

export default {
  name: 'EditPanel',
  components: {
    ChecButton,
    ChecDataPill,
    ChecDropdown,
    ChecFormField,
    ChecIcon,
    ChecTable,
    CopyButton,
    ChecLoading,
    ChecSlideoutPanel,
    TextField,
  },
  mixins: [
    addNotification,
    confirm,
  ],
  props: {
    apiKey: Object,
    mode: String,
  },
  data() {
    return {
      saving: false,
      loading: false,
      updatedApiKey: {
        api_version: apiVersions[0].name || '',
        is_sandbox: 'sandbox',
        type: 'public',
      },
      typeOptions: [
        {
          value: 'public',
          label: this.$t('settings.developerSettings.public'),
        },
        {
          value: 'secret',
          label: this.$t('settings.developerSettings.secret'),
        },
      ],
      modeOptions: [
        {
          value: 'sandbox',
          label: this.$t('settings.developerSettings.sandbox'),
        },
        {
          value: 'live',
          label: this.$t('settings.developerSettings.live'),
        },
      ],
    };
  },
  computed: {
    ...mapGetters(['isLoading']),
    /**
     * @returns {string}
     */
    saveButtonLabel() {
      if (this.saving) {
        return this.$t('general.saving');
      }
      if (this.mode === 'add') {
        return this.$t('settings.developerSettings.createKey');
      }
      return this.$t('general.saveChanges');
    },
    statusCopy() {
      if (this.updatedApiKey.is_sandbox) {
        return this.$t('settings.developerSettings.sandbox');
      }
      return this.$t('settings.developerSettings.live');
    },
    apiOptions() {
      if (apiVersions.length !== 0) {
        return apiVersions
          .map((version) => ({
            value: version.name,
            label: version.is_current
              ? this.$t('versions.current', { version: version.name })
              : version.name,
          }));
      }
      return [];
    },
  },
  mounted() {
    this.updateApiKeyState();
  },
  methods: {
    ...mapActions('merchant/apiKeys', {
      regenerateKey: actions.REGENERATE_KEY,
    }),
    seeChangelog() {
      window.open('https://commercejs.com/docs/release-notes/');
    },
    close() {
      this.$emit('close');
    },
    updateApiKeyState() {
      if (this.apiKey && this.apiKey.id) {
        // Set legacy and null key to current version on edit.
        this.updatedApiKey = {
          ...this.apiKey,
          api_version: this.apiKey.api_version
            || apiVersions[0].name,
        };
      }
    },
    async generateKey() {
      if (!await this.confirm(
        this.$t('settings.developerSettings.resetKeyConfirmationHeader'),
        this.$t('settings.developerSettings.generateKey'),
      )) {
        return;
      }

      this.loading = true;
      try {
        await this.regenerateKey({
          keyId: this.updatedApiKey.id,
        });
        this.updatedApiKey = this.apiKey;
        this.loading = false;
        this.addNotification(this.$t('settings.developerSettings.keyChanged'));
        this.close();
      } catch (error) {
        this.loading = false;
        if (error.response.status === 400) {
          this.addNotification(error.response.data.error.message, 'error');
          return;
        }
        this.addNotification(
          this.$t('settings.developerSettings.keyChangeFailed'),
          'error',
        );
      }
    },
    handleSaveKey(keyData) {
      this.$emit('save-key', keyData);
    },
  },
};
</script>

<style lang="scss">
.manage-api-key {
  &__toolbar,
  &__hero-toolbar {
    @apply w-full flex justify-end items-center;
  }

  &__hero-toolbar {
    @apply mt-8;
  }

  &__api-field {
    @apply flex w-full gap-4;

    .text-field {
      @apply flex-grow;
    }
  }

  &__copy-button {
    @apply ml-2; }

  &__copy-button-icon {
    height: 13px;
  }
}

.status-table {
  @apply mb-8;

  table {
    @apply w-full h-px;

    td:first-of-type {
      @apply caps-xxs;
    }
  }

  tbody {
    @apply h-full;
  }

  td:last-of-type {
    @apply text-right;
  }
}

.api-key-edit {
  &__preview-title {
    @apply mt-8 mb-4 font-bold text-xl leading-none;
  }

  &__alert {
    @apply mt-2;
  }

  &__alert-copy {
    @apply flex justify-between items-center w-full;
  }

  &__options-listing {
    @apply flex flex-wrap gap-2;
  }

  &__option {
    flex: 1 1 20%;
  }
}
</style>
