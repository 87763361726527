<template>
  <form class="attribute-add-edit" @submit.prevent>
    <DashboardHeader :title="headerText" />
    <ChecLoading v-if="loading" without-background />
    <div v-else class="attribute-add-edit__content space-y-4 md:space-y-0">
      <div class="attribute-add-edit__main space-y-4">
        <DetailsCard
          v-model="attribute"
          :attribute="attribute"
          :errors="validationErrors"
        />
        <ConfigurationCard
          v-if="attribute.type === 'options'
            || attribute.type === 'radio'"
          v-model="attribute"
          :attribute="attribute"
          :errors="validationErrors"
          @options-change="handleUpdateOptions"
        />
      </div>
      <div class="attribute-add-edit__aside space-y-4">
        <ActionsCard
          :allow-delete="!isAdding"
          :saving="saving"
          :disabled="saving || !canSave"
          :api-visibility="attribute.is_public"
          @save-attribute="handleSaveAttribute"
          @delete-attribute="handleDeleteAttribute"
          @toggle-active="(isPublic) => { attribute.is_public = isPublic; }"
        />
      </div>
    </div>
  </form>
</template>

<script>
import {
  ChecLoading,
} from '@chec/ui-library';
import addNotification from '@/mixins/addNotification';
import confirm from '@/mixins/confirm';
import crud from '@/mixins/crud';
import validateSchemaRequest from '@/lib/helpers/validateSchemaRequestHelper';
import DashboardHeader from '@/components/DashboardHeader.vue';
import { actions as paginationActions } from '@/lib/pagination';
import ActionsCard from '../components/ActionsCard.vue';
import DetailsCard from '../components/DetailsCard.vue';
import ConfigurationCard from '../components/ConfigurationCard.vue';
import attributeSchema from '../schemas/attribute';

export default {
  name: 'AddEditAttribute',
  components: {
    ChecLoading,
    DashboardHeader,
    ActionsCard,
    DetailsCard,
    ConfigurationCard,
  },
  mixins: [
    addNotification,
    confirm,
    crud('settings/attributes', true),
  ],
  data() {
    return {
      saving: false,
      loading: false,
      attribute: {
        name: '',
        required: false,
        is_public: false,
        is_multiselect: false,
        default_value: '',
        description: '',
        type: '',
        options: [],
      },
      attributeId: '',
      validationErrors: {},
    };
  },
  computed: {
    isAdding() {
      return this.$route.name === 'settings.attributes.add';
    },
    /**
     * Create the attribute title with the name
     *
     * @returns {string}
     */
    headerText() {
      if (this.isAdding) {
        return this.$t('attributes.add');
      }
      return this.$t('attributes.edit');
    },
    /**
     * Verify that the user can save
     *
     * @returns {boolean}
     */
    canSave() {
      if ([
        'options',
        'radio',
      ].includes(this.attribute.type)
        && this.attribute.options.length > 0
        && !this.hasNoEmptyOptions) {
        return false;
      }
      return true;
    },
    /**
     * Verify that there are no empty options
     *
     * @returns {boolean}
     */
    hasNoEmptyOptions() {
      return this.attribute.options.findIndex(
        (option) => option?.label?.trim() === ''
        || option?.value?.trim() === '',
      ) === -1;
    },
  },
  /**
   * Fetch attribute, if editing
   */
  mounted() {
    if (this.isAdding) {
      return;
    }

    this.cacheAttribute(this.$route.params.id);
  },
  methods: {
    cacheAttribute(attributeId) {
      this.attributeId = attributeId;
      this.load(this.attributeId).then(async () => {
        const attributeData = await this.get(this.attributeId);

        this.attribute = {
          ...attributeData,
          description: attributeData.description || '',
          default_value: attributeData.default_value || '',
          options: attributeData.options?.length ? attributeData.options : [],
        };
        this.attributeId = attributeData.id;
      }).catch(() => {
        this.$router.push({ name: 'notFound' });
      });
    },
    handleUpdateOptions(options) {
      this.attribute.options = options;
    },
    /**
     * Handle save for creating or updating attribute
     */
    handleSaveAttribute() {
      this.validationErrors = {};
      // Set saving to true when save button is clicked
      this.saving = true;

      validateSchemaRequest(attributeSchema, this.attribute, { abortEarly: false })
        .then(
          (validatedData) => (this.isAdding
            ? this.create(validatedData, true)
            : this.update(this.attributeId, validatedData, true)),
        )
        .then(({ id }) => {
          this.addNotification(
            this.isAdding ? this.$t('attributes.added') : this.$t('attributes.updated'),
          );
          // When attributes are added or updated the product attributes are reindex in the
          // background, so reset the product state to ensure they get re-fetched
          // with latest changes
          this.$store.dispatch(`products/${paginationActions.RESET}`);

          if (this.isAdding) {
            this.$router.push({ name: 'settings.attributes.edit', params: { id } });
          }
          this.cacheAttribute(id);
        })
        .catch((error) => {
          if (error.name === 'ValidationError') { // yup schema validation error
            this.validationErrors = error.errors;
            return;
          }
          this.addNotification(this.$t('attributes.saveFailure'), 'error');
        })
        .finally(() => { this.saving = false; });
    },
    /**
     * Deletes attribute
     */
    async handleDeleteAttribute() {
      // Handle confirm modal
      if (!await this.confirm(
        this.$t('general.areYouSure'),
        this.$t('attributes.confirmDelete'),
      )) {
        return;
      }
      const { id } = this.$route.params;
      await this.$router.push({ name: 'settings.attributes.home' });
      this.delete(id).then(() => {
        this.addNotification(this.$t('attributes.deleted'));
      }).catch((error) => {
        this.addNotification(this.$t('attributes.deleteFailed'), 'error');
        throw error;
      });
    },
  },
};
</script>

<style lang="scss">
.attribute-add-edit {
  &__aside {
    @screen lg {
      @apply ml-8 w-1/3 max-w-sm flex-shrink-0;
    }
  }

  @screen lg {
    &__content {
      @apply flex flex-row;
    }

    &__main {
      @apply flex-grow;

      .chec-header {
        @apply mb-4;
      }
    }
  }

  .card {
    @apply mb-8;
  }

  // Override to align error and info button
  &__field-type {
    .input-wrapper__error {
      @apply float-left;
    }

    .form-field-action-append {
      @apply float-right;
    }
  }

  .input-wrapper.attributes-add-edit__options-field {
    @apply w-full; // Override input wrapper width when in a row
  }

  .tags-field__input-wrapper input {
    width: 20rem; // Override tags field input width
  }
}
</style>
