<template>
  <div>
    <ChecSlideoutPanel
      :title="$t('settings.tax.euVatMoss')"
      close-on-overlay-click
      @close="close"
    >
      <template>
        <ChecAlert
          show-icon
          inline
          disable-close
          variant="info"
        >
          {{ $t('settings.tax.ratesAutoUpdated') }}
        </ChecAlert>
        <ChecTable class="eu-vat-moss-table">
          <thead>
            <tr>
              <th class="eu-vat-moss-table__row-header-country">
                {{ $t('general.country') }}
              </th>
              <th class="eu-vat-moss-table__row-header-standard">
                {{ $t('general.standard') }}
              </th>
              <th class="eu-vat-moss-table__row-header-digital">
                {{ $t('general.digital') }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-if="!euVatMoss">
              <td colspan="4" class="eu-vat-moss-table__loading-cell">
                <ChecLoading />
              </td>
            </tr>
            <template v-else>
              <EuVatMossRow
                v-for="(country, index) in euVatMoss"
                :key="index"
                :country="country"
              />
            </template>
          </tbody>
        </ChecTable>
      </template>
      <template #toolbar>
        <div class="eu-vat-moss-panel__toolbar space-x-4">
          <ChecButton
            button-type="submit"
            color="primary"
            @click="close"
          >
            {{ $t('general.close' ) }}
          </ChecButton>
        </div>
      </template>
    </ChecSlideoutPanel>
  </div>
</template>

<script>
import {
  ChecAlert,
  ChecButton,
  ChecLoading,
  ChecSlideoutPanel,
  ChecTable,
} from '@chec/ui-library';
import { mapActions, mapState } from 'vuex';
import actions from '@/store/actions';
import EuVatMossRow from '../../components/tax/EuVatMossTableRow.vue';

export default {
  name: 'EuVatMossTable',
  components: {
    ChecAlert,
    ChecSlideoutPanel,
    ChecLoading,
    ChecButton,
    ChecTable,
    EuVatMossRow,
  },
  computed: {
    ...mapState('settings/tax', ['euVatMoss']),
  },
  async mounted() {
    if (this.euVatMoss === null) {
      await this.loadEuVatMoss();
    }
  },
  methods: {
    ...mapActions('settings/tax', {
      loadEuVatMoss: actions.FETCH_EU_VAT_MOSS,
    }),
    close() {
      this.$router.push({ name: 'settings.tax' });
    },
  },
};
</script>

<style lang="scss">
.eu-vat-moss-panel {
  &__alert {
    @apply mb-4;
  }

  &__toolbar {
    @apply flex justify-end w-full;
  }
}

.eu-vat-moss-table {
  @apply mt-8;

  table {
    @apply w-full h-px;
  }

  tbody {
    @apply h-full;
  }

  tr:not(.eu-vat-moss-table__row--empty) > *:last-child {
    @apply;
  }

  &__row--empty {
    @apply text-left;
  }

  &__row-header-standard,
  &__row-header-digital,
  &__row-header-calculation {
    @apply w-1/4;
  }

  &__loading-cell {
    @apply relative p-8;

    .loading {
      @apply z-0;
    }
  }
}
</style>
