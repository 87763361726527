<template>
  <ChecCard inner-class="actions-card" tailwind="p-4">
    <div class="actions-card__primary">
      <ChecButton
        color="green"
        class="flex-grow"
        :disabled="saving || !allowSave"
        button-type="submit"
        @click="() => $emit('save-account')"
      >
        {{ saveLabel }}
      </ChecButton>
    </div>
  </ChecCard>
</template>

<script>
import {
  ChecButton,
  ChecCard,
} from '@chec/ui-library';

export default {
  name: 'ActionsCard',
  components: {
    ChecButton,
    ChecCard,
  },
  props: {
    saving: Boolean,
    allowSave: Boolean,
  },
  computed: {
    saveLabel() {
      return this.saving ? this.$t('general.saving') : this.$t('general.saveChanges');
    },
  },
};
</script>

<style lang="scss">
.actions-card {
  @apply flex flex-col;

  &__primary {
    @apply flex flex-row gap-2;
  }
}
</style>
