var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('DashboardTable',{staticClass:"logs-table",attrs:{"align-right-after":3,"columns":[
    { title: _vm.$t('developer.method'), class: 'logs-table__method-header' },
    { title: _vm.$t('developer.url') },
    { title: _vm.$t('general.status'), class: 'logs-table__status-header' },
    { title: _vm.$t('developer.dateTime') } ],"show-search":"","state-key":"developerLogs"}},_vm._l((_vm.logs),function(ref){
    var id = ref.id;
    var method = ref.method;
    var url = ref.url;
    var statusCode = ref.status_code;
    var created = ref.created;
return _c('LogRow',{key:id,class:{ 'logs-table-row--active': _vm.isActive(id) },attrs:{"method":method,"url":url,"status-code":statusCode,"time":created},on:{"click":function () { return _vm.handleSelectLog(id); }},nativeOn:{"keypress":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return (function () { return _vm.handleSelectLog(id); })($event)}}})}),1)}
var staticRenderFns = []

export { render, staticRenderFns }