var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('DashboardTable',{staticClass:"invoices-table",attrs:{"align-right-after":3,"columns":[
    _vm.$t('billing.invoiceDate'),
    _vm.$t('billing.invoiceDescription'),
    _vm.$t('billing.invoiceAmount'),
    _vm.$t('billing.invoiceStatus'),
    _vm.$t('general.details') ],"state-key":"billing/invoices"}},_vm._l((_vm.invoices),function(ref){
  var id = ref.id;
  var amount = ref.amount;
  var description = ref.description;
  var status = ref.status;
  var created = ref.created;
return _c('InvoiceTableRow',{key:id,attrs:{"id":id,"amount":amount,"description":description,"status":status,"created":created}})}),1)}
var staticRenderFns = []

export { render, staticRenderFns }