<template>
  <TableRow class="vat-moss-row" @click="showVatMoss">
    <td>
      {{ $t('settings.tax.euVatMoss') }}
    </td>
    <td>
      {{ $t('general.auto') }}
    </td>
    <td>
      {{ $t('general.auto') }}
    </td>
    <td>
      <ChecOptionsMenu class="vat-moss-row__actions-button">
        <ChecOption
          @option-selected="showVatMoss"
        >
          {{ $t('general.view') }}
        </ChecOption>
        <ChecOption @option-selected="emitDisable">
          {{ $t('general.disable') }}
        </ChecOption>
      </ChecOptionsMenu>
    </td>
  </TableRow>
</template>

<script>
import {
  ChecOption,
  ChecOptionsMenu,
} from '@chec/ui-library';
import TableRow from '@/components/DashboardTable/TableRow.vue';

export default {
  name: 'EuVatMossRow',
  components: {
    TableRow,
    ChecOption,
    ChecOptionsMenu,
  },
  methods: {
    emitDisable() {
      this.$emit('disable-vat-moss');
    },
    showVatMoss() {
      this.$emit('show-vat-moss');
    },
  },
};
</script>

<style lang="scss">
.vat-moss-row {
  @apply h-full;

  &__actions-button {
    @apply flex justify-end;
  }
}
</style>
