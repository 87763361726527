<template>
  <router-view />
</template>

<script>
export default {
  name: 'SettingsBillingPage',
  created() {
    this.$store.dispatch('billing/FETCH_BILLING');
    this.$store.dispatch('billing/FETCH_PLANS');
  },
};
</script>
