import {
  array,
  object,
  string,
} from 'yup';
import i18n from '@/lang';

export default object().shape({
  name: string().required(i18n.t('extraFields.nameRequired')),
  type: string().required(i18n.t('extraFields.typeRequired')),
  options: array().of(string()).when('type', {
    is: (value) => value === 'options' || value === 'radio', // When type is either 'options' or 'radio'
    then: array().min(1, i18n.t('product.variants.noOptions')),
  }),
});
