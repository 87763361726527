<template>
  <TableRow
    :id="extraField.id"
    class="extra-field-row"
    :route-name="extraField.id ? 'settings.extraFields.edit' : null"
  >
    <td>
      {{ extraField.name }}
    </td>
    <td>
      <div v-if="extraField.id" class="extra-field-row__extra-field-id-container">
        <CopyInline
          class="extra-field-row__inline"
          :copy-content="extraField.id || ''"
        >
          <div
            class="extra-field-row__extra-field-id"
          >
            {{ extraField.id }}
          </div>
        </CopyInline>
      </div>
    </td>
    <td>
      <ChecDataPill :color="extraField.required ? 'green' : 'red'">
        {{ extraField.required ? $t('general.true') : $t('general.false') }}
      </ChecDataPill>
    </td>
    <td>
      <code>
        {{ extraField.type }}
      </code>
    </td>
    <td>
      <ChecOptionsMenu menu-placement="bottom-end" class="dashboard-table__actions-button">
        <ChecOption
          @option-selected="() => editExtraField(extraField.id)"
        >
          {{ $t('general.edit') }}
        </Checoption>
        <ChecOption
          destructive
          @option-selected="emitDelete(extraField)"
        >
          {{ $t('general.delete') }}
        </ChecOption>
      </ChecOptionsMenu>
    </td>
  </TableRow>
</template>

<script>
import {
  ChecDataPill,
  ChecOption,
  ChecOptionsMenu,
} from '@chec/ui-library';
import TableRow from '@/components/DashboardTable/TableRow.vue';
import CopyInline from '@/components/CopyInline.vue';

export default {
  name: 'ExtraFieldRow',
  components: {
    TableRow,
    ChecDataPill,
    ChecOption,
    ChecOptionsMenu,
    CopyInline,
  },
  props: {
    extraField: Object,
  },
  methods: {
    emitDelete(extraField) {
      if (extraField?.id) {
        this.$emit('delete-extra-field', extraField);
      }
    },
    editExtraField(extraFieldId) {
      if (extraFieldId) {
        this.$emit('edit-extra-field', extraFieldId);
      }
    },
  },
};
</script>

<style lang="scss">
.extra-field-row {
  code {
    @apply py-1 px-2 rounded-sm text-xs font-mono tracking-wide bg-gray-200;
  }

  &__extra-field-id-container {
    @apply inline-block items-center border border-gray-300 p-2 bg-gray-100 rounded;
  }

  &__extra-field-id {
    @apply font-mono text-xs select-all break-all;
  }
}
</style>
