var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('form',{staticClass:"apikeys",on:{"submit":function($event){$event.preventDefault();}}},[_c('DashboardHeader',{attrs:{"title":_vm.$t('settings.developerSettings.apiKeys')}},[_c('ChecButton',{attrs:{"variant":"round","color":"primary","icon":"plus","disabled":_vm.saving},on:{"click":_vm.addApiKey}},[_vm._v(" "+_vm._s(_vm.$t('general.add'))+" ")])],1),_c('DashboardTable',{staticClass:"apikey-table",attrs:{"columns":[
      { title: _vm.$t('general.environment') },
      { title: _vm.$t('settings.developerSettings.type') },
      { title: _vm.$t('settings.developerSettings.label') },
      {
        title: _vm.$t('settings.developerSettings.apiKey'),
        class: 'apikey-table__row-header-api-key'
      },
      {
        title: _vm.$t('settings.developerSettings.apiVersion'),
        class: 'apikey-table__row-header-version'
      },
      { title: _vm.$t('general.actions') } ],"align-right-after":5,"state-key":"merchant/apiKeys"}},_vm._l((_vm.apiKeys),function(apikey){return _c('ApiKeyRow',{key:apikey.id,attrs:{"api-key":apikey},on:{"edit-api-key":_vm.editApiKey,"delete-api-key":_vm.handleDeleteKey}})}),1),_c('Pagination',{attrs:{"keep-page-on-mount":"","state-key":"merchant/apiKeys"}}),(_vm.mode === 'add' || _vm.mode === 'edit')?_c('EditKey',{attrs:{"api-key":_vm.activeApiKey,"mode":_vm.mode},on:{"save-key":_vm.handleSaveKey,"close":_vm.closeEditPanel}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }