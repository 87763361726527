<template>
  <ChecCard
    class="discounts-edit"
    inner-class="details-card"
    tailwind="p-4"
  >
    <ChecLoading v-if="isLoading" />
    <ChecHeader
      :title="$t('general.details')"
      variant="card"
      header-tag="h3"
    />
    <template>
      <div class="input-wrapper">
        <ChecFormField>
          <TextField
            :value="discount.code"
            :label="$t('discount.discountCode')"
            name="code"
            :variant="errors && errors['code'] ? 'error' : ''"
            @input="value => change('code', value)"
          >
            <ChecButton
              color="primary"
              variant="round"
              @click="handleGenerateCode('code')"
            >
              {{ generateCopy }}
            </ChecButton>
          </TextField>
        </ChecFormField>
        <span v-if="errors['code']" class="input-wrapper__error">
          {{ errors['code'] }}
        </span>
      </div>

      <div class="input-wrapper__row space-x-2">
        <div class="input-wrapper">
          <TextField
            :value="discount.value"
            :label="$t('general.amount')"
            name="value"
            :variant="errors && errors['value'] ? 'error' : ''"
            @input="value => change('value', value)"
          >
            <ChecSegmentedButtonsGroup
              v-if="discount.type"
              :value="discount.type"
              :options="[
                { label: merchantCurrency, value: 'fixed' },
                { label: '%', value: 'percentage' },
              ]"
              @input="value => change('type', value)"
            />
          </TextField>
          <span v-if="errors['value']" class="input-wrapper__error">
            {{ errors['value'] }}
          </span>
        </div>
        <div class="input-wrapper">
          <TextField
            :value="discount.quantity"
            type="number"
            :label="$t('general.quantity')"
            name="quantity"
            @input="value => change('quantity', value)"
          />
          <span v-if="errors['quantity']" class="input-wrapper__error">
            {{ errors['quantity'] }}
          </span>
        </div>
      </div>

      <div class="input-wrapper__row space-x-2">
        <div class="input-wrapper">
          <ChecDatepicker
            :value="discount.starts_on"
            :label="$t('general.startDate')"
            name="starts_on"
            @input="value => change('starts_on', value)"
          />
          <span v-if="errors['starts_on']" class="input-wrapper__error">
            {{ errors['starts_on'] }}
          </span>
        </div>
        <div class="input-wrapper">
          <ChecDatepicker
            :value="discount.expires_on"
            :label="$t('general.expires')"
            name="expires_on"
            @input="value => change('expires_on', value)"
          />
          <span v-if="errors['expires_on']" class="input-wrapper__error">
            {{ errors['expires_on'] }}
          </span>
        </div>
      </div>

      <div class="input-wrapper">
        <ChecFormField
          :tooltip="$t('discount.productTooltip')"
          :append-label="$t('general.whatIsThis')"
        >
          <ResourceDropdown
            :value="discount.product_ids"
            :label="$t('discount.productsLabel')"
            name="product_ids"
            state-key="products"
            multiselect
            @input="value => change('product_ids', value)"
          />
        </ChecFormField>
        <span v-if="errors['product_ids']" class="input-wrapper__error">
          {{ errors['product_ids'] }}
        </span>
      </div>
    </template>
  </ChecCard>
</template>

<script>
import {
  ChecButton,
  ChecHeader,
  ChecLoading,
  ChecDatepicker,
  ChecFormField,
  ChecCard,
  ChecSegmentedButtonsGroup,
  TextField,
} from '@chec/ui-library';
import generateDiscountCode from '@/lib/helpers/generateDiscountCode';
import ResourceDropdown from '@/components/ResourceDropdown.vue';

export default {
  name: 'DetailsCard',
  components: {
    ChecButton,
    ChecHeader,
    ChecLoading,
    ResourceDropdown,
    ChecDatepicker,
    ChecFormField,
    ChecCard,
    ChecSegmentedButtonsGroup,
    TextField,
  },
  model: {
    prop: 'discount',
    event: 'input',
  },
  props: {
    discount: {
      type: Object,
      required: true,
    },
    errors: {
      type: Object,
      default: () => ({}),
    },
    merchantCurrency: {
      type: String,
      default: '$',
    },
    isLoading: Boolean,
  },
  computed: {
    generateCopy() {
      return this.discount.code !== '' ? this.$t('general.regenerate')
        : this.$t('general.generate');
    },
  },
  methods: {
    change(prop, value) {
      this.$emit('input', {
        ...this.discount,
        [prop]: value,
      });
    },
    /**
     * Generate a new discount code
     */
    handleGenerateCode(prop) {
      this.$emit('input', {
        ...this.discount,
        [prop]: generateDiscountCode(),
      });
    },
  },
};
</script>
