<template>
  <form class="apikeys" @submit.prevent>
    <DashboardHeader :title="$t('settings.developerSettings.apiKeys')">
      <ChecButton
        variant="round"
        color="primary"
        icon="plus"
        :disabled="saving"
        @click="addApiKey"
      >
        {{ $t('general.add') }}
      </ChecButton>
    </DashboardHeader>
    <DashboardTable
      class="apikey-table"
      :columns="[
        { title: $t('general.environment') },
        { title: $t('settings.developerSettings.type') },
        { title: $t('settings.developerSettings.label') },
        {
          title: $t('settings.developerSettings.apiKey'),
          class: 'apikey-table__row-header-api-key'
        },
        {
          title: $t('settings.developerSettings.apiVersion'),
          class: 'apikey-table__row-header-version'
        },
        { title: $t('general.actions') },
      ]"
      :align-right-after="5"
      state-key="merchant/apiKeys"
    >
      <ApiKeyRow
        v-for="apikey in apiKeys"
        :key="apikey.id"
        :api-key="apikey"
        @edit-api-key="editApiKey"
        @delete-api-key="handleDeleteKey"
      />
    </DashboardTable>
    <Pagination
      keep-page-on-mount
      state-key="merchant/apiKeys"
    />
    <EditKey
      v-if="mode === 'add' || mode === 'edit'"
      :api-key="activeApiKey"
      :mode="mode"
      @save-key="handleSaveKey"
      @close="closeEditPanel"
    />
  </form>
</template>

<script>
import { ChecButton } from '@chec/ui-library';
import DashboardHeader from '@/components/DashboardHeader.vue';
import Pagination from '@/components/Pagination.vue';
import crud from '@/mixins/crud';
import confirm from '@/mixins/confirm';
import addNotification from '@/mixins/addNotification';
import DashboardTable from '@/components/DashboardTable.vue';
import ApiKeyRow from './ApiKeyRow.vue';
import EditKey from './EditKey.vue';

export default {
  name: 'ApiKeyTable',
  components: {
    DashboardTable,
    ApiKeyRow,
    ChecButton,
    DashboardHeader,
    EditKey,
    Pagination,
  },
  mixins: [
    crud('merchant/apiKeys', true),
    addNotification,
    confirm,
  ],
  data() {
    return {
      mode: '',
      activeApiKey: {},
      saving: false,
      loading: false,
    };
  },
  methods: {
    addApiKey() {
      this.mode = 'add';
    },
    /**
     * Open the editor panel and load selected key.
     */
    editApiKey(apiKeyData) {
      this.mode = 'edit';
      this.activeApiKey = apiKeyData;
    },
    closeEditPanel() {
      this.mode = '';
      this.activeApiKey = {};
    },
    async handleSaveKey(keyData) {
      this.saving = true;
      if (this.saving) {
        this.loading = true;
      }

      if (this.mode === 'add') {
        try {
          this.mode = '';
          await this.create({
            ...keyData,
            is_sandbox: keyData.is_sandbox === 'sandbox',
            version: keyData.api_version,
          }, true);
          this.addNotification(this.$t('settings.developerSettings.createSuccess'));
          this.$emit('close');
        } catch (error) {
          this.addNotification(this.$t('settings.developerSettings.updateFailure'), 'error');
        }
        this.saving = false;
        this.loading = false;
        return;
      }

      try {
        this.mode = '';
        await this.update(keyData.id, {
          ...keyData,
          version: keyData.api_version,
          label: keyData.label || undefined,
        }, false, false);

        this.addNotification(this.$t('settings.developerSettings.updateSuccess'));
      } catch (error) {
        this.addNotification(this.$t('settings.developerSettings.updateFailure'), 'error');
      }
      this.loading = false;
      this.saving = false;
    },
    /**
     * Confirm and delete api key.
     */
    async handleDeleteKey(keyData) {
      if (!await this.confirm(
        this.$t('general.areYouSure'),
        this.$t('settings.developerSettings.confirmDeleteKey'),
      )) {
        return;
      }

      this.delete(keyData.id)
        .then(() => {
          this.addNotification(this.$t('settings.developerSettings.deleted'));
        })
        .catch((error) => {
          this.addNotification(this.$t('settings.developerSettings.deleteFailed'), 'error');
          throw error;
        });
    },
  },
};
</script>

<style lang="scss">
.apikeys {
  @apply relative;

  &__invalidating-variants-warning {
    @apply mt-4;
  }

  &__requires-save {
    @apply mt-4;
  }
}

.apikey-table {
  table {
    @apply w-full h-px;
  }

  tbody {
    @apply h-full;
  }

  &__row-header-api-key {
    @apply w-12;
  }

  &__row-header-version {
    @apply w-32;
  }

  &__options {
    @apply px-2;
  }

  &__loading-cell {
    @apply relative p-16;
  }

  &__actions-button {
    @apply flex justify-end;
  }
}

.apikey-action-buttons {
  @apply flex;
}
</style>
