<template>
  <form
    class="account-details"
    @submit.prevent
  >
    <DashboardHeader class="account-details__title" :title="$t('account.account')" />
    <ChecLoading v-if="isLoading" without-background />
    <template v-else>
      <div class="account-details__content space-y-4 md:space-y-0">
        <div class="account-details__main space-y-4">
          <DetailsCard
            v-model="details"
            :details="details"
          />
          <PasswordCard
            v-model="password"
            :password="password"
          />
          <LanguageCard
            v-model="language"
            :language="language"
          />
        </div>
        <div class="account-details__side-bar space-y-4">
          <ActionsCard
            :saving="isSaving"
            :allow-save="allowSave"
            @save-account="handleSaveAccount"
          />
          <DeleteAccountCard
            @delete-account="handleDeleteAccount"
          />
        </div>
      </div>
    </template>
  </form>
</template>

<script>
import {
  ChecLoading,
} from '@chec/ui-library';
import {
  mapActions, mapGetters, mapMutations, mapState,
} from 'vuex';
import { makeApiRequest } from '@/lib/api';
import authState from '@/lib/auth/state';
import refreshDocumentTitle from '@/lib/refreshDocumentTitle';
import actions from '@/store/actions';
import mutations from '@/store/mutations';
import addNotification from '@/mixins/addNotification';
import confirm from '@/mixins/confirm';
import DashboardHeader from '@/components/DashboardHeader.vue';
import ActionsCard from '../components/ActionsCard.vue';
import DeleteAccountCard from '../components/DeleteAccountCard.vue';
import DetailsCard from '../components/DetailsCard.vue';
import LanguageCard from '../components/LanguageCard.vue';
import PasswordCard from '../components/PasswordCard.vue';

export default {
  name: 'Account',
  components: {
    ActionsCard,
    ChecLoading,
    DashboardHeader,
    DeleteAccountCard,
    DetailsCard,
    PasswordCard,
    LanguageCard,
  },
  mixins: [addNotification, confirm],
  data() {
    return {
      details: {
        name: '',
        email: '',
      },
      password: {
        currentPassword: '',
        newPassword: '',
        passwordStrengthScore: null,
      },
      language: 'en', // Default
      isSaving: false,
      capturedState: {},
    };
  },
  computed: {
    ...mapGetters(['userData', 'isLoading']),
    ...mapState('merchant', ['merchant']),
    allowSave() {
      if (this.hasDetailsChanged
        || this.hasPasswordChanged
        || this.hasLanguageChanged) {
        return true;
      }
      return false;
    },
    /**
     * have the account details changed?
     *
     * @returns {boolean}
     */
    hasDetailsChanged() {
      return this.details.name !== this.userData.name
        || this.details.email !== this.userData.email;
    },
    /**
     * Has the dashboard language changed?
     *
     * @returns {boolean}
     */
    hasLanguageChanged() {
      return this.language !== this.capturedState.language;
    },
    /**
     * Has the dashboard language changed?
     *
     * @returns {boolean}
     */
    hasPasswordChanged() {
      return this.password.currentPassword !== this.capturedState.password.currentPassword
        && this.password.newPassword !== this.capturedState.password.newPassword;
    },
  },
  watch: {
    userData(val) {
      this.updateUserState(val);
    },
  },
  mounted() {
    this.updateUserState(this.userData);
    // Fetch selected language from state
    this.language = this.getLanguage();
    this.capturedState = {
      password: this.password,
      language: this.language,
    };
  },
  methods: {
    ...mapActions({ setUserData: actions.UPDATE_USER }),
    ...mapGetters('account', ['getLanguage']),
    ...mapMutations({ setLoading: mutations.SET_LOADING }),
    ...mapMutations('account', { setLanguage: mutations.SET_LANGUAGE }),
    updateUserState(userData) {
      if (!userData) {
        // Default
        this.details.name = '';
        this.details.email = '';
        return;
      }

      this.details.name = userData.name;
      this.details.email = userData.email;
    },
    async handleSaveAccount() {
      if (this.hasDetailsChanged) {
        this.handleUpdateAccount();
      }

      if (this.hasPasswordChanged) {
        this.handleUpdatePassword();
      }

      if (this.hasLanguageChanged) {
        this.handleUpdateLanguage();
      }
    },
    /**
     * Update the user's name and email address
     */
    async handleUpdateAccount() {
      this.setLoading(true);
      await this.setUserData({
        name: this.details.name,
        email: this.details.email,
      })
        .then(() => {
          this.addNotification(this.$t('account.updatedDetailsSuccessfully'));
        })
        .catch((error) => {
          if (error.response.data.errors.email) {
            this.addNotification(error.response.data.errors.email[0], 'error');
            return;
          }
          this.addNotification(this.$t('account.updateFailed'), 'error');
        })
        .finally(() => {
          this.setLoading(false);
        });
    },
    /**
     * Updates the user's dashboard language
     * @todo in future make this part of a User API
     */
    async handleUpdateLanguage() {
      await this.setLanguage(this.language);
      refreshDocumentTitle(this.$route);
      this.capturedState.language = this.language;
      this.addNotification(this.$t('account.updatedLanguageSuccessfully'));
    },
    /**
     * Updates the user's password
     */
    handleUpdatePassword() {
      if (this.password.currentPassword === null || this.password.newPassword === null) {
        this.addNotification(this.$t('account.missingPasswords'), 'info');
        return;
      }

      this.setLoading(true);
      this.setUserData({
        current_password: this.password.currentPassword,
        new_password: this.password.newPassword,
      })
        .then(() => {
          this.addNotification(this.$t('account.passwordUpdated'));
          this.password.currentPassword = null;
          this.password.newPassword = null;
          this.password.passwordStrengthScore = null;
        })
        .catch((error) => {
          if (error.response.data.errors.current_password) {
            this.addNotification(error.response.data.errors.current_password[0], 'error');
            return;
          }
          if (error.response.data.errors.new_password) {
            this.addNotification(error.response.data.errors.new_password[0], 'error');
            return;
          }
          this.addNotification(this.$t('account.passwordUpdateFailed'), 'error');
        })
        .finally(() => {
          this.setLoading(false);
        });
    },
    /**
     * Delete the merchant and the user. The user is logged out afterwards.
     */
    async handleDeleteAccount() {
      if (await this.confirm(
        this.$t('account.deleteAccount'),
        `${this.$t('account.deleteMessage')} ${this.$t('general.areYouSure')}`,
        {
          confirmLabel: this.$t('account.deleteMyAccount'),
          callout: this.$t('account.deleteCallout'),
          // todo make the confirm button red when the cancel button is inverted
        },
      )) {
        this.setLoading(true);
        makeApiRequest(
          'DELETE',
          `/v1/merchants/${this.merchant.id}`,
          { delete_user: true },
        )
          .then(() => {
            authState.logout();
          })
          .catch(() => {
            this.setLoading(false);
            this.addNotification(this.$t('account.deleteFailed'), 'error');
          });
      }
    },
  },
};
</script>

<style lang="scss">
.account-details {
  @apply text-gray-500;

  &__side-bar {
    @screen lg {
      @apply ml-8 w-1/3 max-w-sm;
    }
  }

  @screen lg {
    &__content {
      @apply flex flex-row;
    }

    &__main {
      @apply flex-grow;
    }
  }

  .card {
    @apply mb-8;
  }
}
</style>
