<template>
  <TableRow
    non-editable
    class="invoices-table-row"
  >
    <td>
      <Timestamp :timestamp="created" />
    </td>
    <td>
      <span>
        {{ description }}
      </span>
    </td>
    <td>
      <span>
        <strong>{{ amount.formatted_with_symbol }}</strong>
      </span>
    </td>
    <td>
      <div
        class="invoices-table-row__status-cell"
        :class="{ 'invoices-table-row__status-cell--paid': isPaid }"
      >
        {{ paymentStatus }}
      </div>
    </td>
    <td>
      <div v-if="downloadingPdf" class="invoices-table-row__download-loading-icon">
        <ChecLoading without-background />
      </div>
      <ChecButton
        v-else
        class="invoices-table-row__download-button"
        icon="download"
        color="gray"
        variant="small"
        text-only
        @click="getPdfUrl"
      >
        {{ $t('billing.invoiceDownload') }}
      </ChecButton>
    </td>
  </TableRow>
</template>

<script>
import { makeApiRequest } from '@/lib/api';
import { ChecButton, ChecLoading } from '@chec/ui-library';
import addNotification from '@/mixins/addNotification';
import Timestamp from '@/components/Timestamp.vue';
import TableRow from '@/components/DashboardTable/TableRow.vue';

export default {
  name: 'InvoiceTableRow',
  components: {
    TableRow,
    Timestamp,
    ChecButton,
    ChecLoading,
  },
  mixins: [addNotification],
  props: {
    id: String,
    amount: {
      type: Object,
      default: () => ({}),
    },
    description: String,
    status: String,
    created: Number,
  },
  data() {
    return {
      downloadingPdf: false,
      error: null,
    };
  },
  computed: {
    isPaid() {
      return this.status === 'paid';
    },
    paymentStatus() {
      if (this.status === 'paid') {
        return this.$t('billing.paid');
      }
      if (this.status === 'voided') {
        return this.$t('billing.voided');
      }
      return this.$t('billing.unpaid');
    },
  },
  watch: {
    error(val) {
      if (val !== null) {
        this.addNotification(val, 'error');
      }
    },
  },
  methods: {
    async getPdfUrl() {
      this.error = null;
      this.downloadingPdf = true;
      makeApiRequest('get', `/v1/billing/invoices/${this.id}/pdf`)
        .then(({ data: pdfUrl }) => {
          window.open(pdfUrl, '_blank');
        })
        .catch((error) => {
          this.error = error;
        })
        .finally(() => {
          this.downloadingPdf = false;
        });
    },
  },
};
</script>

<style lang="scss">
.invoices-table-row {
  &__status-cell {
    @apply flex flex-row items-center float-right;

    &--paid {
      @apply text-green-600;
    }
  }

  &__icon-wrapper {
    @apply ml-4 w-4 h-4 text-gray-500 cursor-pointer;
  }

  &__download-button {
    @apply float-right;
  }

  &__download-loading-icon {
    @apply relative float-right w-5 h-5;
  }

}
</style>
