<template>
  <div class="billing-plan-details">
    <ChecCard compact tailwind="p-4">
      <ChecHeader
        class="billing-plan-details__title"
        variant="card"
        :title="$t('billing.planDetails')"
      />
      <PlanThumbnail
        class="billing-plan-details__thumbnail"
        :icon="plan.plan_name"
        :name="$t('billing.planName', { name: plan.plan_name })"
      />
      <div class="billing-plan-details-price">
        <template v-if="plan.transaction_fee">
          <div>
            <div class="billing-plan-details-price__value">
              {{ plan.transaction_fee.raw * 100 }}%
            </div>
            <div class="billing-plan-details-price__period">
              {{ $t('billing.perTransaction') }}
            </div>
          </div>
          +
        </template>
        <div>
          <div
            v-if="plan.plan_name === 'Standard'"
            class="billing-plan-details-price__value"
          >
            $10.00
          </div>
          <div v-else class="billing-plan-details-price__value">
            ${{ plan.monthly_fee.raw }}
          </div>
          <div
            v-if="plan.plan_name === 'Standard'"
            class="billing-plan-details-price__period"
          >
            {{ $t('billing.minimum') }}
          </div>
          <div class="billing-plan-details-price__period">
            {{ $t('billing.perMonth') }}
          </div>
        </div>
      </div>
      <InnerBlock
        v-if="terms(plan).length"
        :title="$t('general.details')"
        class="billing-plan-details-price-details"
      >
        <ul class="billing-plan-details-price-details__terms">
          <li v-for="term in terms(plan)" :key="term">
            {{ term }}
          </li>
        </ul>
      </InnerBlock>
    </ChecCard>
    <PaymentMethodSection
      v-if="plan.plan_name !== 'Developer'"
      class="billing-plan-details__payment-method"
    />
    <i18n
      path="billing.planSummary"
      tag="div"
      class="billing-plan-details__confirm-statement"
    >
      <template #perMonth>
        <span
          v-if="plan.plan_name === 'Standard'"
          class="billing-plan-details__key-information"
        >
          a minimum of $10.00
        </span>
        <span v-else class="billing-plan-details__key-information">
          {{ money(plan.monthly_fee).full_with_currency() }}
        </span>
      </template>
      <template #perTransaction>
        <span class="billing-plan-details__key-information">
          {{ plan.transaction_fee.raw * 100 }}%
        </span>
      </template>
    </i18n>
    <div class="billing-plan-details__actions">
      <ChecButton
        color="primary"
        class="billing-plan-details__cancel"
        text-only
        @click="handleCancel"
      >
        {{ $t('general.cancel') }}
      </ChecButton>
      <ChecButton
        color="primary"
        :disabled="allowDisableChangePlan"
        :title="changePlanTitle"
        @click="choosePlan"
      >
        {{ $t('billing.choosePlan') }}
      </ChecButton>
    </div>
  </div>
</template>

<script>
import {
  ChecButton,
  ChecCard,
  ChecHeader,
  InnerBlock,
} from '@chec/ui-library';
import { mapActions } from 'vuex';
import confirm from '@/mixins/confirm';
import actions from '@/store/actions';
import money from '@/lib/helpers/money';
import PlanThumbnail from './PlanThumbnail.vue';
import PaymentMethodSection from './PaymentMethodSection.vue';

export default {
  name: 'PlanDetails',
  components: {
    ChecButton,
    ChecCard,
    ChecHeader,
    InnerBlock,
    PlanThumbnail,
    PaymentMethodSection,
  },
  mixins: [confirm],
  props: {
    disableChangePlan: Boolean,
    plan: {
      type: Object,
      required: true,
    },
  },
  computed: {
    /**
     * Check if plan can be changed without a payment method.
     *
     * @returns {string|null}
     */
    allowDisableChangePlan() {
      if (this.plan.plan_name === 'Developer') {
        return false;
      }
      return this.disableChangePlan;
    },
    /**
     * A tooltip for the disabled "Choose plan" button if it's disabled
     *
     * @returns {string|null}
     */
    changePlanTitle() {
      if (this.plan.plan_name === 'Developer') {
        return null;
      }
      if (this.disableChangePlan) {
        return this.$t('billing.paymentMethodRequired');
      }
      return null;
    },
  },
  methods: {
    money,
    handleCancel() {
      this.$emit('close');
    },
    async choosePlan() {
      if (!await this.confirm(
        this.$t('general.areYouSure'),
        this.$t('billing.confirmChangePlan'),
      )) {
        return;
      }

      this.updatePlan(this.plan.slug);
      this.$emit('close');
    },
    /**
     * Compiles a list of "terms" for displaying in the plan. These are less important factors
     * than monthly fee and transaction fee. We format them in the dashboard because the API is
     * not aware of localisation.
     *
     * @param {Object} plan
     * @returns {Array}
     */
    terms(plan) {
      const terms = [];
      if (plan.monthly_ceiling && plan.monthly_ceiling.raw !== 0) {
        terms.push(
          this.$t('billing.monthlyCeiling', {
            ceiling: money(plan.monthly_ceiling).full(),
          }),
        );
      }
      if (plan.overage_fee && plan.overage_fee.raw !== 0) {
        terms.push(
          this.$t('billing.overageFee', { fee: plan.overage_fee.print }),
        );
      }
      return terms;
    },
    ...mapActions('billing', { updatePlan: actions.SET_PLAN }),
  },
};
</script>

<style lang="scss">
.billing-plan-details {
  @apply text-lg;

  &__thumbnail {
    @apply my-4;
  }

  &__payment-method,
  &__confirm-statement {
    @apply mt-2 border rounded border-gray-300;
  }

  &__confirm-statement {
    @apply p-4 text-sm text-center px-16;
  }

  &__key-information {
    @apply text-green-600;
  }

  &__actions {
    @apply flex items-center justify-between mt-8;
  }

  // .button is added for increased specificity
  &__cancel.button {
    @apply p-0;
  }
}

.billing-plan-details-price {
  @apply bg-gray-100 shadow-inner rounded py-4 px-8 mb-4 flex items-center justify-around;
  @apply text-center text-gray-600 text-xl font-bold;

  &__period {
    @apply caps-xxs text-gray-500;
  }
}

.billing-plan-details-price-details {
  @apply bg-gray-100 rounded p-4 text-sm leading-tight;

  &__title {
    @apply mb-2 caps-xs text-gray-500;
  }

  &__terms {
    > * + * {
      @apply mt-1;
    }
  }
}
</style>
