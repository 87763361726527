<template>
  <ChecCard
    class="tax-address__card"
    inner-class="details-card"
  >
    <ChecLoading v-if="loading" />
    <ChecHeader
      variant="card"
      :title="$t('settings.tax.address')"
    />
    <ChecAlert
      class="tax-address__alert"
      variant="info"
      inline
      disable-close
      show-icon
    >
      {{ $t('settings.tax.addressAlert') }}
    </ChecAlert>
    <div class="input-wrapper">
      <TextField
        :value="tax.address.street"
        name="street"
        :label="$t('general.address.street')"
        :variant="errors && errors['address.street'] ? 'error' : ''"
        @input="value => change('street', value)"
      />
      <span
        v-if="errors && errors['address.street']"
        class="input-wrapper__error"
      >
        {{ errors['address.street'] }}
      </span>
    </div>
    <div class="input-wrapper__row space-x-2">
      <div class="tax-address__input-wrapper w-7/12">
        <TextField
          :value="tax.address.town_city"
          name="town_city"
          :label="$t('general.address.city')"
          :variant="errors && errors['address.city'] ? 'error' : ''"
          @input="value => change('town_city', value)"
        />
        <span
          v-if="errors && errors['address.city']"
          class="input-wrapper__error"
        >
          {{ errors['address.city'] }}
        </span>
      </div>
      <div class="tax-address__input-wrapper w-5/12">
        <ChecDropdown
          v-if="Object.values(subdivisionOptions).length"
          v-model="selectedCountyState"
          :options="subdivisionOptions"
          name="county_state"
          :label="$t('general.address.provinceState')"
          :variant="errors && errors['address.county_state'] ? 'error' : ''"
          :search-value="searchRegion"
          show-search
          required
          @search="(value) => searchRegion = value"
        />
        <!-- Show disabeld input if no country selected. -->
        <TextField
          v-else
          variant="disabled"
          name="county_state"
          :label="$t('general.address.provinceState')"
        />
        <span
          v-if="errors && errors['address.county_state']"
          class="input-wrapper__error"
        >
          {{ errors['address.county_state'] }}
        </span>
      </div>
    </div>
    <div class="input-wrapper__row space-x-2">
      <div class="tax-address__input-wrapper w-4/12">
        <TextField
          :value="tax.address.postal_zip_code"
          name="postal_zip_code"
          :label="$t('general.address.postalZip')"
          :variant="errors && errors['address.postal_zip_code'] ? 'error' : ''"
          @input="value => change('postal_zip_code', value)"
        />
        <span
          v-if="errors && errors['address.postal_zip_code']"
          class="input-wrapper__error"
        >
          {{ errors['address.postal_zip_code'] }}
        </span>
      </div>
      <div class="tax-address__input-wrapper w-8/12">
        <ChecDropdown
          v-if="Object.values(countriesOptions).length"
          v-model="selectedCountry"
          :options="countriesOptions"
          name="country"
          :label="$t('general.address.country')"
          :variant="errors && errors['address.country'] ? 'error' : ''"
          :search-value="searchCountry"
          show-search
          required
          @search="(value) => searchCountry = value"
        />
        <span
          v-if="errors && errors['address.country']"
          class="input-wrapper__error"
        >
          {{ errors['address.country'] }}
        </span>
      </div>
    </div>
  </ChecCard>
</template>

<script>
import {
  ChecCard,
  ChecDropdown,
  ChecHeader,
  ChecLoading,
  ChecAlert,
  TextField,
} from '@chec/ui-library';
import actions from '@/store/actions';

export default {
  name: 'TaxAddress',
  components: {
    ChecCard,
    ChecDropdown,
    ChecHeader,
    ChecLoading,
    ChecAlert,
    TextField,
  },
  model: {
    prop: 'tax',
    event: 'input',
  },
  props: {
    tax: {
      type: Object,
      required: true,
    },
    countries: {
      type: Object,
      required: true,
    },
    subdivisions: {
      type: Object,
      required: true,
    },
    errors: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      searchCountry: '',
      searchRegion: '',
      loading: false,
    };
  },
  computed: {
    /**
     * Gets a list of available countries, formatted as options for a dropdown
     *
     * @returns {object[]}
     */
    countriesOptions() {
      // Filter countries based on a search (if there is one)
      const filteredCountries = this.searchCountry.length > 0
        ? Object.entries(this.countries)
          // Make country label lower case to prevent case sensitivity in search input
          .filter((country) => country[1].toLowerCase().includes(this.searchCountry.toLowerCase()))
        : Object.entries(this.countries);

      return filteredCountries.map(([value, label]) => ({ value, label }));
    },
    selectedCountry: {
      get() {
        return this.tax.address.country || '';
      },
      set(country) {
        // If the country isn't actually changing, do nothing
        const oldCountry = this.tax.address.country;
        if (oldCountry === country) {
          return;
        }
        // Reset region search fields
        this.searchRegion = '';

        // Trigger a change in the editable object, changing both the country and the region
        this.$emit('input', {
          ...this.tax,
          address: {
            ...this.tax.address,
            country,
            county_state: '',
          },
        });

        // Refresh the regions for the new country
        this.getRegionsForCountry(country);
      },
    },
    selectedCountyState: {
      get() {
        const { county_state: region } = this.tax.address;

        if (!region || !this.selectedCountry) {
          return '';
        }

        // Ensure we know the valid subdivisions for the selected country
        if (!Object.hasOwnProperty.call(this.subdivisions, this.selectedCountry)) {
          return '';
        }

        // Ensure the currently selected region exists for the current country
        if (!Object.keys(this.subdivisions[this.selectedCountry]).includes(region)) {
          return '';
        }

        return region;
      },
      set(value) {
        this.change('county_state', value);
      },
    },
    /**
     * Gets a list of available states/regions/subdivisions for the currently selected country
     *
     * @returns {object[]}
     */
    subdivisionOptions() {
      const countryCode = this.selectedCountry;

      if (!this.subdivisions[countryCode]) {
        return [];
      }

      // Filter countries based on a search (if there is one)
      const filteredRegions = this.searchRegion.length > 0
        ? Object.entries(this.subdivisions[countryCode])
          // Make country label lower case to prevent case sensitivity in search input
          .filter((region) => region[1].toLowerCase().includes(this.searchRegion.toLowerCase()))
        : Object.entries(this.subdivisions[countryCode]);

      return filteredRegions.map(([key, value]) => ({ value: key, label: value }));
    },
  },
  mounted() {
    if (this.selectedCountry) {
      // Refresh the regions for the existing country
      this.getRegionsForCountry(this.selectedCountry);
    }
  },
  methods: {
    /**
     * Get a list of available regions from the API for the given country code
     *
     * @param {string} countryCode
     * @returns {Promise|null}
     */
    getRegionsForCountry(countryCode) {
      if (countryCode === '') {
        // Do nothing
        return null;
      }

      if (this.subdivisions[countryCode]) {
        // Already exists in state
        return null;
      }

      this.loading = true;
      return this.$store.dispatch(actions.FETCH_SUBDIVISIONS, countryCode)
        .finally(() => {
          this.loading = false;
        });
    },
    change(prop, value) {
      this.$emit('input', {
        ...this.tax,
        address: {
          ...this.tax.address,
          [prop]: value,
        },
      });
    },
  },
};
</script>

<style lang="scss">
.tax-address {
  &__input-wrapper {
    @apply mb-2;
  }

  &__alert {
    @apply mb-2;
  }
}
</style>
