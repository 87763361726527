<template>
  <form v-if="!isLoading" class="tax-settings" @submit.prevent>
    <ChecHeader
      :title="$t('settings.tax.title')"
      class="tax-settings__header"
    />
    <ChecLoading
      v-if="!tax"
      without-background
    />
    <Intro
      v-else-if="showIntro"
      @enable-tax="handleEnableTax"
    />
    <div
      v-else
      class="tax-settings__content space-y-4 md:space-y-0"
    >
      <div class="tax-settings__main space-y-4">
        <TaxAddress
          v-model="taxEditable"
          :countries="countries"
          :subdivisions="subdivisions"
          :errors="validationErrors"
        />
        <Zones
          :countries="countries"
          :eu-vat-moss="taxEditable.eu_vat_moss"
          @disable-vat-moss="taxEditable.eu_vat_moss = !taxEditable.eu_vat_moss"
        />
      </div>
      <div class="tax-settings__side-bar space-y-4">
        <ActionsCard
          :enabled="taxEditable.enabled"
          :saving="isSaving"
          class="tax-settings__actions-card"
          @toggle-enabled="(enabled) => { taxEditable.enabled = enabled; }"
          @save-tax="handleSaveTax"
        />
        <PricesIncludeTax
          v-model="taxEditable"
        />
        <EnableVat
          v-model="taxEditable"
        />
      </div>
      <ActionsCard
        :enabled="taxEditable.enabled"
        :saving="isSaving"
        class="tax-settings__actions-card--mobile"
        @toggle-enabled="(enabled) => { taxEditable.enabled = enabled; }"
        @save-tax="handleSaveTax"
      />
    </div>

    <router-view />
  </form>
</template>

<script>
import { ChecHeader, ChecLoading } from '@chec/ui-library';
import {
  mapState,
  mapGetters,
  mapMutations,
  mapActions,
} from 'vuex';
import mutations from '@/store/mutations';
import actions from '@/store/actions';
import addNotification from '@/mixins/addNotification';
import validateSchemaRequest from '@/lib/helpers/validateSchemaRequestHelper';
import taxSchema from '../schemas/tax';
import ActionsCard from '../components/tax/ActionsCard.vue';
import TaxAddress from '../components/tax/Address.vue';
import EnableVat from '../components/tax/EnableVat.vue';
import Intro from '../components/tax/Intro.vue';
import PricesIncludeTax from '../components/tax/PricesIncludeTax.vue';
import Zones from '../components/tax/Zones.vue';

export default {
  name: 'Tax',
  components: {
    ActionsCard,
    ChecHeader,
    ChecLoading,
    EnableVat,
    Intro,
    PricesIncludeTax,
    TaxAddress,
    Zones,
  },
  mixins: [
    addNotification,
  ],
  data() {
    return {
      taxEditable: {
        address: {
          country: null,
          county_state: null,
          postal_zip_code: null,
          street_address: null,
          town_city: null,
        },
        enabled: false,
        eu_vat_moss: false,
        prices_include_tax: false,
      },
      isSaving: false,
      validationErrors: {},
    };
  },
  computed: {
    ...mapState('settings/tax', ['tax']),
    ...mapGetters(['isLoading', 'countries', 'subdivisions']),
    /**
     * Check to make sure that no fields are filled so we don't
     * show the intro when setting tax to disabled.
     *
     * @returns {boolean}
     */
    showIntro() {
      const isAddressEmpty = Object.values(this.taxEditable.address)
        .every((value) => value === null || value === '');

      return isAddressEmpty
        && !this.taxEditable.eu_vat_moss
        && !this.taxEditable.prices_include_tax
        && !this.taxEditable.enabled;
    },
  },
  watch: {
    tax() {
      this.taxEditable = this.tax;
    },
  },
  mounted() {
    if (this.tax === null) {
      this.loadTax();
    }
  },
  created() {
    if (this.tax !== null) {
      this.taxEditable = this.tax;
    }
  },
  methods: {
    ...mapMutations('settings/tax', { setTax: mutations.SET_TAX }),
    ...mapActions('settings/tax', {
      loadTax: actions.FETCH_TAX,
      saveTax: actions.UPDATE_TAX,
    }),
    /**
     * Validate then save the tax configuration.
     */
    handleSaveTax() {
      this.validationErrors = {};
      this.isSaving = true;
      validateSchemaRequest(
        taxSchema,
        this.taxEditable,
        { abortEarly: false },
      )
        .then(
          (validatedData) => (this.saveTax(validatedData)),
        )
        .then(() => {
          this.addNotification(this.$t('general.saved'));
        })
        .catch((error) => {
          if (error.name === 'ValidationError') { // yup schema validation error
            this.validationErrors = error.errors;
            return;
          }
          this.addNotification(
            this.$t('settings.tax.saveFailed'),
            'error',
          );
        })
        .finally(() => { this.isSaving = false; });
    },
    handleEnableTax() {
      this.taxEditable.enabled = true;
    },
  },
};
</script>

<style lang="scss">
.tax-settings {
  @apply text-gray-500 relative;

  // Work around weirdness with the loading indicator positioning
  min-height: 6rem;

  > .loading {
    @apply mt-8;
  }

  &__side-bar {
    @screen lg {
      @apply ml-8 w-1/3 max-w-sm;
    }
  }

  &__actions-card {
    @apply hidden;

    @screen lg {
      @apply block;
    }

    &--mobile {
      @apply mt-4;

      @screen lg {
        @apply hidden;
      }
    }
  }

  @screen lg {
    &__content {
      @apply flex flex-row;
    }

    &__main {
      @apply flex-grow;
    }
  }

  &__header {
    @apply mb-8;
  }

  .card {
    @apply mb-8;
  }

  .details-card {
    @apply p-4;

    .chec-header {
      @apply mb-4;
    }
  }

  .slideout-panel {
    // Hack to avoid overlapping loading components
    @apply z-50;

    // @TODO explore fix in UI library.
    &__footer,
    &__header {
      @apply z-10;
    }

    &__footer-inner {
      @apply py-6;
    }

    &__header-inner {
      @apply py-8;
    }
  }
}
</style>
