<template>
  <ChecCard inner-class="attribute-actions" tailwind="p-4">
    <div class="attribute-actions__primary">
      <ChecButton
        color="green"
        class="flex-grow"
        :disabled="saving || disabled"
        button-type="submit"
        @click="() => $emit('save-attribute')"
      >
        {{ saveLabel }}
      </ChecButton>
      <ChecOptionsMenu
        v-if="allowDelete"
        class="attribute-actions__more-options"
        :aria-label="$t('product.additionalActions')"
      >
        <ChecOption
          destructive
          @option-selected="() => $emit('delete-attribute')"
        >
          {{ $t('general.delete') }}
        </ChecOption>
      </ChecOptionsMenu>
    </div>
    <hr class="attribute-actions__rule">
    <div class="attribute-actions__secondary">
      <ChecSwitch
        v-model="isPublic"
        name="active"
      >
        {{ isPublic
          ? $t('general.visible')
          : $t('general.private')
        }}
      </ChecSwitch>
    </div>
  </ChecCard>
</template>

<script>
import {
  ChecButton,
  ChecCard,
  ChecOptionsMenu,
  ChecOption,
  ChecSwitch,
} from '@chec/ui-library';

export default {
  name: 'ActionsCard',
  components: {
    ChecButton,
    ChecCard,
    ChecOptionsMenu,
    ChecOption,
    ChecSwitch,
  },
  props: {
    saving: Boolean,
    disabled: Boolean,
    allowDelete: Boolean,
    apiVisibility: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    isPublic: {
      get() {
        return this.apiVisibility;
      },
      set(value) {
        this.$emit('toggle-active', value);
      },
    },
    /**
     * The label shown for the "save" button
     *
     * @returns {string}
     */
    saveLabel() {
      return this.saving ? this.$t('general.saving') : this.$t('general.saveChanges');
    },
  },
};
</script>

<style lang="scss">
.attribute-actions {
  @apply flex flex-col;

  &__rule {
    @apply border-gray-200 mt-4 mb-2;
  }

  &__primary {
    @apply flex flex-row gap-2;
  }

  &__secondary {
    @apply flex flex-row justify-between;
  }

  &__more-options {
    @apply flex items-center;
  }
}
</style>
