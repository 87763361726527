<template>
  <ChecCard inner-class="actions-card" tailwind="p-4">
    <ChecButton
      color="green"
      :disabled="saving"
      button-type="submit"
      @click="() => $emit('save-merchant')"
    >
      {{ saveLabel }}
    </ChecButton>
  </ChecCard>
</template>

<script>
import {
  ChecButton,
  ChecCard,
} from '@chec/ui-library';

export default {
  name: 'ActionsCard',
  components: {
    ChecButton,
    ChecCard,
  },
  props: {
    saving: Boolean,
  },
  computed: {
    /**
     * The label shown for the "save" button
     *
     * @returns {string}
     */
    saveLabel() {
      return this.saving ? this.$t('general.saving') : this.$t('general.saveChanges');
    },
  },
};
</script>

<style lang="scss">
.actions-card {
  @apply flex flex-col;
}
</style>
