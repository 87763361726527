<template>
  <ChecCard inner-class="details-card" tailwind="p-4">
    <DashboardHeader
      variant="card"
      margin="small"
      :title="$t('general.details')"
    />
    <div class="input-wrapper__row space-x-2">
      <div class="input-wrapper">
        <TextField
          :value="details.name"
          required
          name="name"
          :label="$t('general.name')"
          @input="value => change('name', value)"
        />
      </div>
      <div class="input-wrapper">
        <ChecFormField
          :tooltip="$t('account.emailToolTip')"
          :append-label="$t('general.whatIsThis')"
        >
          <TextField
            :value="details.email"
            required
            name="email"
            type="email"
            :label="$t('general.emailAddress')"
            @input="value => change('email', value)"
          />
        </ChecFormField>
      </div>
    </div>
  </ChecCard>
</template>

<script>
import {
  ChecCard,
  ChecFormField,
  TextField,
} from '@chec/ui-library';
import DashboardHeader from '@/components/DashboardHeader.vue';

export default {
  name: 'DetailsCard',
  components: {
    ChecCard,
    ChecFormField,
    DashboardHeader,
    TextField,
  },
  model: {
    prop: 'details',
    event: 'input',
  },
  props: {
    details: {
      type: Object,
      required: true,
    },
  },
  methods: {
    change(prop, value) {
      this.$emit('input', {
        ...this.details,
        [prop]: value,
      });
    },
  },
};
</script>
