<template>
  <ChecCard
    tailwind="p-4"
    variant="card"
    class="configuration-card"
  >
    <ChecHeader
      :title="configurationTitle"
      variant="card"
      header-tag="h3"
    >
      <ChecSwitch
        v-if="attribute.type === 'options'"
        :toggled="attribute.is_multiselect"
        prefix-label
        name="isMulti"
        @input="value => updateMultiSelect(value)"
      >
        {{ $t('attributes.allowMultiselect') }}
      </ChecSwitch>
      <ChecButton
        variant="round"
        color="primary"
        class="configuration-card__add-option"
        @click="handleAddOption"
      >
        <template #icon>
          <ChecIcon icon="plus" />
        </template>
        {{ $t('general.add') }}
      </ChecButton>
    </ChecHeader>
    <ChecAlert
      v-if="showDuplicateWarning"
      variant="warning"
      inline
      show-icon
      disable-close
    >
      {{ $t('attributes.duplicateWarning') }}
    </ChecAlert>
    <div
      class="input-wrapper attributes-add-edit__options-field"
    >
      <ConfigurationTable
        :configurations="configurations"
        @update-configuration="updateConfiguration"
      />
    </div>
  </ChecCard>
</template>

<script>
import {
  ChecAlert,
  ChecCard,
  ChecButton,
  ChecHeader,
  ChecIcon,
  ChecSwitch,
} from '@chec/ui-library';
import ConfigurationTable from './ConfigurationTable.vue';

export default {
  name: 'ConfigurationCard',
  components: {
    ChecAlert,
    ChecCard,
    ChecButton,
    ChecHeader,
    ChecIcon,
    ChecSwitch,
    ConfigurationTable,
  },
  model: {
    prop: 'attribute',
    event: 'input',
  },
  props: {
    attribute: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      configurations: [],
    };
  },
  computed: {
    configurationTitle() {
      return this.attribute.type === 'options'
        ? this.$t('attributes.configureDropdown')
        : this.$t('attributes.configureRadio');
    },
    showDuplicateWarning() {
      return new Set(this.configurations.map((item) => item.value))
        .size !== this.configurations.length;
    },
  },
  mounted() {
    if (this.attribute.options.length > 0) {
      this.configurations = this.attribute.options;
    }
    if (this.configurations.length === 0) {
      this.handleAddOption();
    }
  },
  methods: {
    updateMultiSelect(value) {
      this.$emit('input', {
        ...this.attribute,
        is_multiselect: value,
      });
    },
    handleAddOption() {
      this.updateConfiguration([
        ...this.configurations,
        {
          label: '',
          value: '',
        },
      ]);
    },
    updateConfiguration(newData) {
      this.configurations = newData;
      this.$emit('options-change', this.configurations);
    },
  },
};
</script>

<style lang="scss">
.configuration-card {
  &__add-option {
    @apply ml-4;
  }
}
</style>
