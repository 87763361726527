<template>
  <form class="discounts-add-edit" @submit.prevent>
    <DashboardHeader :title="headerText" />
    <ChecLoading v-if="loading" without-background />
    <div v-else class="discounts-add-edit__content space-y-4 md:space-y-0">
      <div class="discounts-add-edit__main space-y-4">
        <DetailsCard
          v-model="discount"
          :is-loading="isSaving || loading"
          :errors="validationErrors"
          :merchant-currency="merchantCurrency"
        />
      </div>
      <div class="discounts-add-edit__side-bar space-y-4">
        <ActionsCard
          :allow-delete="!isAdding"
          :saving="isSaving"
          @save-discount="handleSaveDiscount"
          @delete-discount="handleDeleteDiscount"
        />
      </div>
    </div>
  </form>
</template>

<script>
import {
  ChecLoading,
} from '@chec/ui-library';
import addNotification from '@/mixins/addNotification';
import crud from '@/mixins/crud';
import confirm from '@/mixins/confirm';
import validateSchemaRequest from '@/lib/helpers/validateSchemaRequestHelper';
import DashboardHeader from '@/components/DashboardHeader.vue';
import discountSchema from '../schemas/discount';
import ActionsCard from '../components/ActionsCard.vue';
import DetailsCard from '../components/DetailsCard.vue';

export default {
  name: 'DiscountsAddEdit',
  components: {
    DashboardHeader,
    DetailsCard,
    ActionsCard,
    ChecLoading,
  },
  mixins: [
    addNotification,
    crud('discounts', true),
    confirm,
  ],
  props: {
    merchantCurrency: {
      type: String,
      default: '$',
    },
  },
  data() {
    return {
      discount: {
        code: '',
        value: '',
        type: 'fixed',
        starts_on: null,
        expires_on: null,
        quantity: null,
        product_ids: [],
      },
      validationErrors: {},
      isSaving: false,
      loading: true,
    };
  },
  computed: {
    /**
     * Are we adding?
     *
     * @returns {boolean}
     */
    isAdding() {
      return this.$route.name === 'discounts.add';
    },
    /**
     * Returns the header text for the page
     *
     * @returns {string}
     */
    headerText() {
      return this.isAdding ? this.$t('discount.add') : this.$t('discount.edit');
    },
    discountId() {
      return this.$route.params.id;
    },
  },
  /**
   * Fetch discount, if editing
   */
  mounted() {
    if (this.isAdding) {
      this.loading = false;
      return;
    }

    // Load discount
    this.loadDiscount();
  },
  methods: {
    /**
     * Save the discount, and return to the discounts list view
     */
    handleSaveDiscount() {
      this.validationErrors = {};
      this.isSaving = true;
      validateSchemaRequest(discountSchema, this.discount, { abortEarly: false })
        .then(
          (validatedData) => (this.isAdding
            ? this.create(validatedData, true, false)
            : this.update(this.discountId, validatedData, true, false)),
        )
        .then(({ id }) => {
          this.addNotification(
            this.isAdding ? this.$t('discount.added') : this.$t('discount.updated'),
          );
          if (this.isAdding) {
            this.$router.push({ name: 'discounts.edit', params: { id } });
          }
          this.loadDiscount();
        })
        .catch((error) => {
          if (error.name === 'ValidationError') { // yup schema validation error
            this.validationErrors = error.errors;
            // return;
          }

          if (error.response?.status === 422) {
            const {
              message,
              param,
            } = error.response.data.error;
            this.validationErrors = {
              ...this.validationErrors,
              [param]: message,
            };
            return;
          }

          this.addNotification(
            this.$t('discount.saveError'),
            'error',
          );
        })
        .finally(() => {
          this.isSaving = false;
        });
    },
    /**
     * Confirm and delete the discount.
     */
    async handleDeleteDiscount() {
      if (!await this.confirm(
        this.$t('general.areYouSure'),
        this.$t('discount.confirmDelete'),
      )) {
        return;
      }

      // Send user immediately back to discounts list
      const originalRoute = this.$router.currentRoute;
      const originalDiscountId = this.discountId;
      await this.$router.push({ name: 'discounts.home' });

      if (originalDiscountId === undefined) {
        // Discount wasn't saved yet, do nothing.
        return;
      }

      this.delete(originalDiscountId)
        .then(() => {
          this.addNotification(this.$t('discount.deleted'));
        })
        .catch((error) => {
          this.addNotification(this.$t('discount.deleteFailed'), 'error');
          this.$router.push(originalRoute); // send user back to category view
          throw error;
        });
    },
    loadDiscount() {
      this.load(this.discountId)
        .then(async () => {
          const discountData = await this.get(this.discountId);

          // Format some of the API data before storing on the component
          this.discount = {
            code: discountData.code,
            value: discountData.value,
            type: discountData.type,
            starts_on: discountData.starts_on
              // Convert from timestamp to date string if set
              ? this.$moment.unix(discountData.starts_on).format('YYYY-MM-DD')
              : null,
            expires_on: discountData.expires_on
              ? this.$moment.unix(discountData.expires_on).format('YYYY-MM-DD')
              : null,
            quantity: discountData.limit_quantity ? discountData.quantity : null,
            product_ids: discountData.product_ids ?? [],
            // Remove attributes that get added by the CRUD mixin
            description: undefined,
          };
          this.loading = false;
        })
        .catch(() => {
          this.$router.push({ name: 'notFound' });
        });
    },
  },
};
</script>

<style lang="scss">
.discounts-add-edit {
  @apply text-gray-500;

  &__side-bar {
    @screen lg {
      @apply ml-8 w-1/3 max-w-sm flex-shrink-0;
    }
  }

  @screen lg {
    &__content {
      @apply flex flex-row;
    }

    &__main {
      @apply flex-grow;
    }
  }

  .card {
    @apply mb-8;
  }

  .details-card {
    @apply p-4;

    .chec-header {
      @apply mb-4;
    }
  }
}
</style>
