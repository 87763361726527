<template>
  <div class="billing-home">
    <DashboardHeader :title="$t('billing.planDetails')" />
    <template v-if="error">
      <!-- error UI -->
      <p>There was an error. {{ error }}</p>
    </template>
    <template v-else-if="details">
      <div class="billing-home__top-wrapper">
        <CurrentPlanCard :details="details" />
        <PlanTable
          :plans="plans"
          :current-plan="details.plan"
        />
      </div>
      <DashboardHeader class="billing-home__invoices-header" :title="$t('billing.invoices')" />
      <InvoiceTable :invoices="invoices" />
      <DashboardPagination state-key="billing/invoices" />
      <ChecModal
        v-if="!isLoading && viewedPlan"
        width="lg"
        :header="$t('billing.changePlan')"
        @dismiss="resetViewedPlan"
      >
        <PlanDetails
          :plan="viewedPlan"
          :disable-change-plan="!canChangePlan"
          @close="resetViewedPlan"
        />
      </ChecModal>
    </template>
    <ChecLoading v-else without-background />
  </div>
</template>

<script>
import { ChecLoading, ChecModal } from '@chec/ui-library';
import { mapGetters, mapState } from 'vuex';
import DashboardHeader from '@/components/DashboardHeader.vue';
import DashboardPagination from '@/components/Pagination.vue';
import { mapPagination } from '@/lib/pagination';
import CurrentPlanCard from '../components/CurrentPlanCard.vue';
import PlanTable from '../components/PlanTable.vue';
import InvoiceTable from '../components/InvoiceTable.vue';
import PlanDetails from '../components/PlanDetails.vue';

export default {
  name: 'BillingHome',
  components: {
    CurrentPlanCard,
    ChecLoading,
    ChecModal,
    DashboardHeader,
    DashboardPagination,
    PlanDetails,
    PlanTable,
    InvoiceTable,
  },
  computed: {
    /**
     * Whether the merchant can change their plan.
     *
     * @returns {Boolean}
     */
    canChangePlan() {
      return this.details.has_payment_method || false;
    },
    viewedPlan() {
      return this.$route.name === 'settings.billing.plan' && this.plans && this.plans.find(
        (candidate) => candidate.slug === this.$route.params.slug,
      );
    },
    ...mapState('billing', ['error', 'details', 'plans']),
    ...mapState('merchant', ['merchant']),
    ...mapGetters(['isLoading']),
    ...mapPagination('billing/invoices'),
  },
  methods: {
    resetViewedPlan() {
      this.$router.push({ name: 'settings.billing.home' });
    },
  },
};
</script>

<style lang="scss">
.billing-home {
  // Work around ChecLoading awkwardness
  min-height: 4rem;

  > .loading {
    @apply pb-16 mt-4;
  }

  &__invoices-header {
    @apply mt-20;
  }

  &__top-wrapper {
    @screen lg {
      @apply flex w-full;
    }
  }

  &__invoice-label-pagination-wrapper {
    @apply flex flex justify-between items-center mt-4;
  }
}
</style>
