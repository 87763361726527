<template>
  <div v-if="log" class="log-response-request">
    <CodeBlockGroup
      :key="log.id"
    >
      <CodeBlock
        key="1"
        ref="response"
        :title="$t('developer.response')"
        language="json"
      >
        {{ log.response }}
      </CodeBlock>
      <CodeBlock
        key="2"
        ref="request"
        :title="$t('developer.request')"
        language="json"
      >
        {{ log.request }}
      </CodeBlock>
    </CodeBlockGroup>
    <template>
      <ChecButton
        v-if="canCopy && log"
        class="log-response-request__copy"
        icon="copy"
        color="primary"
        variant="small"
        :title="$t('general.copy')"
        @click="copyText(copyContent())"
      />
    </template>
  </div>
</template>

<script>
import {
  CodeBlockGroup,
  CodeBlock,
  ChecButton,
} from '@chec/ui-library';
import addNotification from '@/mixins/addNotification';
import copyText from '@/mixins/copyText';
import i18n from '../../../../lang';

export default {
  name: 'LogResponseRequest',
  components: {
    CodeBlockGroup,
    CodeBlock,
    ChecButton,
  },
  mixins: [addNotification, copyText(
    i18n.t('developer.copyCodeSuccess'),
    i18n.t('developer.copyCodeFailure'),
  )],
  props: {
    log: Object,
  },
  methods: {
    /**
     * Determine if the response or request code should be copied
     * and returns the copy content after evaluation
     */
    copyContent() {
      if (this.$el.contains(this.$refs.request?.$el)) {
        return JSON.stringify(this.log.request, null, 2);
      }
      return JSON.stringify(this.log.response, null, 2);
    },
  },
};
</script>

<style lang="scss">
.log-response-request {
  &__copy {
    @apply ml-auto mt-2;
  }
}
</style>
