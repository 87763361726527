<template>
  <div class="invoice-fees">
    <div class="invoice-view__toolbar">
      <h2>{{ $t('billing.transactionFeesTitle', { date: invoiceDate }) }}</h2>
      <div v-show="totalFees" class="invoice-view__total">
        {{ $t('general.total') }} <span class="invoice-fees__amount">{{ totalFees }}</span>
      </div>
      <!--
        todo build export to CSV handler
        <ChecButton variant="round" @click="handleExportCsv">Export CSV</ChecButton>
      -->
    </div>

    <table class="invoice-fees__table">
      <thead>
        <tr>
          <th>{{ $t('billing.invoiceDate') }}</th>
          <th>{{ $t('billing.transactionFeeOrder') }}</th>
          <th>{{ $t('billing.paymentGateway') }}</th>
          <th>{{ $t('billing.orderValue', { currency: 'USD' }) }}</th>
          <th>{{ $t('billing.checFee', { currency: 'USD' }) }}</th>
        </tr>
      </thead>
      <tbody>
        <tr v-if="transactionFees.length === 0" class="invoice-fees--empty">
          <td colspan="5">
            {{ $t('general.empty') }}
          </td>
        </tr>
        <tr v-for="fee in transactionFees" :key="fee.id">
          <td>{{ fee.created | moment("MMMM Do, YYYY") }}</td>
          <td>{{ fee.order_reference }}</td>
          <td>{{ fee.gateway | capitalize }}</td>
          <td class="invoice-fees__amount">
            {{ money(fee.order_value).full() }}
          </td>
          <td>{{ money(fee.amount).full() }}</td>
        </tr>
      </tbody>
    </table>
    <DashboardPagination state-key="billing/transactionFees" :context="{ invoice: invoiceId }" />
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import money from '@/lib/helpers/money';
import { getters, actions, mapPagination } from '@/lib/pagination';
import DashboardPagination from '@/components/Pagination.vue';

export default {
  name: 'TransactionFees',
  components: {
    DashboardPagination,
  },
  computed: {
    ...mapState('billing', ['error']),
    ...mapPagination('billing/transactionFees'),
    ...mapGetters('billing/invoices', { getInvoice: getters.GET_ITEM }),
    /**
     * The invoice ID being viewed
     *
     * @returns {string}
     */
    invoiceId() {
      return this.$route.params.id;
    },
    /**
     * The current invoice's date
     *
     * @returns {string}
     */
    invoiceDate() {
      const invoice = this.getInvoice(this.invoiceId);
      if (!invoice) {
        return '';
      }
      return this.$moment(invoice.created * 1000).format('MMMM Do, YYYY');
    },
    /**
     * The total value of the invoice
     *
     * @returns {string|null}
     */
    totalFees() {
      const invoice = this.getInvoice(this.$route.params.id);
      if (!invoice) {
        return null;
      }
      return money(invoice.amount).full_with_currency();
    },
  },
  mounted() {
    this.loadInvoice({ id: this.invoiceId });
  },
  methods: {
    money,
    ...mapActions('billing/invoices', { loadInvoice: actions.LOAD_ITEM }),
  },
};
</script>

<style scoped lang="scss">
.invoice-view {
  &__total {
    @apply mr-4 caps-xs;
  }
}

.invoice-fees {
  &__table {
    @apply w-full;

    th:nth-child(n+3),
    td:nth-child(n+3) {
      @apply text-right;
    }
  }

  &__amount {
    @apply text-green-600;
  }

  &--empty {
    @apply text-gray-400;
  }
}
</style>
