<template>
  <ChecCard
    class="merchant-address__card"
    inner-class="details-card"
  >
    <ChecLoading v-if="loading" />
    <ChecHeader
      variant="card"
      :title="$t('settings.merchant.merchantAddress')"
    >
      <ChecSwitch
        name="isPublic"
        prefix-label
        :toggled="merchant.address.public"
        @input="value => change('public', value)"
      >
        {{ $t('settings.merchant.isPublic') }}
      </ChecSwitch>
    </ChecHeader>
    <div class="input-wrapper">
      <TextField
        :value="merchant.address.street"
        name="street"
        :label="$t('general.address.street')"
        :variant="errors && errors['address.street'] ? 'error' : ''"
        @input="value => change('street', value)"
      />
      <span
        v-if="errors && errors['address.street']"
        class="input-wrapper__error"
      >
        {{ errors['address.street'] }}
      </span>
    </div>
    <div class="input-wrapper__row space-x-2">
      <div class="merchant-address__input-wrapper w-7/12">
        <TextField
          :value="merchant.address.city"
          name="city"
          :label="$t('general.address.city')"
          :variant="errors && errors['address.city'] ? 'error' : ''"
          @input="value => change('city', value)"
        />
        <span
          v-if="errors && errors['address.city']"
          class="input-wrapper__error"
        >
          {{ errors['address.city'] }}
        </span>
      </div>
      <div class="merchant-address__input-wrapper w-5/12">
        <ChecDropdown
          v-if="Object.values(subdivisionOptions).length"
          v-model="selectedRegion"
          :options="subdivisionOptions"
          name="region"
          :label="$t('general.address.provinceState')"
          :variant="errors && errors['address.region'] ? 'error' : ''"
          :search-value="searchRegion"
          show-search
          required
          @search="(value) => searchRegion = value"
        />
        <span
          v-if="errors && errors['address.region']"
          class="input-wrapper__error"
        >
          {{ errors['address.region'] }}
        </span>
      </div>
    </div>
    <div class="input-wrapper__row space-x-2">
      <div class="merchant-address__input-wrapper w-4/12">
        <TextField
          :value="merchant.address.postal_zip_code"
          name="postal_zip_code"
          :label="$t('general.address.postalZip')"
          :variant="errors && errors['address.postal_zip_code'] ? 'error' : ''"
          @input="value => change('postal_zip_code', value)"
        />
        <span
          v-if="errors && errors['address.postal_zip_code']"
          class="input-wrapper__error"
        >
          {{ errors['address.postal_zip_code'] }}
        </span>
      </div>
      <div class="merchant-address__input-wrapper w-8/12">
        <ChecDropdown
          v-if="Object.values(countriesOptions).length"
          v-model="selectedCountry"
          :options="countriesOptions"
          name="country"
          :label="$t('general.address.country')"
          :variant="errors && errors['address.country'] ? 'error' : ''"
          :search-value="searchCountry"
          show-search
          required
          @search="(value) => searchCountry = value"
        />
        <span
          v-if="errors && errors['address.country']"
          class="input-wrapper__error"
        >
          {{ errors['address.country'] }}
        </span>
      </div>
    </div>
  </ChecCard>
</template>

<script>
import {
  ChecCard,
  ChecDropdown,
  ChecHeader,
  ChecLoading,
  ChecSwitch,
  TextField,
} from '@chec/ui-library';
import actions from '@/store/actions';

export default {
  name: 'MerchantAddress',
  components: {
    ChecCard,
    ChecDropdown,
    ChecHeader,
    ChecLoading,
    ChecSwitch,
    TextField,
  },
  model: {
    prop: 'merchant',
    event: 'input',
  },
  props: {
    merchant: {
      type: Object,
      required: true,
    },
    countries: {
      type: Object,
      required: true,
    },
    subdivisions: {
      type: Object,
      required: true,
    },
    errors: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      selectedCountry: this.merchant.address.country || '',
      selectedRegion: this.merchant.address.region || '',
      searchCountry: '',
      searchRegion: '',
      loading: false,
    };
  },
  computed: {
    /**
     * Gets a list of available countries, formatted as options for a dropdown
     *
     * @returns {object[]}
     */
    countriesOptions() {
      // Filter countries based on a search (if there is one)
      const filteredCountries = this.searchCountry.length > 0
        ? Object.entries(this.countries)
          // Make country label lower case to prevent case sensitivity in search input
          .filter((country) => country[1].toLowerCase().includes(this.searchCountry.toLowerCase()))
        : Object.entries(this.countries);

      return filteredCountries.map(([value, label]) => ({ value, label }));
    },
    /**
     * Gets a list of available states/regions/subdivisions for the currently selected country
     *
     * @returns {object[]}
     */
    subdivisionOptions() {
      const countryCode = this.selectedCountry;

      if (!this.subdivisions[countryCode]) {
        return [];
      }

      // Filter countries based on a search (if there is one)
      const filteredRegions = this.searchRegion.length > 0
        ? Object.entries(this.subdivisions[countryCode])
          // Make country label lower case to prevent case sensitivity in search input
          .filter((region) => region[1].toLowerCase().includes(this.searchRegion.toLowerCase()))
        : Object.entries(this.subdivisions[countryCode]);

      return filteredRegions.map(([key, value]) => ({ value: key, label: value }));
    },
  },
  watch: {
    selectedCountry: {
      handler(val, oldVal) {
        if (oldVal !== val) {
          this.getRegionsForCountry(val);
          this.change('country', val);
        }
        // If not just mounted
        if (typeof oldVal !== 'undefined') {
          // Unselect whatever state was selected
          this.selectedRegion = '';
          this.searchRegion = '';
        }
      },
      immediate: true,
    },
    selectedRegion: {
      handler(val) {
        this.change('region', val);
      },
    },
  },
  methods: {
    /**
     * Get a list of available regions from the API for the given country code
     *
     * @param {string} countryCode
     */
    getRegionsForCountry(countryCode) {
      if (countryCode === '') {
        // Do nothing
        return;
      }

      if (this.subdivisions[countryCode]) {
        // Already exists in state
        return;
      }

      this.loading = true;
      this.$store.dispatch(actions.FETCH_SUBDIVISIONS, countryCode)
        .finally(() => {
          this.loading = false;
        });
    },
    change(prop, value) {
      this.$emit('input', {
        ...this.merchant,
        address: {
          ...this.merchant.address,
          [prop]: value,
        },
      });
    },
  },
};
</script>

<style lang="scss">
.merchant-address {
  &__input-wrapper {
    @apply mb-2;
  }
}
</style>
