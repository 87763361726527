/**
 * Currencies supported by the API that are indicated as not having any fractional currency, eg.
 * cents are fractions of the dollar
 *
 * @type {string[]}
 */
export const zeroDecimalCurrencies = [
  'BIF',
  'CLP',
  'DJF',
  'GNF',
  'JPY',
  'KMF',
  'KRW',
  'MGA',
  'PYG',
  'RWF',
  'UGX',
  'VND',
  'VUV',
  'XAF',
  'XOF',
  'XPF',
];

/**
 * Formats the given value into the given currency. This is returned with the currency symbol by
 * default, but you may choose any option for `currencyDisplay` used by the Intl.NumberFormat
 * constructor. Additionally, you can provide `false` as the currencyDisplay to omit a currency.
 *
 * @see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/NumberFormat/NumberFormat#parameters
 *
 * @param {Number} value
 * @param {{code: String, symbol: string}} currencyDetail The currency object provided by the API
 * @param {String|false} currencyDisplay
 * @returns {string}
 */
export function formatCurrency(value, currencyDetail, currencyDisplay = 'narrowSymbol') {
  const { code, symbol } = currencyDetail;
  // Create some options that are used in the NumberFormat constructor.
  const options = {
    currency: code,
    style: 'currency',
    currencyDisplay,
  };

  // Check for currency display compatibility (for really old browsers)
  try {
    // eslint-disable-next-line no-new
    new Intl.NumberFormat(undefined, options);
  } catch (error) {
    // Fallback to guessing where the symbol/code goes for the locale (ie. show it the western way)
    const prefix = currencyDisplay === 'code' ? `${code} ` : symbol;
    return `${prefix}${typeof value !== 'string'
      ? value.toFixed(zeroDecimalCurrencies.includes(code) ? 0 : 2)
      : value}`;
  }

  // eslint-disable-next-line no-unreachable
  if (!currencyDisplay) {
    // We force the currency to display with a code but then replace it out. We do this as there
    // doesn't appear to be any API to get this value, and we can't use a standard number formatting
    // call as we don't know how many significant digits the locale normally shows for currencies
    return new Intl.NumberFormat(undefined, { ...options, currencyDisplay: 'code' })
      .format(value)
      .replace(new RegExp(`\\s*${code}\\s*`), '');
  }

  // TODO Sync this up with the dashboard setting for localisation when it supports regional
  // translation (eg. en-IN for Indian English).
  return Intl.NumberFormat(undefined, options).format(value);
}
