<template>
  <ChecCard
    class="currency__card"
    inner-class="details-card"
  >
    <ChecHeader
      variant="card"
      :title="$t('general.currency')"
    />
    <div class="input-wrapper">
      <ChecFormField>
        <ChecDropdown
          v-model="selectedCurrency"
          :options="currencyOptions"
          name="currency"
          :label="$t('general.currency')"
          required
          show-search
          :variant="errors.address ? 'error' : ''"
          :search-value="searchTerm"
          @search="handleSearch"
        />
      </ChecFormField>
    </div>
  </ChecCard>
</template>

<script>
import {
  ChecCard,
  ChecHeader,
  ChecFormField,
  ChecDropdown,
} from '@chec/ui-library';
import supportedCurrencies from '@/lib/currencies';

export default {
  name: 'Currency',
  components: {
    ChecCard,
    ChecHeader,
    ChecFormField,
    ChecDropdown,
  },
  model: {
    prop: 'merchant',
    event: 'input',
  },
  props: {
    merchant: {
      type: Object,
      required: true,
    },
    errors: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      selectedCurrency: this.merchant.currency || '',
      searchTerm: '',
    };
  },
  computed: {
    currencyOptions() {
      return supportedCurrencies
        .filter((currency) => {
          if (!this.searchTerm) {
            return currency;
          }
          // If searching, perform a lowercase search match
          const term = this.searchTerm.toString().toLowerCase();
          return currency.code.toLowerCase().includes(term)
            || currency.name.toLowerCase().includes(term);
        })
        .map((currency) => ({
          value: currency.code,
          label: currency.name,
        }));
    },
  },
  watch: {
    selectedCurrency: {
      handler(val) {
        this.$emit('input', {
          ...this.merchant,
          currency: val,
        });
      },
    },
  },
  methods: {
    handleSearch(value) {
      this.searchTerm = value.trim();
    },
  },
};
</script>
