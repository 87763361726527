<template>
  <div>
    <ChecSlideoutPanel
      :title="$t('settings.tax.addTaxZone')"
      close-on-overlay-click
      @close="close"
    >
      <template>
        <div class="zone-country-select">
          <ChecAlert
            class="zone-country-select__alert"
            variant="info"
            show-icon
            inline
            disable-close
          >
            {{ $t('settings.tax.countrySelectInfo') }}
          </ChecAlert>
          <div
            class="input-wrapper"
          >
            <ChecDropdown
              v-model="selectedCountry"
              :options="countriesOptions"
              name="country"
              :label="$t('general.address.country')"
              required
              :search-value="searchCountry"
              show-search
              @search="(value) => searchCountry = value"
            />
          </div>
        </div>
      </template>
      <template #toolbar>
        <div class="zone-country-select__toolbar space-x-4">
          <ChecButton text-only color="primary" @click="close">
            {{ $t('general.cancel' ) }}
          </ChecButton>
          <ChecButton
            button-type="submit"
            color="primary"
            :disabled="selectedCountry === null"
            @click="handleSetCountry()"
          >
            {{ $t('settings.tax.configureTaxZone') }}
          </ChecButton>
        </div>
      </template>
    </ChecSlideoutPanel>
    <ConfigureZone
      v-if="userReady"
      :zone="zone"
      :country="selectedCountry"
      :loading="loading"
      @close="userReady = false"
      @update="handleUpdateZone"
      @save-configuration="handleSaveConfiguration"
      @delete-configuration="handleDeleteConfiguration"
    />
  </div>
</template>

<script>
import {
  ChecAlert,
  ChecButton,
  ChecDropdown,
  ChecSlideoutPanel,
} from '@chec/ui-library';
import addNotification from '@/mixins/addNotification';
import crud from '@/mixins/crud';
import confirm from '@/mixins/confirm';
import { mapGetters } from 'vuex';
import ConfigureZone from '../../components/tax/ConfigureZone.vue';

export default {
  name: 'AddEdit',
  components: {
    ChecAlert,
    ChecButton,
    ChecDropdown,
    ChecSlideoutPanel,
    ConfigureZone,
  },
  mixins: [
    addNotification,
    confirm,
    crud('settings/tax/zones', true),
  ],
  data() {
    return {
      loading: false,
      searchCountry: '',
      selectedCountry: null,
      userReady: false,
      zone: {
        calculate_automatically: false,
        country_code: null,
        country_digital_rate: 0,
        country_standard_rate: 0,
        id: null,
        rates: [],
        type: null,
      },
    };
  },
  computed: {
    ...mapGetters(['countries']),
    isAdding() {
      return this.$route.name === 'settings.tax.addZone';
    },
    /**
     * Returns a list of the existing zones countries by country code.
     *
     * @returns {Array}
     */
    existingCountries() {
      return this.zones.reduce((zone, obj) => zone.concat(obj.country_code), []);
    },
    /**
     * Gets a list of available countries, formatted as options for a dropdown
     * excluding the countries that are already used.
     *
     * @returns {Array}
     */
    countriesOptions() {
      // Filter countries based on a search (if there is one)
      const countries = this.searchCountry.length > 0
        ? Object.entries(this.countries)
          // Make country label lower case to prevent case sensitivity in search input
          .filter((country) => country[1].toLowerCase().includes(this.searchCountry.toLowerCase()))
        : Object.entries(this.countries);

      // Only filter out existing countries if user is adding a new tax zone.
      if (this.isAdding) {
        // Filter out existing countries from the options list by comparing country codes
        const filteredCountries = countries
          .filter((candidate) => !this.existingCountries.includes(candidate[0]));

        return filteredCountries.map(([value, label]) => ({ value, label }));
      }

      return countries.map(([value, label]) => ({ value, label }));
    },
  },
  async mounted() {
    if (this.isAdding) {
      return;
    }

    this.userReady = true;
    const { id } = this.$route.params;
    const zone = await this.load(id).then(() => this.get(id));
    this.selectedCountry = zone.country_code;
    this.zone = zone;
  },
  methods: {
    close() {
      this.$router.push({ name: 'settings.tax' });
    },
    handleUpdateZone(zone) {
      this.zone = zone;
    },
    handleSetCountry() {
      // Default to nexus enabled when adding the US tax zone
      this.zone.calculate_automatically = false;
      if (this.selectedCountry === 'US') {
        this.zone.calculate_automatically = true;
      }

      this.userReady = true;
    },
    handleSaveConfiguration() {
      (
        this.loading = true,
        this.isAdding
          ? this.create({
            ...this.zone,
            country_code: this.selectedCountry,
          }, true, false)
          : this.update(this.zone.id, this.zone, true, false)
      )
        .then(() => {
          this.close();
          this.addNotification(this.$t('general.saved'));
        })
        .catch(() => {
          this.addNotification(this.$t('settings.tax.saveFailed'), 'error');
        })
        .finally(() => {
          this.loading = false;
        });
    },
    /**
     * Confirm and delete the tax zone.
     */
    async handleDeleteConfiguration() {
      this.close();
      if (!await this.confirm(
        this.$t('settings.tax.confirmDeleteZoneTitle'),
        this.$t('settings.tax.confirmDeleteZone'),
      )) {
        return;
      }
      this.delete(this.zone.id)
        .then(() => {
          this.addNotification(this.$t('settings.tax.deleted'));
        })
        .catch((error) => {
          this.addNotification(this.$t('settings.tax.saveFailed'), 'error');
          throw error;
        });
    },
  },
};
</script>

<style lang="scss">
.zone-country-select {
  &__alert {
    @apply mb-4;
  }

  &__toolbar {
    @apply flex justify-end w-full;
  }
}
</style>
