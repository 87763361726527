<template>
  <ChecCard
    tailwind="p-4"
    variant="card"
  >
    <ChecHeader
      :title="$t('general.details')"
      variant="card"
      header-tag="h3"
    >
      <ChecSwitch
        :toggled="attribute.required"
        prefix-label
        name="required"
        @input="value => change('required', value)"
      >
        {{ requiredLabel }}
      </ChecSwitch>
    </ChecHeader>
    <div class="input-wrapper__row space-x-2">
      <div class="input-wrapper">
        <TextField
          :value="attribute.name"
          name="attribute-name"
          type="text"
          required
          :label="$t('general.name')"
          @input="value => change('name', value)"
        />
        <span v-if="errors.name" class="input-wrapper__error">
          {{ errors.name }}
        </span>
      </div>
      <div class="input-wrapper attributes-add-edit__field-type">
        <ChecFormField>
          <ChecDropdown
            :value="attribute.type"
            required
            :options="fieldTypes"
            :label="$t('extraFields.fieldType')"
            name="attribute-type"
            @input="value => change('type', value)"
          />
        </ChecFormField>
        <span v-if="errors.type" class="input-wrapper__error">
          {{ errors.type }}
        </span>
      </div>
    </div>
    <div class="input-wrapper">
      <ChecFormField :tooltip="$t('attributes.defaultValueTooltip')">
        <DefaultValueSelector
          :attribute="attribute"
          :errors="errors"
          @set-default="value => change('default_value', value)"
        />
      </ChecFormField>
    </div>
    <div class="input-wrapper">
      <ChecFormField :tooltip="$t('attributes.descriptionTooltip')">
        <TextField
          :value="attribute.description"
          name="attribute-description"
          type="text"
          :label="$t('general.description')"
          @input="value => change('description', value)"
        />
      </ChecFormField>
      <span v-if="errors.description" class="input-wrapper__error">
        {{ errors.description }}
      </span>
    </div>
  </ChecCard>
</template>

<script>
import {
  ChecDropdown,
  ChecCard,
  ChecFormField,
  ChecHeader,
  TextField,
  ChecSwitch,
} from '@chec/ui-library';
import fieldTypes from '../lib/attributeTypes';
import DefaultValueSelector from './DefaultValueSelector.vue';

export default {
  name: 'DetailsCard',
  components: {
    ChecDropdown,
    ChecCard,
    ChecFormField,
    ChecHeader,
    TextField,
    ChecSwitch,
    DefaultValueSelector,
  },
  model: {
    prop: 'attribute',
    event: 'input',
  },
  props: {
    attribute: {
      type: Object,
      required: true,
    },
    errors: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    requiredLabel() {
      return this.attribute.required
        ? this.$t('general.required')
        : this.$t('general.notRequired');
    },
    /**
     * Returns a map of attributes types in a format that our dropdowns can consume
     *
     * @returns {object[]}
     */
    fieldTypes() {
      return fieldTypes.map((fieldType) => ({
        value: fieldType.value,
        label: fieldType.label,
      }));
    },
  },
  methods: {
    change(prop, value) {
      this.$emit('input', {
        ...this.attribute,
        [prop]: value,
      });
    },
  },
};
</script>
