<template>
  <tr class="standard-tax-rate-row">
    <td>
      {{ $t('settings.tax.countryTax') }}
    </td>
    <td>
      <TextField
        v-model.number="activeTaxZone.country_standard_rate"
        name="standard"
        type="number"
        step=".0001"
      >
        <span class="standard-tax-rate-row__percentage">%</span>
      </TextField>
    </td>
    <td>
      <TextField
        v-model.number="activeTaxZone.country_digital_rate"
        name="digital"
        type="number"
        step=".0001"
      >
        <span class="standard-tax-rate-row__percentage">%</span>
      </TextField>
    </td>
  </tr>
</template>

<script>
import {
  TextField,
} from '@chec/ui-library';

export default {
  name: 'StandardTaxRow',
  components: {
    TextField,
  },
  props: {
    activeZone: Object,
  },
  data() {
    return {
      activeTaxZone: this.activeZone,
    };
  },
  watch: {
    activeZone() {
      this.activeTaxZone = this.activeZone;
    },
  },
};
</script>

<style lang="scss">
.standard-tax-rate-row {
  &__percentage {
    @apply text-lg;
  }
}
</style>
