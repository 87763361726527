<template>
  <TableRow
    class="api-key-row"
    @click="editKey(apiKey)"
  >
    <td>
      <ChecDataPill
        :color="apiKey.is_sandbox ? 'orange' : 'green' "
      >
        {{ statusCopy }}
      </ChecDataPill>
    </td>
    <td>
      <ChecDataPill
        :color="apiKey.type === 'public' ? 'blue' : 'red' "
      >
        {{ apiKey.type }}
      </ChecDataPill>
    </td>
    <td>
      {{ apiKey.label ? apiKey.label : $t('general.notAvailable') }}
    </td>
    <td>
      <div v-if="apiKey.key" class="api-key-row__api-key-container">
        <CopyInline
          class="api-key-row__inline"
          :copy-content="apiKey.key || ''"
        >
          <div
            :class="{'api-key-row__api-key--secret': apiKey.type !== 'public'}"
            class="api-key-row__api-key"
          >
            {{ apiKey.key }}
          </div>
        </CopyInline>
      </div>
      <CopyButton
        class="api-key-row__copy-button"
        :copy-content="apiKey.key || ''"
        color="primary"
      />
    </td>
    <td class="api-key-row__version">
      {{ apiKey.api_version ? apiKey.api_version : currentApiVersion }}
    </td>
    <td>
      <ChecOptionsMenu class="dashboard-table__actions-button">
        <ChecOption
          @option-selected="() => editKey(apiKey)"
        >
          {{ $t('general.edit') }}
        </ChecOption>
        <ChecOption destructive @option-selected="emitDelete(apiKey)">
          {{ $t('general.delete') }}
        </ChecOption>
      </ChecOptionsMenu>
    </td>
  </TableRow>
</template>

<script>
import {
  ChecDataPill,
  ChecOption,
  ChecOptionsMenu,
} from '@chec/ui-library';
import CopyInline from '@/components/CopyInline.vue';
import CopyButton from '@/components/CopyButton.vue';
import TableRow from '@/components/DashboardTable/TableRow.vue';
import apiVersions from '../../../../../content/versions.json';

export default {
  name: 'ApiKeyRow',
  components: {
    TableRow,
    ChecDataPill,
    ChecOption,
    ChecOptionsMenu,
    CopyButton,
    CopyInline,
  },
  props: {
    apiKey: Object,
  },
  computed: {
    statusCopy() {
      if (this.apiKey.is_sandbox) {
        return this.$t('settings.developerSettings.sandbox');
      }
      return this.$t('settings.developerSettings.live');
    },
    currentApiVersion() {
      return apiVersions[0].name;
    },
  },
  methods: {
    emitDelete(apiKey) {
      this.$emit('delete-api-key', apiKey);
    },
    editKey(apiKey) {
      this.$emit('edit-api-key', apiKey);
    },
  },
};
</script>

<style lang="scss">
.api-key-row {
  @apply h-full;

  &__api-key-container {
    @apply hidden items-center border border-gray-300 p-2 bg-gray-100 rounded;

    @screen md {
      @apply block;

      min-width: 240px;
    }

    @screen lg {
      @apply block;

      min-width: 450px;
    }
  }

  &__api-key {
    @apply font-mono text-xs select-all break-all;

    &--secret {
      @apply transition-all duration-200 ease-linear;

      filter: blur(0.2rem);

      &:hover {
        filter: blur(0);
      }
    }
  }

  &__copy-button {
    @apply inline-block;

    @screen md {
      @apply hidden;
    }
  }

  &__version {
    @apply caps-xs text-gray-500;
  }
}

</style>
