<template>
  <ChecCard
    class="avatar-card"
    tailwind="p-4"
    inner-class="digital-fulfillment-card"
  >
    <ChecHeader
      variant="card"
      :title="$t('general.logo')"
    />
    <ChecImageManager
      v-model="files"
      class="avatar-card__dropzone"
      :normalise-file="normaliseAsset"
      :endpoint="resolveEndpoint"
      :accept-successful-upload="completeUpload"
      accepted-files="image/jpeg,image/png,image/gif,image/jpg"
      :footnote="footnote"
      :max-files="1"
      :columns="2"
    />
  </ChecCard>
</template>

<script>
import {
  ChecCard,
  ChecImageManager,
  ChecHeader,
} from '@chec/ui-library';
import upload from '@/mixins/upload';

export default {
  name: 'LogoCard',
  components: {
    ChecCard,
    ChecImageManager,
    ChecHeader,
  },
  mixins: [upload],
  model: {
    prop: 'image',
    event: 'change',
  },
  props: {
    image: {
      type: [Object, Array],
    },
  },
  computed: {
    files: {
      get() {
        if (this.image === null) {
          return [];
        }

        return [this.image];
      },
      set(value) {
        if (Array.isArray(value) && value.length > 0) {
          this.$emit('change', value[0]);
          return;
        }
        this.$emit('change', null);
      },
    },
    footnote() {
      return this.$tc('product.images.types', 3, {
        types: 'PNG, JPG',
        lastType: 'GIF',
      });
    },
  },
};
</script>

<style lang="scss">
.avatar-card {
  &__dropzone {
    @apply mt-4;
  }

  &__logo-shape {
    @apply flex justify-between pt-4;
  }
}
</style>
