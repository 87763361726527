<template>
  <div class="extra-fields-table">
    <DashboardTable
      class="extra-fields-table__table"
      :align-right-after="4"
      :columns="[
        { title: $t('general.name'), class: 'extra-fields-table__row-header-name' },
        { title: $t('general.id') },
        { title: $t('general.required') },
        { title: $t('general.type') },
        { title: $t('general.actions') },
      ]"
      state-key="settings/extraFields"
    >
      <ExtraFieldRow
        v-for="extraField in extraFields"
        :key="extraField.id"
        :extra-field="extraField"
        @edit-extra-field="handleEditExtraField"
        @delete-extra-field="handleDeleteExtraField"
      />
    </DashboardTable>
  </div>
</template>

<script>
import DashboardTable from '@/components/DashboardTable.vue';
import crud from '@/mixins/crud';
import confirm from '@/mixins/confirm';
import addNotification from '@/mixins/addNotification';
import ExtraFieldRow from './ExtraFieldRow.vue';

export default {
  name: 'ExtraFieldsTable',
  components: {
    DashboardTable,
    ExtraFieldRow,
  },
  mixins: [
    crud('settings/extraFields', true),
    addNotification,
    confirm,
  ],
  methods: {
    /**
     * Opens the add panel to add extra field
     */
    handleAddExtraField() {
      this.$emit('add-extra-field');
    },
    /**
     * Opens the edit panel to loads the active extra field
     */
    handleEditExtraField(extraFieldId) {
      this.$router.push({
        name: 'settings.extraFields.edit',
        params: { id: extraFieldId },
      });
    },
    /**
     * Deletes extra field
     */
    async handleDeleteExtraField(extraField) {
      // Handle confirm modal
      if (!await this.confirm(
        this.$t('general.areYouSure'),
        this.$t('extraFields.confirmDeleteExtraField'),
      )) {
        return;
      }

      // Use extra field delete CRUD
      this.delete(extraField.id).then(() => {
        this.addNotification(this.$t('extraFields.deleted'));
      }).catch((error) => {
        this.addNotification(this.$t('extraFields.deleteFailed'), 'error');
        throw error;
      });
    },
  },
};
</script>

<style lang="scss">
.extra-fields-table {
  &__row-header-name {
    @apply w-1/2;
  }
}
</style>
