import i18n from '../lang';

export default [
  {
    value: 'text',
    label: i18n.t('general.text'),
  },
  {
    value: 'number',
    label: i18n.t('general.number'),
  },
  {
    value: 'date',
    label: i18n.t('general.date'),
  },
  {
    value: 'checkbox',
    label: i18n.t('general.checkbox'),
  },
  {
    value: 'radio',
    label: i18n.t('general.radio'),
  },
  {
    value: 'options',
    label: i18n.t('general.options'),
  },
  {
    value: 'hidden',
    label: i18n.t('general.hidden'),
  },
];
