/**
 * Supported regional tax defaults for Canada.
 */
export default [
  {
    region_code: 'AB',
    standard_rate: 0,
    digital_rate: 0,
    calculate: 'add',
  },
  {
    region_code: 'BC',
    standard_rate: 7,
    digital_rate: 0,
    calculate: 'add',
  },
  {
    region_code: 'MB',
    standard_rate: 7,
    digital_rate: 0,
    calculate: 'add',
  },
  {
    region_code: 'NB',
    standard_rate: 15,
    digital_rate: 0,
    calculate: 'override',
  },
  {
    region_code: 'NL',
    standard_rate: 15,
    digital_rate: 0,
    calculate: 'override',
  },
  {
    region_code: 'NS',
    standard_rate: 15,
    digital_rate: 0,
    calculate: 'override',
  },
  {
    region_code: 'NT',
    standard_rate: 0,
    digital_rate: 0,
    calculate: 'add',
  },
  {
    region_code: 'NU',
    standard_rate: 0,
    digital_rate: 0,
    calculate: 'add',
  },
  {
    region_code: 'ON',
    standard_rate: 13,
    digital_rate: 0,
    calculate: 'override',
  },
  {
    region_code: 'PE',
    standard_rate: 15,
    digital_rate: 0,
    calculate: 'override',
  },
  {
    region_code: 'QC',
    standard_rate: 9.975,
    digital_rate: 0,
    calculate: 'add',
  },
  {
    region_code: 'SK',
    standard_rate: 6,
    digital_rate: 0,
    calculate: 'add',
  },
  {
    region_code: 'YT',
    standard_rate: 0,
    digital_rate: 0,
    calculate: 'add',
  },
];
