<template>
  <div class="us-nexus-tax">
    <ChecAlert
      show-icon
      inline
      disable-close
      variant="info"
      class="us-nexus-tax__help-notice"
    >
      <span>
        {{ $t('settings.tax.nexusHelp') }}
        <!-- TODO Add link for the following "learn more" -->
        <!-- <a href="">{{ $t('general.learnMore') }}</a> -->
      </span>
    </ChecAlert>
    <DashboardHeader
      header-tag="h3"
      margin="small"
      :title="$t('settings.tax.nexusStates')"
    />
    <ChecTable
      class="us-nexus-tax-table"
      inner-class="us-nexus-tax-table__table"
      snug
    >
      <template #prepend>
        <div class="input-wrapper">
          <form class="us-nexus-tax-table__add-state" @submit.prevent="handleAddState">
            <TextField
              v-model="newZip"
              class="us-nexus-tax-table__add-state-input"
              name="zip_code"
              required
              :label="$t('address.zipCode')"
              :variant="addingState ? 'disabled' : (invalidZip || zipExists ? 'error' : '')"
            />
            <ChecButton
              button-type="submit"
              color="primary"
              :disabled="addingState || invalidZip || zipExists"
            >
              {{ addingState ? $t('general.adding') : $t('settings.tax.addState') }}
            </ChecButton>
          </form>
          <span v-if="invalidZip || zipExists" class="input-wrapper__error">
            {{ zipExists ? $t('settings.tax.zipAlreadyExists') : $t('settings.tax.zipInvalid') }}
          </span>
        </div>
      </template>
      <thead>
        <tr>
          <th>
            {{ $t('general.state') }}
          </th>
          <th>
            {{ $t('general.region') }}
          </th>
          <th>
            {{ $t('address.zipCode') }}
          </th>
          <th class="us-nexus-tax-table__options-header">
            {{ $t('general.options') }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-if="!states.length">
          <td colspan="4">
            {{ $t('general.empty') }}
          </td>
        </tr>
        <tr
          v-for="{ state, town_city: city, zipcode, deleted } in states"
          v-else
          :key="zipcode"
          :class="{ 'us-nexus-tax-table__row--deleted': deleted }"
        >
          <td>
            {{ stateNames[state] }}
          </td>
          <td>
            {{ city }}
          </td>
          <td>
            {{ zipcode }}
          </td>
          <td>
            <ChecButton
              v-if="deleted"
              class="variant-group-row__cancel"
              variant="round"
              color="primary"
              outline
              @click="cancelDelete(zipcode)"
            >
              {{ $t('general.cancel') }}
            </ChecButton>
            <ChecOptionsMenu v-else class="us-nexus-tax-table__options">
              <ChecOption destructive @option-selected="() => handleDelete(zipcode)">
                {{ $t('general.delete') }}
              </ChecOption>
            </ChecOptionsMenu>
          </td>
        </tr>
      </tbody>
    </ChecTable>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import {
  ChecAlert,
  ChecButton,
  ChecOption,
  ChecOptionsMenu,
  ChecTable,
  TextField,
} from '@chec/ui-library';
import DashboardHeader from '@/components/DashboardHeader.vue';
import stateNames from '../../data/tax/us-states.json';
import { actions } from '../../store/tax';

export default {
  name: 'USNexusTable',
  components: {
    ChecAlert,
    ChecButton,
    ChecOption,
    ChecOptionsMenu,
    ChecTable,
    DashboardHeader,
    TextField,
  },
  props: {
    deletedZipcodes: Array,
    existingStates: Array,
    newStates: Array,
  },
  data() {
    return {
      addingState: false,
      invalidZip: false,
      zipExists: false,
      newZip: '',
      stateNames,
    };
  },
  computed: {
    states() {
      // Add existing and newly added states, and add in the current (transient) deleted state
      return [
        ...(this.existingStates || []),
        ...(this.newStates || []),
      ].map((state) => ({
        ...state,
        deleted: this.isDeleted(state.zipcode),
      }));
    },
  },
  watch: {
    newZip() {
      // Clear validation errors from the field when the value is changed
      this.invalidZip = false;
      this.zipExists = false;
    },
  },
  methods: {
    ...mapActions('settings/tax', { loadZipcode: actions.FETCH_US_ZIPCODE }),
    cancelDelete(zipcode) {
      this.$emit('cancel-delete', zipcode);
    },
    async handleAddState() {
      if (this.states.find(({ zipcode }) => zipcode === this.newZip)) {
        this.zipExists = true;
        return;
      }

      this.addingState = true;
      const result = await this.loadZipcode(this.newZip);
      this.addingState = false;

      if (!result) {
        this.invalidZip = true;
        return;
      }

      this.newZip = '';
      this.$emit('add', result);
    },
    handleDelete(zipcode) {
      this.$emit('delete', zipcode);
    },
    isDeleted(zipcode) {
      return this.deletedZipcodes.includes(zipcode);
    },
  },
};
</script>

<style lang="scss">
.us-nexus-tax {
  &__help-notice {
    @apply my-4;
  }
}

.us-nexus-tax-table {
  &__table {
    @apply w-full;

    tr > *:nth-child(n+4) {
      @apply text-right;
    }
  }

  &__add-state {
    @apply flex;
  }

  &__add-state-input {
    @apply flex-grow mr-2;
  }

  &__options-header {
    @apply w-24;
  }

  &__options {
    @apply inline-block;
  }

  &__row {
    &--deleted {
      > *:nth-child(-n+3) {
        @apply opacity-50;
      }
    }
  }

  .input-wrapper {
    @apply mb-0;

    &__error {
      @apply pb-0;
    }
  }
}
</style>
