<template>
  <ChecCard inner-class="merchant-delete" tailwind="p-4">
    <DashboardHeader
      variant="card"
      margin="small"
      :title="$t('merchant.deleteMerchant')"
    >
      <ChecButton
        color="red"
        variant="round"
        class="account__save"
        :disabled="saving"
        @click="() => $emit('delete-merchant')"
      >
        {{ deleteLabel }}
      </ChecButton>
    </DashboardHeader>
    <ChecAlert
      variant="warning"
      inline
      show-icon
      disable-close
      class="merchant-delete__alert-message"
    >
      {{ $t('merchant.deleteMessage') }}
    </ChecAlert>
  </ChecCard>
</template>

<script>
import {
  ChecButton,
  ChecAlert,
  ChecCard,
} from '@chec/ui-library';
import DashboardHeader from '@/components/DashboardHeader.vue';

export default {
  name: 'DeleteMerchantCard',
  components: {
    ChecButton,
    ChecAlert,
    ChecCard,
    DashboardHeader,
  },
  props: {
    saving: Boolean,
  },
  computed: {
    /**
     * The label shown for the "delete" button
     *
     * @returns {string}
     */
    deleteLabel() {
      return this.saving ? this.$t('general.deleting') : this.$t('merchant.deleteMerchant');
    },
  },
};
</script>
