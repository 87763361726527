import cryptoRandomString from 'crypto-random-string';

/**
 * Generate a random 10 character alphanumeric discount code. Note that this will not
 * validate whether the discount code is available to be used, so the API may still
 * reply saying it is in use.
 *
 * @returns {string} The code
 */
export default () => cryptoRandomString({ length: 10 }).toUpperCase();
