import { formatCurrency } from '@/lib/locale';

export default (money) => ({
  full() {
    return formatCurrency(this.raw(), this.currency());
  },
  raw() {
    return money.raw;
  },
  currency() {
    return {
      // Trim numbers and formatting from the front, leaving just the ISO code.
      code: money.formatted_with_code.replace(/^[\s,.\d]+/, ''),
      // Remove everything from the first digit onwards, leaving just the symbol
      symbol: money.formatted_with_symbol.replace(/\d.*$/, ''),
    };
  },
  full_with_currency() {
    return formatCurrency(this.raw(), this.currency(), 'code');
  },
});
