<template>
  <form v-if="!isLoading" class="developer-cors" @submit.prevent>
    <DashboardHeader :title="$t('developer.crossOriginResourceSharing')" />
    <div class="developer-cors__content space-y-4 lg:space-y-0">
      <div class="developer-cors__main space-y-4">
        <CorsSettings
          v-model="merchantEditable"
          :errors="validationErrors"
        />
      </div>
      <div class="developer-cors__side-bar space-y-4">
        <ActionsCard
          :saving="isSaving"
          @save-merchant="handleSaveMerchant"
        />
      </div>
    </div>
  </form>
</template>

<script>
import { mapGetters, mapMutations, mapState } from 'vuex';
import dot from 'dot-object';
import addNotification from '@/mixins/addNotification';
import crud from '@/mixins/crud';
import DashboardHeader from '@/components/DashboardHeader.vue';
import ActionsCard from '@/modules/settings/components/merchant/ActionsCard.vue';
import mutations from '@/store/mutations';
import validateSchemaRequest from '@/lib/helpers/validateSchemaRequestHelper';
import CorsSettings from './CorsSettings.vue';
import merchantCorsSchema from '../../schemas/merchantCors';

export default {
  name: 'Cors',
  components: {
    ActionsCard,
    CorsSettings,
    DashboardHeader,
  },
  mixins: [
    addNotification,
    crud('merchant'),
  ],
  data() {
    return {
      merchantEditable: {
        cors_domains: [],
        id: null,
      },
      isSaving: false,
      validationErrors: {},
    };
  },
  computed: {
    ...mapState('merchant', ['merchant']),
    ...mapGetters(['isLoading']),
  },
  created() {
    this.merchantEditable = this.merchant;
  },
  methods: {
    ...mapMutations('merchant', { setMerchant: mutations.SET_MERCHANT }),
    /**
     * Validate then save the merchant.
     */
    handleSaveMerchant() {
      this.validationErrors = {};
      this.isSaving = true;
      validateSchemaRequest(
        merchantCorsSchema,
        {
          id: this.merchantEditable.id,
          cors_domains: this.merchantEditable.cors_domains,
        },
        { abortEarly: false },
      )
        .then((validatedData) => this.update(this.merchant.id, validatedData))
        .then((merchant) => {
          this.setMerchant({ merchant });
          this.addNotification(this.$t('settings.merchant.saved'));
        })
        .catch((error) => {
          if (error.name === 'ValidationError') { // yup schema validation error
            this.validationErrors = dot.object(error.errors);
            return;
          }
          this.addNotification(
            this.$t('settings.merchant.saveFailed'),
            'error',
          );
        })
        .finally(() => { this.isSaving = false; });
    },
  },
};
</script>

<style lang="scss">
.developer-cors {
  @apply mt-8;

  // todo centralise these styles somewhere and implement in settings/views/merchant.vue too
  &__side-bar {
    @screen lg {
      @apply ml-8 w-1/3 max-w-sm flex-shrink-0;
    }
  }
  @screen lg {
    &__content {
      @apply flex flex-row;
    }

    &__main {
      @apply flex-grow;
    }
  }
}
</style>
