<template>
  <div>
    <InnerBlock
      :title="$t('billing.paymentMethod')"
      :action-color="details.has_payment_method ? undefined: 'red'"
      :action-text="details.has_payment_method ? $t('billing.updateCard') : $t('billing.addCard')"
      class="payment-method-section"
      @action="openPaymentModal"
    >
      <span v-if="details.has_payment_method" class="payment-method-section__method">
        {{ details.payment_method.brand }} <strong>{{ details.payment_method.last4 }}</strong>
      </span>
      <template v-else>
        {{ $t('billing.noPaymentMethod') }}
      </template>
    </InnerBlock>
    <PaymentMethodModal v-if="paymentModalOpen" @close="closePaymentModal" />
  </div>
</template>

<script>
import { InnerBlock } from '@chec/ui-library';
import { mapState } from 'vuex';
import PaymentMethodModal from './PaymentMethodModal.vue';

export default {
  name: 'PaymentMethodSection',
  components: { PaymentMethodModal, InnerBlock },
  data() {
    return {
      paymentModalOpen: false,
    };
  },
  computed: {
    ...mapState('billing', ['details']),
  },
  methods: {
    openPaymentModal() {
      this.paymentModalOpen = true;
    },
    closePaymentModal() {
      this.paymentModalOpen = false;
    },
  },
};
</script>

<style lang="scss">
.payment-method-section {
  &__method {
    @apply capitalize;
  }
}
</style>
