<template>
  <TableRow
    class="tax-zone-row"
    @click="editTaxZone(zone)"
  >
    <td>
      {{ activeCountryName }}
    </td>
    <td>
      {{ standardRate }}
    </td>
    <td>
      {{ digitalRate }}
    </td>
    <td>
      <ChecOptionsMenu class="tax-zone-row__actions-button">
        <ChecOption
          @option-selected="() => editTaxZone(zone)"
        >
          {{ $t('general.edit') }}
        </ChecOption>
        <ChecOption destructive @option-selected="emitDelete">
          {{ $t('general.delete') }}
        </ChecOption>
      </ChecOptionsMenu>
    </td>
  </TableRow>
</template>

<script>
import {
  ChecOption,
  ChecOptionsMenu,
} from '@chec/ui-library';
import TableRow from '@/components/DashboardTable/TableRow.vue';

export default {
  name: 'ZoneRow',
  components: {
    TableRow,
    ChecOption,
    ChecOptionsMenu,
  },
  model: {
    prop: 'variant',
    event: 'change',
  },
  props: {
    zone: Object,
    countries: Object,
  },
  computed: {
    activeCountryName() {
      return this.countries[this.zone.country_code];
    },
    digitalRate() {
      if (this.zone.calculate_automatically === true) {
        return this.$t('general.auto');
      }
      return `${this.zone.country_digital_rate
        .toString()
        .replace(/^0+(\d)|(\d)0+$/gm, '$1$2')}%`;
    },
    standardRate() {
      if (this.zone.calculate_automatically === true) {
        return this.$t('general.auto');
      }
      return `${this.zone.country_standard_rate
        .toString()
        .replace(/^0+(\d)|(\d)0+$/gm, '$1$2')}%`;
    },
  },
  methods: {
    emitDelete() {
      this.$emit('delete');
    },
    editTaxZone() {
      this.$emit('edit-tax-zone', this.zone);
    },
  },
};
</script>

<style lang="scss">
.tax-zone-row {
  @apply h-full;

  &__actions-button {
    @apply flex justify-end;
  }
}
</style>
