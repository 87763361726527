<template>
  <TableRow
    :id="attribute.id"
    class="attribute-row"
    :route-name="attribute.id ? 'settings.attributes.edit' : null"
  >
    <td>
      {{ attribute.name }}
    </td>
    <td>
      <div v-if="attribute.id" class="attribute-row__attribute-id-container">
        <CopyInline
          class="attribute-row__inline"
          :copy-content="attribute.id || ''"
        >
          <div
            class="attribute-row__attribute-id"
          >
            {{ attribute.id }}
          </div>
        </CopyInline>
      </div>
    </td>
    <td class="attribute-row__type">
      {{ attribute.type.replace('_', ' ') }}
    </td>
    <td>
      <ChecDataPill :color="attribute.is_public ? 'blue' : 'orange'">
        {{
          attribute.is_public
            ? $t('developer.keyUsed.public')
            : $t('developer.keyUsed.secret')
        }}
      </ChecDataPill>
    </td>
    <td>
      <ChecDataPill :color="attribute.required ? 'green' : 'gray'">
        {{ attribute.required ? $t('general.yes') : $t('general.no') }}
      </ChecDataPill>
    </td>
    <td>
      <ChecOptionsMenu menu-placement="bottom-end" class="dashboard-table__actions-button">
        <ChecOption
          @option-selected="handleEdit"
        >
          {{ $t('general.edit') }}
        </Checoption>
        <ChecOption
          destructive
          @option-selected="handleDelete"
        >
          {{ $t('general.delete') }}
        </ChecOption>
      </ChecOptionsMenu>
    </td>
  </TableRow>
</template>

<script>
import {
  ChecDataPill,
  ChecOption,
  ChecOptionsMenu,
} from '@chec/ui-library';
import TableRow from '@/components/DashboardTable/TableRow.vue';
import CopyInline from '@/components/CopyInline.vue';

export default {
  name: 'AttributeRow',
  components: {
    TableRow,
    ChecDataPill,
    ChecOption,
    ChecOptionsMenu,
    CopyInline,
  },
  props: {
    attribute: Object,
  },
  methods: {
    handleEdit() {
      this.$emit('edit-attribute', this.attribute.id);
    },
    handleDelete() {
      this.$emit('delete-attribute', this.attribute.id);
    },
  },
};
</script>

<style lang="scss">
.attribute-row {
  &__type {
    @apply capitalize;
  }

  &__attribute-id-container {
    @apply inline-block items-center border border-gray-300 p-2 bg-gray-100 rounded;
  }

  &__attribute-id {
    @apply font-mono text-xs select-all break-all;
  }
}
</style>
