/**
 * Supported regional tax defaults for the United States.
 */
export default [
  {
    region_code: 'AK',
    standard_rate: 0,
    digital_rate: 0,
    calculate: 'override',
  },
  {
    region_code: 'AL',
    standard_rate: 4.00,
    digital_rate: 0,
    calculate: 'override',
  },
  {
    region_code: 'AR',
    standard_rate: 6.50,
    digital_rate: 0,
    calculate: 'override',
  },
  {
    region_code: 'AS',
    standard_rate: 15.00,
    digital_rate: 0,
    calculate: 'override',
  },
  {
    region_code: 'AZ',
    standard_rate: 5.600,
    digital_rate: 0,
    calculate: 'add',
  },
  {
    region_code: 'CA',
    standard_rate: 7.25,
    digital_rate: 0,
    calculate: 'override',
  },
  {
    region_code: 'CO',
    standard_rate: 2.90,
    digital_rate: 0,
    calculate: 'override',
  },
  {
    region_code: 'CT',
    standard_rate: 6.35,
    digital_rate: 0,
    calculate: 'override',
  },
  {
    region_code: 'DC',
    standard_rate: 6.00,
    digital_rate: 0,
    calculate: 'override',
  },
  {
    region_code: 'DE',
    standard_rate: 0,
    digital_rate: 0,
    calculate: 'override',
  },
  {
    region_code: 'FL',
    standard_rate: 6.00,
    digital_rate: 0,
    calculate: 'override',
  },
  {
    region_code: 'GA',
    standard_rate: 4.00,
    digital_rate: 0,
    calculate: 'override',
  },
  {
    region_code: 'GU',
    standard_rate: 2.00,
    digital_rate: 0,
    calculate: 'override',
  },
  {
    region_code: 'HI',
    standard_rate: 4.00,
    digital_rate: 0,
    calculate: 'override',
  },
  {
    region_code: 'IA',
    standard_rate: 6.00,
    digital_rate: 0,
    calculate: 'override',
  },
  {
    region_code: 'ID',
    standard_rate: 6.00,
    digital_rate: 0,
    calculate: 'override',
  },
  {
    region_code: 'IL',
    standard_rate: 6.25,
    digital_rate: 0,
    calculate: 'override',
  },
  {
    region_code: 'IN',
    standard_rate: 7.00,
    digital_rate: 0,
    calculate: 'override',
  },
  {
    region_code: 'KS',
    standard_rate: 6.50,
    digital_rate: 0,
    calculate: 'override',
  },
  {
    region_code: 'KY',
    standard_rate: 6.00,
    digital_rate: 0,
    calculate: 'override',
  },
  {
    region_code: 'LA',
    standard_rate: 4.45,
    digital_rate: 0,
    calculate: 'override',
  },
  {
    region_code: 'MA',
    standard_rate: 6.25,
    digital_rate: 0,
    calculate: 'override',
  },
  {
    region_code: 'MD',
    standard_rate: 6.00,
    digital_rate: 0,
    calculate: 'override',
  },
  {
    region_code: 'ME',
    standard_rate: 5.50,
    digital_rate: 0,
    calculate: 'override',
  },
  {
    region_code: 'MI',
    standard_rate: 6.00,
    digital_rate: 0,
    calculate: 'override',
  },
  {
    region_code: 'MN',
    standard_rate: 6.875,
    digital_rate: 0,
    calculate: 'override',
  },
  {
    region_code: 'MO',
    standard_rate: 4.225,
    digital_rate: 0,
    calculate: 'override',
  },
  {
    region_code: 'MP',
    standard_rate: 0,
    digital_rate: 0,
    calculate: 'override',
  },
  {
    region_code: 'MS',
    standard_rate: 7.00,
    digital_rate: 0,
    calculate: 'override',
  },
  {
    region_code: 'MT',
    standard_rate: 0,
    digital_rate: 0,
    calculate: 'override',
  },
  {
    region_code: 'NC',
    standard_rate: 4.75,
    digital_rate: 0,
    calculate: 'override',
  },
  {
    region_code: 'ND',
    standard_rate: 5.00,
    digital_rate: 0,
    calculate: 'override',
  },
  {
    region_code: 'NE',
    standard_rate: 5.50,
    digital_rate: 0,
    calculate: 'override',
  },
  {
    region_code: 'NH',
    standard_rate: 0,
    digital_rate: 0,
    calculate: 'override',
  },
  {
    region_code: 'NJ',
    standard_rate: 6.625,
    digital_rate: 0,
    calculate: 'override',
  },
  {
    region_code: 'NM',
    standard_rate: 5.125,
    digital_rate: 0,
    calculate: 'override',
  },
  {
    region_code: 'NV',
    standard_rate: 6.85,
    digital_rate: 0,
    calculate: 'override',
  },
  {
    region_code: 'NY',
    standard_rate: 4.00,
    digital_rate: 0,
    calculate: 'override',
  },
  {
    region_code: 'OH',
    standard_rate: 5.75,
    digital_rate: 0,
    calculate: 'override',
  },
  {
    region_code: 'OK',
    standard_rate: 4.50,
    digital_rate: 0,
    calculate: 'override',
  },
  {
    region_code: 'OR',
    standard_rate: 0,
    digital_rate: 0,
    calculate: 'override',
  },
  {
    region_code: 'PA',
    standard_rate: 6.00,
    digital_rate: 0,
    calculate: 'override',
  },
  {
    region_code: 'PR',
    standard_rate: 11.5,
    digital_rate: 0,
    calculate: 'override',
  },
  {
    region_code: 'RI',
    standard_rate: 7.00,
    digital_rate: 0,
    calculate: 'override',
  },
  {
    region_code: 'SC',
    standard_rate: 6.00,
    digital_rate: 0,
    calculate: 'override',
  },
  {
    region_code: 'SD',
    standard_rate: 4.50,
    digital_rate: 0,
    calculate: 'override',
  },
  {
    region_code: 'TN',
    standard_rate: 7.00,
    digital_rate: 0,
    calculate: 'override',
  },
  {
    region_code: 'TX',
    standard_rate: 6.25,
    digital_rate: 0,
    calculate: 'override',
  },
  {
    region_code: 'UM',
    standard_rate: 0,
    digital_rate: 0,
    calculate: 'override',
  },
  {
    region_code: 'UT',
    standard_rate: 6.10,
    digital_rate: 0,
    calculate: 'override',
  },
  {
    region_code: 'VA',
    standard_rate: 5.30,
    digital_rate: 0,
    calculate: 'override',
  },
  {
    region_code: 'VI',
    standard_rate: 4.00,
    digital_rate: 0,
    calculate: 'override',
  },
  {
    region_code: 'VT',
    standard_rate: 6.00,
    digital_rate: 0,
    calculate: 'override',
  },
  {
    region_code: 'WA',
    standard_rate: 6.50,
    digital_rate: 0,
    calculate: 'override',
  },
  {
    region_code: 'WI',
    standard_rate: 5.00,
    digital_rate: 0,
    calculate: 'override',
  },
  {
    region_code: 'WV',
    standard_rate: 6.00,
    digital_rate: 0,
    calculate: 'override',
  },
  {
    region_code: 'WY',
    standard_rate: 4.00,
    digital_rate: 0,
    calculate: 'override',
  },
];
