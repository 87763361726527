<template>
  <div class="logs-home">
    <DashboardHeader :title="$t('logs.logs')" />
    <IntroSplash
      v-if="showIntroSplash"
      :title="$t('logs.introSplash.title')"
    >
      {{ $t('logs.introSplash.description') }}
    </IntroSplash>
    <div v-else class="logs-home__content">
      <div class="logs-home__table-pagination">
        <LogsTable
          :logs="developerLogs"
          :loading="isLoading"
        />
        <DashboardPagination state-key="developerLogs" />
      </div>
      <LogEntry
        v-if="currentLog"
        :loading="isLoading"
        :log="currentLog"
      />
    </div>
    <i18n
      v-if="!isLoading"
      path="developer.cliNotice"
      tag="div"
      class="logs-home__cli-notice"
    >
      <template #cli>
        <a
          class="logs-home__cli-link"
          href="https://github.com/chec/cli"
          target="_blank"
        >
          {{ $t('developer.checCli') }}
        </a>
      </template>
      <template #cliCommand>
        <span><code>npm install -g @chec/cli</code></span>
      </template>
    </i18n>
    <router-view />
  </div>
</template>

<script>
import crud from '@/mixins/crud';
import DashboardHeader from '@/components/DashboardHeader.vue';
import DashboardPagination from '@/components/Pagination.vue';
import IntroSplash from '@/components/IntroSplash.vue';
import LogsTable from '../components/logs/LogsTable.vue';
import LogEntry from '../components/logs/LogEntry.vue';

export default {
  name: 'DeveloperLogs',
  components: {
    DashboardHeader,
    IntroSplash,
    LogsTable,
    LogEntry,
    DashboardPagination,
  },
  mixins: [
    crud('developerLogs', true),
  ],
  computed: {
    /**
     * Return the current log entry
     */
    currentLog() {
      if (!Array.isArray(this.developerLogs)) {
        return null;
      }

      // Is there one in the URL?
      const selectedLog = this.developerLogs.find((log) => log.id === this.$route.params.id);
      if (selectedLog) {
        return selectedLog;
      }

      // Is there a first item in the list?
      return this.developerLogs.find(() => true) || null;
    },
    /**
     * Show intro card section when there are no logs and when
     * page is not loading
     *
     * @returns {boolean}
     */
    showIntroSplash() {
      return this.totalItemCount === 0 && !this.isLoading && !this.currentSearchTerm;
    },
  },
};
</script>

<style lang="scss">
.logs-home {
  &__content {
    @apply mt-6 items-start w-full;

    @screen xl {
      @apply flex;
    }
  }

  &__table-pagination {
    @apply flex-grow;
  }

  &__cli-notice {
    @apply justify-center flex mt-12 text-center;

    code {
      @apply ml-1 py-1 px-2 bg-gray-200 rounded-sm font-mono tracking-wide text-gray-600;
    }
  }

  &__cli-link {
    @apply mx-1;
  }
}
</style>
