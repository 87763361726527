<template>
  <ChecCard
    class="analytics__card"
    inner-class="details-card"
  >
    <ChecAlert
      class="cors-settings__help"
      variant="info"
      inline
      disable-close
    >
      {{ $t('developer.corsDomainsHelp') }}
    </ChecAlert>

    <div class="input-wrapper__row">
      <div class="cors-settings__input-wrapper">
        <ChecTagsField
          :tags="merchant.cors_domains"
          placeholder="https://yourstore.com"
          add-on-blur
          :add-on-keys="[9, 13, 188]"
          :max-length="20"
          :variant="errors.cors_domains ? 'error' : ''"
          @change="updateCorsDomains"
        />
        <span v-if="errors.cors_domains" class="input-wrapper__error">
          {{ errors.cors_domains[0] }}
        </span>
      </div>
    </div>
  </ChecCard>
</template>

<script>
import {
  ChecAlert,
  ChecCard,
  ChecTagsField,
} from '@chec/ui-library';

export default {
  name: 'CorsSettings',
  components: {
    ChecAlert,
    ChecCard,
    ChecTagsField,
  },
  model: {
    prop: 'merchant',
    event: 'input',
  },
  props: {
    merchant: {
      type: Object,
      required: true,
    },
    errors: {
      type: Object,
      default: () => ({}),
    },
  },
  methods: {
    updateCorsDomains(newDomains) {
      // Make a copy of the current existing options
      const existingDomains = [
        ...(this.merchant.cors_domains || []),
      ];
      // Check to see if a domain was removed by checking
      // if the existing domains is more than the new domains
      if (existingDomains.length > newDomains.length) {
        // Find the index of the missing domain index
        // by checking that the new domains does not
        // include the domain
        const missingOptionIndex = existingDomains.findIndex(
          (candidate) => !newDomains.includes(candidate),
        );
        // Remove the domain at the index position by 1
        existingDomains.splice(missingOptionIndex, 1);
        // Emit the change event to update the domains list
        this.$emit('input', {
          ...this.merchant,
          cors_domains: existingDomains,
        });
        return;
      }
      // Check to see if a new domain was added by checking
      // if the existing domains is less than the new domains
      if (existingDomains.length < newDomains.length) {
        // Return the new domain that is not included
        // in the current list of domains
        const newOption = newDomains.find(
          (candidate) => !(this.merchant.cors_domains || []).includes(candidate),
        );
        // Emit change by updating the existing domains
        this.$emit('input', {
          ...this.merchant,
          cors_domains: [
            ...existingDomains,
            newOption,
          ],
        });
      }
    },
  },
};
</script>

<style lang="scss">
.cors-settings {
  &__input-wrapper {
    @apply w-full;
  }

  &__help {
    @apply mb-4;
  }
}
</style>
