<template>
  <ChecCard
    class="merchant-id__card"
    inner-class="details-card"
  >
    <ChecHeader
      variant="card"
      :title="$t('settings.merchant.merchantId')"
    />
    <!-- Message variant is used here as the alert doesn't actually look like an alert in the UI -->
    <ChecAlert
      class="merchant-id__info"
      variant="message"
      inline
      disable-close
    >
      <span class="merchant-id__id">
        #{{ merchantId }}
      </span>
      <CopyButton
        class="merchant-id__copy-button"
        color="primary"
        :copy-content="merchantId.toString()"
      />
    </ChecAlert>
    <p class="merchant-id__copy">
      {{ $t('settings.merchant.merchantIdInfo') }}
    </p>
  </ChecCard>
</template>

<script>
import {
  ChecAlert,
  ChecCard,
  ChecHeader,
} from '@chec/ui-library';
import CopyButton from '@/components/CopyButton.vue';

export default {
  name: 'MerchantID',
  components: {
    ChecAlert,
    CopyButton,
    ChecCard,
    ChecHeader,
  },
  props: {
    merchantId: {
      type: Number,
      required: true,
    },
  },
};
</script>

<style lang="scss">
.merchant-id {
  &__info {
    .alert__text {
      @apply flex justify-between items-center;
    }
  }

  &__id {
    @apply text-xl font-mono;
  }

  &__copy {
    @apply text-sm pt-4;
  }
}
</style>
