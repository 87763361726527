export default {
  methods: {
    getPlanIconName(planName = '', returnCircleVersion = false) {
      const mappedPlanIcons = {
        standard: ['standard', 'standardcircle'],
        plus: ['plusplan', 'pluscircle'],
        pro: ['pro', 'procircle'],
        rise: ['rise', 'risecircle'],
        growth: ['growth', 'growthcircle'],
        enterprise: ['enterprise', 'enterprisecircle'],
        developer: ['enterprise', 'enterprisecircle'],
        sandbox: ['enterprise', 'enterprisecircle'],
      };
      return mappedPlanIcons[planName.toLowerCase()]
        && mappedPlanIcons[planName.toLowerCase()][returnCircleVersion ? 1 : 0];
    },
  },
};
