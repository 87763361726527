<template>
  <form class="extra-fields-settings" @submit.prevent>
    <DashboardHeader :title="$t('extraFields.extraFields')">
      <ChecButton
        variant="round"
        color="primary"
        icon="plus"
        @click="handleAddExtraField"
      >
        {{ $t('general.add') }}
      </ChecButton>
    </DashboardHeader>
    <IntroSplash
      v-if="showIntro"
      :title="$t('extraFields.introTitle')"
      button
      @click="handleAddExtraField"
    >
      {{ $t('extraFields.introDescription') }}
      <a
        href="http://support.commercejs.com/en/articles/5445627-adding-extra-fields"
        target="_blank"
        rel="noopener noreferrer"
        class="extra-fields-settings__learn-more"
      >
        {{ $t('general.learnMore') }}
      </a>
    </IntroSplash>
    <ExtraFieldsTable v-else />
    <Pagination
      state-key="settings/extraFields"
    />
    <router-view />
  </form>
</template>

<script>
import { ChecButton } from '@chec/ui-library';
import crud from '@/mixins/crud';
import IntroSplash from '@/components/IntroSplash.vue';
import Pagination from '@/components/Pagination.vue';
import DashboardHeader from '@/components/DashboardHeader.vue';
import ExtraFieldsTable from '../components/ExtraFieldsTable.vue';

export default {
  name: 'ExtraFieldsHome',
  components: {
    ExtraFieldsTable,
    IntroSplash,
    Pagination,
    ChecButton,
    DashboardHeader,
  },
  mixins: [crud('settings/extraFields', true)],
  computed: {
    /**
     * Show intro card section when there are no extra fields and when
     * page is not loading
     *
     * @returns {boolean}
     */
    showIntro() {
      if (this.extraFields.length === 0 && !this.isLoading) {
        return true;
      }
      return false;
    },
  },
  methods: {
    handleAddExtraField() {
      this.$router.push({ name: 'settings.extraFields.add' });
    },
  },
};
</script>

<style lang="scss">
.extra-fields-settings {
  &__title {
    @apply mb-8;
  }

  &__section {
    @apply w-full;

    @screen md {
      @apply w-4/6;
    }
  }

  &__section-container {
    @apply flex flex-col;

    @screen md {
      @apply flex-row;
    }
  }

  &__card-heading {
    @apply mb-4;
  }

  &__aside {
    @apply w-full;

    @screen md {
      @apply w-1/3;
    }
  }

  &__delete {
    @apply h-full mt-8 ml-0;

    @screen md {
      @apply ml-8 mt-0;
    }
  }

  &__delete-message {
    @apply mb-4 text-gray-500;
  }

  &__learn-more {
    @apply block mt-4;
  }
}
</style>
