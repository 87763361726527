<template>
  <ChecCard
    class="analytics__card"
    inner-class="details-card"
  >
    <ChecHeader
      variant="card"
      :title="$t('settings.merchant.analyticsCardTitle')"
    />
    <div class="input-wrapper__row space-x-2">
      <div class="analytics_input-wrapper w-4/12">
        <ChecFormField
          :tooltip="$t('settings.merchant.analyticsGaTrackingIdTooltip')"
          :append-label="$t('general.whatIsThis')"
        >
          <TextField
            :value="merchant.analytics.google.settings.tracking_id"
            name="GoogleAnalyticsTrackingID"
            :label="$t('settings.merchant.analyticsGaTrackingId')"
            @input="value => change('tracking_id', value)"
          />
          <span
            v-if="errors && errors['analytics.google.settings.tracking_id']"
            class="input-wrapper__error"
          >
            {{ errors['analytics.google.settings.tracking_id'] }}
          </span>
        </ChecFormField>
      </div>
      <div class="analytics_input-wrapper w-8/12">
        <ChecFormField
          :tooltip="$t('settings.merchant.analyticsLinkedDomainTooltip')"
          :append-label="$t('general.whatIsThis')"
        >
          <ChecTagsField
            :tags="merchant.analytics.google.settings.linked_domains"
            add-on-blur
            :add-on-keys="[9, 13, 188]"
            :max-length="20"
            :placeholder="$t('settings.merchant.analyticsLinkedDomain')"
            @change="updateDomains"
          />
          <span
            v-if="errors && errors['analytics.google.settings.linked_domains']"
            class="input-wrapper__error"
          >
            {{ errors['analytics.google.settings.linked_domains'] }}
          </span>
        </ChecFormField>
      </div>
    </div>
  </ChecCard>
</template>

<script>
import {
  ChecCard,
  ChecHeader,
  ChecFormField,
  ChecTagsField,
  TextField,
} from '@chec/ui-library';

export default {
  name: 'AnalyticsSettings',
  components: {
    ChecCard,
    ChecHeader,
    ChecFormField,
    ChecTagsField,
    TextField,
  },
  model: {
    prop: 'merchant',
    event: 'input',
  },
  props: {
    merchant: {
      type: Object,
      required: true,
    },
    errors: Object,
  },
  methods: {
    change(prop, value) {
      this.$emit('input', {
        ...this.merchant,
        analytics: {
          google: {
            settings: {
              ...this.merchant.analytics.google.settings,
              [prop]: value,
            },
          },
        },
      });
    },
    updateDomains(newDomains) {
      // Make a copy of the current existing options
      const existingDomains = [
        ...this.merchant.analytics.google.settings.linked_domains,
      ];
      // Check to see if a domain was removed by checking
      // if the existing domains is more than the new domains
      if (existingDomains.length > newDomains.length) {
        // Find the index of the missing domain index
        // by checking that the new domains does not
        // include the domain
        const missingOptionIndex = existingDomains.findIndex(
          (candidate) => !newDomains.includes(candidate),
        );
        // Remove the domain at the index position by 1
        existingDomains.splice(missingOptionIndex, 1);
        // Emit the change event to update the domains list
        this.$emit('input', {
          ...this.merchant,
          analytics: {
            google: {
              settings: {
                ...this.merchant.analytics.google.settings,
                linked_domains: existingDomains,
              },
            },
          },
        });
        return;
      }
      // Check to see if a new domain was added by checking
      // if the existing domains is less than the new domains
      if (existingDomains.length < newDomains.length) {
        // Return the new domain that is not included
        // in the current list of domains
        const newOption = newDomains.find(
          (candidate) => !this.merchant.analytics
            .google.settings.linked_domains.includes(candidate),
        );
        // Emit change by updating the existing domains
        this.$emit('input', {
          ...this.merchant,
          analytics: {
            google: {
              settings: {
                ...this.merchant.analytics.google.settings,
                linked_domains: [
                  ...existingDomains,
                  newOption,
                ],
              },
            },
          },
        });
      }
    },
  },
};
</script>

<style lang="scss">
.details-card {
  @apply p-4;

  .chec-header {
    @apply mb-4;
  }
}

.analytics {
  &__inventory-available {
    .text-field--disabled .text-field__right-content {
      @apply opacity-100; // by default this would be .5
    }
  }

  &__input-wrapper {
    @apply mb-2;
  }
}
</style>
