import i18n from '@/lang';
import {
  string, object, bool, array,
} from 'yup';

export default object().shape({
  name: string().required(i18n.t('validation.validBusinessName')),
  support_email: string().required(i18n.t('validation.validSupportEmail')),
  address: object({
    public: bool(),
    city: string().nullable().required(i18n.t('validation.validCity')),
    country: string().nullable().required(i18n.t('validation.validCountry')),
    postal_zip_code: string().nullable().required(i18n.t('validation.validPostalZipCode')),
    region: string().nullable().required(i18n.t('validation.validRegion')),
    street: string().nullable().required(i18n.t('validation.validStreet')),
  }),
  analytics: object({
    google: object({
      settings: object({
        tracking_id: string().nullable(),
        linked_domains: array().of(string()),
      }),
    }),
  }),
});
