<template>
  <div class="settings-container">
    <ApiKeysTable />
    <Cors />
  </div>
</template>

<script>
import ApiKeysTable from '../components/api-keys/ApiKeysTable.vue';
import Cors from '../components/cors/Cors.vue';

export default {
  name: 'Developer',
  components: {
    ApiKeysTable,
    Cors,
  },
};
</script>
