/**
 * Automatically load all card components in the card folder.
 */
const req = require.context('.', false, /\.vue$/);

const components = {};

req.keys().forEach((fileName) => {
  if (fileName === './index.js') return;
  const componentName = fileName.replace(/(\.\/|\.vue)/g, '');
  components[componentName] = req(fileName).default;
});

export default components;
