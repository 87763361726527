<template>
  <div>
    <TextField
      v-if="[
        'short_text',
        'long_text',
        'number',
      ].includes(fieldType)"
      :value="attribute.default_value"
      name="default-value"
      type="text"
      :multiline="fieldType === 'long_text'"
      :label="$t('attributes.defaultValue')"
      :variant="attribute.type === '' ? 'disabled' : errors.default_value ? 'error' : ''"
      @input="value => change(value)"
    />
    <ChecDatepicker
      v-else-if="fieldType === 'date'"
      :value="attribute.default_value"
      :label="$t('attributes.defaultValue')"
      name="default-value"
      @input="value => change(value)"
    />
    <ChecDropdown
      v-else-if="[
        'radio',
        'options',
        'checkbox',
      ].includes(fieldType)"
      :value="attribute.default_value"
      :label="$t('attributes.defaultValue')"
      name="default-value"
      :required="fieldType === 'checkbox'"
      :options="fieldOptions"
      @input="value => change(value)"
    />
    <TextField
      v-else
      name="attribute-empty"
      type="text"
      :label="$t('attributes.defaultValue')"
      :disabled="true"
      variant="disabled"
      @input="value => change(value)"
    />
  </div>
</template>

<script>
import {
  ChecDropdown,
  ChecDatepicker,
  TextField,
} from '@chec/ui-library';

export default {
  name: 'DefaultValueSelector',
  components: {
    ChecDropdown,
    ChecDatepicker,
    TextField,
  },
  props: {
    attribute: {
      type: Object,
      required: true,
    },
    errors: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      checkboxOptions: [
        {
          value: 'checked',
          label: this.$t('general.checked'),
        },
        {
          value: 'unchecked',
          label: this.$t('general.unchecked'),
        },
      ],
    };
  },
  computed: {
    fieldType() {
      return this.attribute.type;
    },
    fieldOptions() {
      if (this.fieldType === 'checkbox') {
        return this.checkboxOptions;
      }
      return this.attribute.options
        .filter((item) => item.label !== '' && item.value !== '')
        .map((item) => ({
          label: item.label,
          value: item.value,
        }));
    },
  },
  methods: {
    change(value) {
      this.$emit('set-default', value);
    },
  },
};
</script>
