<template>
  <ChecCard inner-class="prices-tax" tailwind="p-4">
    <ChecSwitch
      name="price_included"
      :toggled="tax.prices_include_tax"
      @input="handleChangePriceIcludedOption"
    >
      {{ $t('settings.tax.pricesIncludeTax') }}
    </ChecSwitch>
  </ChecCard>
</template>

<script>
import {
  ChecCard,
  ChecSwitch,
} from '@chec/ui-library';

export default {
  name: 'PricesIncludeTax',
  components: {
    ChecCard,
    ChecSwitch,
  },
  model: {
    prop: 'tax',
    event: 'input',
  },
  props: {
    tax: {
      type: Object,
    },
  },
  methods: {
    handleChangePriceIcludedOption(value) {
      this.$emit('input', {
        ...this.tax,
        prices_include_tax: value,
      });
    },
  },
};
</script>

<style lang="scss">
.prices-tax {
  @apply flex flex-col;

  &__rule {
    @apply border-gray-200 mt-4 mb-2;
  }
}
</style>
