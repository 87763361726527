<template>
  <ChecTable v-if="log" class="log-summary">
    <tbody>
      <tr>
        <td class="log-summary__label">
          {{ $t('developer.dateTime') }}
        </td>
        <td><Timestamp :timestamp="log.created" /></td>
      </tr>
      <tr>
        <td class="log-summary__label">
          {{ $t('developer.method') }}
        </td>
        <td>
          <p class="log-summary__method-text">
            {{ log.method }}
          </p>
        </td>
      </tr>
      <tr class="log-summary__url-row">
        <td class="log-summary__label">
          {{ $t('developer.url') }}
        </td>
        <td>
          <code>{{ log.url }}</code>
        </td>
      </tr>
      <tr>
        <td class="log-summary__label">
          {{ $t('general.status') }}
        </td>
        <td class="log-summary__status-cell">
          <ChecDataPill :color="statusColor">
            {{ log.status_code }}
          </ChecDataPill>
        </td>
      </tr>
      <tr>
        <td class="log-summary__label">
          {{ $t('developer.ipAddress') }}
        </td>
        <td>{{ log.ip_address }}</td>
      </tr>
      <tr>
        <td class="log-summary__label">
          {{ $t('developer.keyUsed.label') }}
        </td>
        <td>
          <ChecDataPill
            :color="log.key_used === 'public' ? 'blue' : 'red' "
          >
            {{ log.key_used }}
          </ChecDataPill>
        </td>
      </tr>
      <tr>
        <td class="log-summary__label">
          {{ $t('general.environment') }}
        </td>
        <td>
          <ChecDataPill
            :color="log.sandbox ? 'orange' : 'green' "
          >
            {{ log.sandbox ? $t('general.sandbox') : $t('general.live') }}
          </ChecDataPill>
        </td>
      </tr>
    </tbody>
  </ChecTable>
</template>

<script>
import { ChecDataPill, ChecTable } from '@chec/ui-library';
import Timestamp from '@/components/Timestamp.vue';

export default {
  name: 'LogSummary',
  components: {
    ChecDataPill,
    ChecTable,
    Timestamp,
  },
  props: {
    log: Object,
  },
  computed: {
    /**
     * Determine the data pill color from the status code
     */
    statusColor() {
      const { status_code: status } = this.log;
      if (status >= 200 && status < 300) {
        return 'green';
      }
      return 'red';
    },
  },
};
</script>

<style lang="scss">
.log-summary {
  &__label {
    @apply w-32 caps-xxs;
  }

  &__method-text {
    @apply font-bold;
  }

  &__url-row {
    code {
      @apply py-1 px-2 bg-gray-200 rounded-sm break-words;
      @apply text-xs font-mono tracking-wide text-gray-600;
    }
  }

  &__status-cell {
    padding-top: calc(0.75rem - 1px); // Specific padding to vertically align pill
  }
}
</style>
