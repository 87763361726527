<template>
  <form v-if="!isLoading" class="merchant-settings" @submit.prevent>
    <DashboardHeader :title="$t('settings.merchant.title')" />
    <div class="merchant-settings__content space-y-4 lg:space-y-0">
      <div class="merchant-settings__main space-y-4">
        <DetailsCard v-model="merchantEditable" :errors="validationErrors" />
        <MerchantAddress
          v-model="merchantEditable"
          :countries="countries"
          :subdivisions="subdivisions"
          :errors="validationErrors"
        />
        <Currency v-model="merchantEditable" :errors="validationErrors" />
        <AnalyticsSettings v-model="merchantEditable" />
      </div>
      <div class="merchant-settings__side-bar space-y-4">
        <ActionsCard
          :saving="isSaving"
          class="merchant-settings__actions-card"
          @save-merchant="handleSaveMerchant"
        />
        <MerchantID :merchant-id="merchant.id" />
        <LogoCard v-model="merchantEditable.images.logo" />
        <CoverCard v-model="merchantEditable.images.cover" />
        <HostedExperiences v-model="merchantEditable" />
        <DeleteMerchantCard
          :saving="isSaving"
          @delete-merchant="handleDeleteMerchant"
        />
      </div>
      <ActionsCard
        :saving="isSaving"
        class="merchant-settings__actions-card--mobile"
        @save-merchant="handleSaveMerchant"
      />
    </div>
  </form>
</template>

<script>
import { mapState, mapGetters, mapMutations } from 'vuex';
import { makeApiRequest } from '@/lib/api';
import DashboardHeader from '@/components/DashboardHeader.vue';
import mutations from '@/store/mutations';
import addNotification from '@/mixins/addNotification';
import switchMerchant from '@/modules/merchant/mixins/switchMerchant';
import confirm from '@/mixins/confirm';
import validateSchemaRequest from '@/lib/helpers/validateSchemaRequestHelper';
import crud from '@/mixins/crud';
import cards from '../components/merchant';
import merchantSchema from '../schemas/merchant';

export default {
  name: 'MerchantSettings',
  components: {
    DashboardHeader,
    ...cards,
  },
  mixins: [
    switchMerchant,
    addNotification,
    confirm,
    crud('merchant'),
  ],
  data() {
    return {
      merchantEditable: {
        address: {
          city: null,
          country: null,
          postal_zip_code: null,
          public: false,
          region: null,
          street: null,
        },
        analytics: {
          google: {
            settings: {
              linked_domains: null,
              tracking_id: null,
            },
          },
        },
        description: null,
        name: null,
        country: null,
        images: {
          cover: null,
          logo: null,
        },
        currency: {
          code: null,
          symbol: null,
        },
        has: {
          analytics: false,
          description: false,
          cover: false,
          logo: false,
          enabled_hosted_storefront: false,
          enabled_hosted_checkouts: false,
        },
        id: null,
        support_email: null,
      },
      isSaving: false,
      validationErrors: {},
    };
  },
  computed: {
    ...mapState('merchant', ['merchant']),
    ...mapGetters(['isLoading', 'countries', 'subdivisions']),
  },
  created() {
    // Create a copy of the merchant.
    this.merchantEditable = {
      ...this.merchant,
      images: { ...this.merchant.images },
      // CORS domains are managed elsewhere
      cors_domains: undefined,
    };
    this.merchantEditable.email = this.merchant.support_email;
    this.merchantEditable.currency = this.merchant.currency.code;
  },
  methods: {
    ...mapMutations('merchant', { setMerchant: mutations.SET_MERCHANT }),
    /**
     * Validate then save the merchant.
     */
    handleSaveMerchant() {
      this.validationErrors = {};
      this.isSaving = true;
      validateSchemaRequest(
        merchantSchema,
        this.merchantEditable,
        { abortEarly: false },
      )
        .then(
          (validatedData) => (this.update(validatedData.id, validatedData)),
        )
        .then((merchant) => {
          this.setMerchant({ merchant });
          this.addNotification(this.$t('settings.merchant.saved'));
        })
        .catch((error) => {
          if (error.name === 'ValidationError') { // yup schema validation error
            this.validationErrors = error.errors;
            return;
          }
          this.addNotification(
            this.$t('settings.merchant.saveFailed'),
            'error',
          );
        })
        .finally(() => { this.isSaving = false; });
    },
    /**
     * Delete the merchant account.
     */
    async handleDeleteMerchant() {
      if (!await this.confirm(
        this.$t('merchant.confirmDeleteTitle'),
        this.$t('merchant.confirmDelete'),
      )) {
        return;
      }
      this.isSaving = true;
      makeApiRequest(
        'DELETE',
        `/v1/merchants/${this.merchant.id}`,
        { delete_user: false },
      )
        .then(() => {
          this.addNotification(this.$t('merchant.deleted'));
          // Reload the merchant
          this.$store.dispatch('merchant/FETCH_MERCHANT', {
            showLoading: false,
            force: true,
          }, { root: true });
          this.$router.push({ name: 'merchant.list' }); // send user back to merchant selector
        })
        .catch((error) => {
          this.isSaving = false;
          this.addNotification(this.$t('merchant.deleteFailed'), 'error');
          throw error;
        });
    },
  },
};
</script>

<style lang="scss">
.merchant-settings {
  @apply text-gray-500;

  &__side-bar {
    @screen lg {
      @apply ml-8 w-1/3 max-w-sm flex-shrink-0;
    }
  }
  @screen lg {
    &__content {
      @apply flex flex-row;
    }

    &__main {
      @apply flex-grow;
    }
  }

  &__actions-card {
    @apply hidden;

    @screen lg {
      @apply block;
    }

    &--mobile {
      @apply mt-4;

      @screen lg {
        @apply hidden;
      }
    }
  }

  .card {
    @apply mb-8;
  }

  .details-card {
    @apply p-4;

    .chec-header {
      @apply mb-4;
    }
  }
}
</style>
