<template>
  <DashboardTable
    class="invoices-table"
    :align-right-after="3"
    :columns="[
      $t('billing.invoiceDate'),
      $t('billing.invoiceDescription'),
      $t('billing.invoiceAmount'),
      $t('billing.invoiceStatus'),
      $t('general.details'),
    ]"
    state-key="billing/invoices"
  >
    <InvoiceTableRow
      v-for="({ id, amount, description, status, created }) in invoices"
      :id="id"
      :key="id"
      :amount="amount"
      :description="description"
      :status="status"
      :created="created"
    />
  </DashboardTable>
</template>

<script>
import DashboardTable from '@/components/DashboardTable.vue';
import InvoiceTableRow from './InvoiceTableRow.vue';

export default {
  name: 'InvoiceTable',
  components: {
    DashboardTable,
    InvoiceTableRow,
  },
  props: {
    /**
     * List of Invoices
     */
    invoices: {
      type: Array,
      default: () => [],
    },
  },
};
</script>

<style lang="scss" scoped>
.invoices-table {
  @apply table-fixed w-full text-sm text-gray-500;

  tr > *:nth-child(n+4) {
    @apply text-right;
  }

  &__loading-cell {
    @apply relative p-8;
  }

  &--empty {
    @apply text-gray-400;
  }
}
</style>
